&-footer {
	&__logo {
		height: 50px;
		background: {
			image: url(#{$img_path}logo-white.svg);
			repeat: no-repeat;
			size: contain; } }
	&__info {
		font-size: .875em;
		margin-top: 25px; }
	&__grid {
		flex-wrap: wrap; }
	&__item {
		flex: 0 1 25%;
		text-align: center;
		&-with-logo {
			display: flex;
			text-align: left;
			& > div {
				margin: auto 0; } } }
	&__menu {
		display: inline-block;
		text-align: left;
		li {
			a, span {
				font: {
					family: Panton;
					weight: bold; }
				padding: 8px 0 7px; } }
		&_with_icons {
			li {
				padding-left: 25px;
				position: relative;
				a, span {
					padding: 8px 0 7px;
					vertical-align: middle; }
				.#{$prefix}-icon {
					position: absolute;
					left: 0; } } } } }

&-social {
	text-align: center;
	&__item {
		display: inline-block;
		margin: 0 9px; } }

@include media($mobile) {
	&-footer {
		&__grid {
			padding: 0 20px; }
		&__info {
			margin: 0; }
		&__item {
			flex: 0 1 100%;
			text-align: left;
			margin-bottom: 35px;
			&:nth-child(1) {
				order: 3; }
			&:nth-child(2) {
				order: 1; }
			&:nth-child(3) {
				order: 2; }
			&:nth-child(4) {
				order: 4;
				margin-bottom: 0;
				margin-top: 15px; } }
		&__logo {
			display: none; }
		&__menu {
			li {
				a, span {
					padding: 8px 0 18px; } }
			&_with_icons {
				li {
					&:last-child {
						margin-top: -18px; } } } } } }
