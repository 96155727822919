&-modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: -17px;
	display: none;
	z-index: 10000;
	overflow-x: auto;
	background: rgba(51, 51, 51, .3);
	padding: 40px 0;
	&_open {
		display: flex;
		animation: show-modal .2s ease;
		.#{$prefix}-modal__wrap {
			display: block;
			animation: show-modal-wrap .4s ease; } }
	&_close {
		animation: hide-modal .3s ease;
		opacity: 0;
		.#{$prefix}-modal__wrap {
			animation: hide-modal-wrap .2s ease;
			opacity: 0; } }
	&__wrap {
		background: $white;
		border-radius: 4px;
		padding: 55px;
		flex-basis: 20%;
		box-sizing: border-box;
		position: relative;
		margin: auto;
		z-index: 1;
		display: none;
		transition: all .8s ease; }
	&__title {
		font: {
			family: Panton;
			weight: bold;
			size: 2.125em; }
		color: $font_dark_gray;
		margin-bottom: 45px;
		text-align: center; }
	&__close {
		position: absolute;
		right: 35px;
		top: 35px;
		cursor: pointer; } }

&-form {
	&-feedback {
		width: 400px;
		padding: 0 60px;
		box-sizing: border-box; }
	&-vacancy {
		width: 620px;
		padding: 0 80px;
		box-sizing: border-box; }
	&-auth {
		width: 350px;
		padding: 0 80px;
		box-sizing: border-box; }
	&-business {
		width: 400px;
		padding: 0 60px;
		box-sizing: border-box; } }

@include media($mobile) {
	&-modal {
		padding: 0;
		right: -9px;
		&__wrap {
			border-radius: 0;
			flex-basis: 100%;
			min-height: 100%;
			padding: 50px 29px 50px 20px; }
		&__close {
			top: 25px;
			right: 29px; }
		&__title {
			margin-bottom: 35px; } }

	&-form {
		&-feedback, &-vacancy, &-auth, &-business {
			padding: 0 5px;
			width: auto; } } }
