.#{$prefix} {
	&-container {
		width: 100%;
		max-width: $max_content_width;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 0 40px;
		position: relative; }
	&-bg {
		&-gray {
			background: $bg_gray; }
		&-red {
			background: $light_red; } }
	&-flex {
		display: flex;
		align-items: stretch;
		justify-content: space-between; }
	&-title {
		&-h1 {
			font: bold 2.5em/3em Panton;
			color: $font_dark_gray;
			text-align: center;
			&-new {
				font: bold 1.75em/1.4em Panton;
				color: $font_gray;
				margin-bottom: 30px; } }
		&-h2 {
			font: bold 2.5em/3em Panton;
			color: $font_dark_gray;
			text-align: center; }
		&-h3 {
			font: bold 1.75em/2.125em Panton;
			color: $font_dark_gray;
			text-align: center; }
		&-h4 {
			font: bold 1.625em/1.5em Istok web;
			color: $font_dark_gray;
			margin: 0 0 35px;
			padding: 35px 0 0; }
		&-h5 {
			font: normal 1em/1.5em Istok web;
			color: $font_gray;
			text-align: center;
			margin-bottom: 35px;
			&-new {
				font-size: 1.25em;
				font-weight: 500;
				color: $gray;
				text-transform: lowercase; } } }
	&-inline {
		&-logo {
			height: 75px;
			width: 100%;
			&_red {
				background: {
					image: url(#{$img_path}inline-logo-red.svg);
					repeat: no-repeat;
					position: center;
					size: contain; } }
			&_blue {
				background: {
					image: url(#{$img_path}inline-logo-blue.svg);
					repeat: no-repeat;
					position: center;
					size: contain; } } }
		&-slogo {
			height: 35px;
			width: 100%;
			display: none; } }
	&-join-button {
		position: fixed;
		bottom: 100px;
		right: 88px;
		z-index: 1000;
		a {
			animation: pulse 1.8s infinite; } }
	&-attention {
		padding: 55px 50px;
		text-align: center;
		&__title {
			font: {
				family: Panton;
				weight: bold;
				size: 2.375em; }
			color: $font_white;
			display: inline-block;
			vertical-align: middle;
			margin-right: 30px; } } }

##{$prefix} {
	&-header {
		background: $white;
		color: $font_light_gray;
		padding: 25px 0;
		a {
			display: block;
			color: $font_blue; } }

	&-footer {
		background: $blue;
		padding: 30px 0;
		a, span, p {
			display: inline-block;
			color: $font_lighter_gray; } }

	&-about {
		padding: 35px 0 25px; }

	&-news {
		padding: 0 0 75px; } }

@include media($mobile) {
	.#{$prefix} {
		&-container {
			padding: 0; }
		&-title {
			&-h1 {
				font: bold 1.75em/1.5em Panton;
				margin: 18px 0;
				padding: 0 20px;
				&-new {
					font: bold 1.15em/1.4em Panton;
					margin: 25px auto;
					padding-left: 15px;
					box-shadow: inset 5px 0 #eb7e7f; } }
			&-h2 {
				font: bold 1.75em/2em Panton;
				margin-bottom: 15px;
				padding: 0 20px; }
			&-h4 {
				font: bold 1em/1.9em Istok web;
				margin: 15px 0;
				padding: 2px 20px;
				&:nth-of-type(odd) {
					box-shadow: inset 5px 0 $light_blue; }
				&:nth-of-type(even) {
					box-shadow: inset 5px 0 $light_red; } }
			&-h5 {
				color: $font_gray_e6;
				margin-bottom: 15px;
				padding: 0 40px;
				&-new {
					font-size: 1em;
					font-weight: bold;
					margin-bottom: 20px; } } }
		&-inline {
			&-logo {
				height: 35px; }
			&-slogo {
				display: block; } }
		&-join-button {
			display: none; }
		&-attention {
			padding: 25px 60px 35px;
			&__title {
				font-size: 1.5em;
				line-height: 1.5em;
				margin: 0 0 20px; } } }
	##{$prefix} {
		&-header {
			padding: 11px 20px; }
		&-slider {
			background: $blue; }
		&-about {
			padding: 40px 0 25px; }
		&-news {
			padding: 0 0 5px;
			.#{$prefix}-carousel__dots {
				margin-top: 15px; } }
		&-join {
			background: $light_red; }
		&-footer {
			padding: 50px 0 55px; } } }
