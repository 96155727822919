&-brand {
	&__content {
		margin: 0 0 70px; }
	&__buttons {
		text-align: center;
		margin-bottom: 60px; } }

&-carousel {
	&_brand {
		margin-bottom: 0;
		.#{$prefix}-carousel__nav-next, .#{$prefix}-carousel__nav-prev {
			bottom: 0;
			background: $white; }
		&__item {
			box-sizing: border-box;
			height: 410px;
			display: flex;
			position: relative;
			&-title {
				font: {
					size: 1em;
					family: Panton;
					weight: bold; }
				color: $font_blue;
				text-align: center;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0; }
			img {
				width: calc(100% - 40px);
				margin: auto 20px; } }
		.slick-center {
			.#{$prefix}-carousel_brand__item {
				img {
					margin: 0 20px 25px; } } } } }

@include media($mobile) {
	&-brand {
		&__content {
			margin: 40px 0; }
		&__buttons {
			margin-bottom: 40px;
			a {
				width: auto; } } }

	&-carousel {
		&_brand {
			.#{$prefix}-carousel__nav-next, .#{$prefix}-carousel__nav-prev {
				display: flex !important; }
			&__item {
				img {
					width: calc(100% - 20px);
					margin: 0 10px 25px !important; } } } } }
