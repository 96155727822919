.daterangepicker {
	left: 0 !important;
	top: 100% !important;
	border-radius: 0;
	border: none;
	outline: 1px solid rgba(0, 0, 0, .07);
	margin-top: 1px;
	&:before, &:after {
		display: none; }
	&.show-calendar {
		.ranges {
			margin-top: 0; } }
	.drp {
		&-calendar {
			max-width: none;
			padding: 0 10px 12px !important;
			&.right {
				display: none !important; } } }
	.calendar-table {
		padding: 0 !important;
		.prev, .next {
			span {
				border: solid $gray;
				border-width: 0 1px 1px 0; } }
		thead {
			tr {
				height: 50px;
				&:first-child {
					th {
						font: {
							family: Panton;
							weight: bold;
							size: 1em; }
						color: $font_dark_gray; } }
				&:last-child {
					box-shadow: inset 0 1px 0 rgba(228, 228, 228, .2);
					th {
						font: {
							size: .75em;
							family: Panton;
							weight: normal; }
						color: $font_grayer; } }
				th {
					&.available {
						&:hover {
							background: transparent; } } } } }
		td {
			min-width: 36px;
			height: 22px;
			line-height: 20px;
			font: {
				size: .875em;
				family: Panton;
				weight: normal; } } } }

.#{$prefix} {
	&-calendar {
		&-day {
			position: relative;
			border: none !important;
			&.available {
				color: $light_blue;
				&:hover {
					color: $light_blue !important;
					background: transparent !important; } }
			&.off {
				color: $light_gray !important; }
			&.in-range {
				color: $light_blue !important;
				background: rgba(165, 179, 234, 0.6) !important;
				&:hover {
					background: rgba(165, 179, 234, 0.6) !important; } }
			&.active {
				background: $white !important;
				border-radius: 50%;
				color: $font_white !important;
				&:before {
					content: attr(data-content);
					height: 24px;
					width: 24px;
					line-height: 24px;
					text-align: center;
					top: 0;
					left: 50%;
					margin-left: -12px;
					background: $light_blue;
					border-radius: 50%;
					position: absolute;
					z-index: 1; }
				&:hover {
					background: $white !important;
					color: $font_white !important; } }
			&.start-date {
				&:after {
					content: '';
					background: rgba(165, 179, 234, 0.6);
					position: absolute;
					top: 0;
					right: 0;
					width: 50%;
					height: 100%;
					z-index: 0; }
				&.end-date {
					&:after {
						display: none; } } }
			&.end-date {
				&:after {
					content: '';
					background: rgba(165, 179, 234, 0.6);
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					z-index: 0; } } } } }

@include media($mobile) {
	.daterangepicker {
		position: fixed;
		left: 0 !important;
		top: 56px !important;
		width: 100%;
		border: none;
		outline: none;
		margin-top: 0;
		box-shadow: 0 2px 4px rgba(0,0,0,.07); } }
