&-diagnostics {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 90px;
		img {
			max-height: 440px;
			width: 100%;
			margin-bottom: 25px; }
		p {
			margin-bottom: 40px; }
		strong {
			margin-bottom: 20px;
			display: inline-block; }
		ul {
			margin-bottom: 40px;
			li {
				padding-left: 15px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 11px;
					left: 0;
					height: 4px;
					width: 4px;
					border-radius: 50%;
					background: $font_dark_gray; } } }
		&-title {
			font: {
				family: Panton;
				weight: bold;
				size: 1.5em; }
			color: $font_dark_gray;
			margin: 10px 0 20px; } }
	&__table {
		&_min {
			margin-bottom: 60px !important;
			&-header {
				font: {
					family: Panton;
					weight: bold; }
				color: $font_white;
				text-transform: uppercase;
				background: $light_blue;
				border-radius: 5px 5px 0 0;
				padding: 11px 0 12px 120px; }
			&-item {
				padding: 20px 25px;
				display: flex;
				flex-wrap: wrap;
				border-bottom: 1px solid $light_gray;
				div {
					flex: 0 0 50%;
					font-size: .875em;
					&:first-child {
						font-weight: bold;
						font-size: 1em; } }
				&:before {
					display: none; } } } }
	&__title {
		padding-left: 30px;
		line-height: 55px;
		color: $font_dark_gray;
		box-shadow: inset 5px 0 0 $light_red;
		margin-bottom: 45px;
		font: {
			family: Panton;
			weight: bold;
			size: 1.5em; } }
	&__buttons {
		text-align: center; } }

@include media($mobile) {
	&-diagnostics {
		&__content {
			margin: 0 0 40px;
			img {
				border-radius: 5px;
				overflow: hidden;
				margin: 0 20px 25px;
				width: calc(100% - 40px); }
			p, strong {
				margin-bottom: 20px;
				padding: 0 20px; }
			ul {
				margin-bottom: 20px;
				padding: 0 20px; }
			&-title {
				padding: 0 20px;
				font-size: 1.15em; } }
		&__table {
			&_min {
				margin-bottom: 0 !important;
				padding: 0 !important;
				&-header {
					border-radius: 0;
					padding: 11px 20px 12px;
					text-align: center; }
				&-item {
					padding: 20px;
					div {
						flex: 0 0 100%;
						&:first-child {
							margin-bottom: 10px; } } } } }
		&__title {
			padding: 0 20px;
			font-size: 1.15em;
			line-height: 30px;
			margin: 40px 0; }
		&__buttons {
			a {
				width: auto; } } } }
