&-form {
	&-wrap {
		form {
			padding: 10px 0;
			text-align: left; } }
	&-label {
		display: inline-block;
		margin-bottom: 12px;
		&-file {
			font-size: 0.875em;
			color: $font_blue;
			text-align: center;
			line-height: 36px;
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
			border: 2px solid $light_blue;
			border-radius: 20px;
			width: 60%;
			max-width: 230px;
			user-select: none;
			padding: 0 20px;
			box-sizing: border-box;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis; } }
	&__field {
		margin-bottom: 30px;
		position: relative;
		&-desc {
			color: $font_dark_gray;
			margin: 10px 0 60px;
			position: relative;
			padding-left: 12px;
			&:before {
				content: '*';
				position: absolute;
				left: 0;
				top: 0; } } }
	&__buttons {
		text-align: center; }
	&-success {
		text-align: center;
		color: #338e37; }
	&-error {
		text-align: center;
		color: $light_red; } }

&-input {
	display: block;
	width: 100%;
	box-sizing: border-box;
	transition: border .2s ease;
	&_text {
		padding: 8px 15px 7px;
		border: 1px solid #cecece;
		border-radius: 5px;
		box-shadow: inset 0 4px 5px rgba(0, 0, 0, .05);
		position: relative;
		&:focus {
			border: 1px solid $light_blue; }
		&:disabled {
			background: $white;
			color: $gray; }
		&-phone {
			padding-left: 35px; } }
	&_file {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1; }
	&_select {
		padding: 8px 15px 7px;
		border: 1px solid #cecece;
		border-radius: 5px;
		box-shadow: inset 0 4px 5px rgba(0, 0, 0, .05);
		position: relative;
		&:focus {
			border: 1px solid $light_blue; } }
	&_radio {
		margin: 0;
		position: absolute;
		height: 0;
		left: -15px;
		visibility: hidden;
		& + label {
			cursor: pointer;
			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				margin-right: 12px;
				height: 28px;
				width: 28px;
				background: $white;
				border-radius: 50%;
				border: 2px solid $border_dark_gray;
				box-shadow: inset 0 0 0 5px $white; } }
		&:checked + label {
			&:before {
				background: $light_blue; } } }
	&-desc {
		display: inline-block;
		color: $gray;
		margin-left: 5px;
		font-size: .875em;
		user-select: none;
		max-width: 200px;
		width: 36%;
		vertical-align: middle; } }

&-textarea {
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 8px 15px 7px;
	border: 1px solid #cecece;
	border-radius: 5px;
	box-shadow: inset 0 4px 5px rgba(0, 0, 0, .05);
	transition: border .2s ease;
	&:focus {
		border: 1px solid $light_blue; }
	&:disabled {
		background: $white;
		color: $gray; } }

&-error {
	border: 1px solid $light_red; }

&-phone {
	&-prefix {
		color: $gray;
		font: normal .875em/1.47em Istok Web;
		position: absolute;
		bottom: 9px;
		left: 16px; } }

@include media($mobile) {
	&-form {
		&-label {
			margin-bottom: 15px;
			&-file {
				margin: 15px 0;
				font: {
					family: Panton;
					weight: bold;
					size: 1em; }
				color: $light_blue;
				border-radius: 5px; } }

		&__field {
			margin-bottom: 15px;
			&-desc {
				margin-bottom: 40px; } } }
	&-input {
		&-desc {
			font-size: .715em; } } }
