&-pagination {
	text-align: center;
	&__item {
		display: inline-block;
		margin: 0 8px;
		a, span {
			font-size: 1.125em;
			color: $font_blue; }
		&_active {
			a, span {
				color: $font_dark_gray;
				font-weight: bold; } } } }
