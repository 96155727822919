&-join {
	&__content {
		max-width: $mid_content_width;
		margin: 45px auto;
		text-align: center; }
	&__desc {
		margin: 30px 0 40px;
		line-height: 1.75em; }
	&__buttons {
		margin-bottom: 35px; } }

@include media($mobile) {
	&-join {
		&__content {
			margin: auto;
			padding: 30px 20px 40px;
			.#{$prefix}-title-h3 {
				color: $font_white; }
			.#{$prefix}-inline-logo {
				display: none; } }
		&__desc {
			margin: 15px 0 35px; }
		&__buttons {
			margin-bottom: 0;
			padding: 0 25px; } } }
