&-breadcrumbs {
	&__item {
		display: inline-block;
		a, span {
			font-size: .75em; }
		a {
			color: $font_blue;
			&:after {
				content: '/';
				display: inline-block;
				color: $font_grayer;
				margin-left: 6px; } }
		span {
			color: $font_grayer;
			margin-left: 6px; } } }

@include media($mobile) {
	&-breadcrumbs {
		display: none; } }
