@font-face {
	font-family: 'Panton';
	src: url('#{$font_path}Panton-Bold.eot');
	src: local('Panton Bold'), local('Panton-Bold'), url('#{$font_path}Panton-Bold.eot?#iefix') format('embedded-opentype'), url('#{$font_path}Panton-Bold.woff') format('woff'), url('#{$font_path}Panton-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'Panton';
	src: url('#{$font_path}Panton-Light.eot');
	src: local('Panton Light'), local('Panton-Light'), url('#{$font_path}Panton-Light.eot?#iefix') format('embedded-opentype'), url('#{$font_path}Panton-Light.woff') format('woff'), url('#{$font_path}Panton-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal; }

@font-face {
	font-family: 'Panton';
	src: url('#{$font_path}Panton-Regular.eot');
	src: local('Panton Regular'), local('Panton-Regular'), url('#{$font_path}Panton-Regular.eot?#iefix') format('embedded-opentype'), url('#{$font_path}Panton-Regular.woff') format('woff'), url('#{$font_path}Panton-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal; }
