&-partners {
	&__content {
		padding: 35px 0 75px; }
	&__grid {
		justify-content: center; }
	@include white-grid(25%); }

@include media($mobile) {
	&-partners {
		&__content {
			padding: 35px 0 45px; } } }
