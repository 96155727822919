&-dropdown {
	padding: 13px 25px;
	position: relative;
	box-shadow: inset -1px 0 $border_gray;
	&__wrap {
		background: $white;
		z-index: 2;
		text-align: left;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		overflow: hidden;
		width: inherit; }
	&_open {
		//border-radius: 5px 5px 0 0
		box-shadow: 0 0 0 1px $border_gray;
		background: $white;
		.#{$prefix}-dropdown__value {
			&:after {
				transform: rotate(-135deg);
				top: 23px; } }
		.#{$prefix}-dropdown__wrap {
			box-shadow: 0 0 0 1px $border_gray, 0 2px 4px rgba(0, 0, 0, 0.07); }
		.#{$prefix}-dropdown__list {
			display: block; } }
	&_calendar {
		.#{$prefix}-dropdown__value {
			&:after {
				content: '';
				height: 18px;
				width: 18px;
				right: 24px;
				position: absolute;
				top: 15px;
				border: none;
				transform: none;
				@include icon-background('calendar#{$suffix}'); } } }
	&__value {
		cursor: pointer;
		color: $font_dark_gray;
		span {
			font-size: 1em;
			user-select: none;
			position: relative;
			display: block;
			//overflow: hidden
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-right: 20px; }
		&:after {
			content: '';
			height: 6px;
			width: 6px;
			right: 27px;
			position: absolute;
			top: 18px;
			border: 1px solid $font_blue;
			border-left: none;
			border-top: none;
			transform: rotate(45deg); }
		&_blue {
			color: $font_blue; }
		&-count {
			color: $font_blue;
			display: inline !important;
			padding-left: 8px; }
		&-sub {
			position: absolute !important;
			font-size: .875em !important;
			color: $font_gray;
			top: -15px; } }
	&__list {
		display: none;
		li {
			display: block;
			padding: 9px 13px 9px 40px;
			cursor: pointer;
			position: relative;
			transition: all .2s ease;
			&:first-child {
				margin-top: 6px; }
			&:last-child {
				margin-bottom: 6px; }
			&:before {
				content: '';
				height: 18px;
				width: 18px;
				border-radius: 50%;
				position: absolute;
				border: 1px solid $light_blue;
				left: 13px;
				top: 11px; }
			&:after {
				content: '';
				display: none;
				position: absolute;
				width: 3px;
				height: 6px;
				left: 21px;
				top: 16px;
				border: 2px solid $font_white;
				border-top: none;
				border-left: none;
				transform: rotate(45deg); } } }
	&__item {
		&_active {
			&:before {
				background: $light_blue; }
			&:after {
				display: block !important; } } } }

@include media($mobile) {
	&-dropdown {
		width: 100%;
		box-shadow: none;
		padding: 0;
		border-bottom: 1px solid $border_gray;
		&_open {
			.#{$prefix}-dropdown__wrap {
				box-shadow: 0 2px 4px rgba(0,0,0,.07); } }
		&__wrap {
			position: fixed;
			top: 56px;
			padding: 8px; }
		&__list {
			li {
				line-height: 1.7em;
				padding: 7px 12px 7px 40px;
				&:last-child {
					margin-bottom: 4px; }
				&:before {
					top: 8px; }
				&:after {
					top: 13px; } } }
		&__value {
			padding: 15px 0;
			&:after {
				display: none; }
			span {
				font: {
					size: 1em;
					weight: bold;
					family: Panton; }
				line-height: 1.7em;
				display: inline-flex;
				padding: 0; }
			&-count {
				margin-left: 5px; } } } }
