&-docs {
	&__item {
		padding-left: 25px;
		margin-bottom: 15px;
		.#{$prefix}-icon {
			float: left; }
		a {
			margin-left: 15px;
			color: $font_blue; } }
	&__list {
		&-title {
			font-weight: bold;
			margin: 40px 0 20px;
			& + ul {
				li {
					padding-left: 0  !important; } } } } }

@include media($mobile) {
	&-docs {
		&__item {
			padding: 0 20px;
			margin-bottom: 10px;
			.#{$prefix}-icon {
				margin-top: 3px; }
			a {
				margin: 0;
				display: block;
				padding-left: 20px;
				font-size: .85em; } }
		&__list {
			&-title {
				margin: 20px 0;
				padding: 0 20px; } } } }
