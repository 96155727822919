@import url("https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext");
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(235, 126, 127, 0.3); }
  100% {
    box-shadow: 0 0 0 20px rgba(235, 126, 127, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3); }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); } }

@keyframes show-modal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show-modal-wrap {
  0% {
    top: -100px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes hide-modal {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes hide-modal-wrap {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: -100px;
    opacity: 0; } }

@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em; }
  40% {
    box-shadow: 0 1.5em 0 0; } }

@font-face {
  font-family: 'Panton';
  src: url("../fonts/Panton-Bold.eot");
  src: local("Panton Bold"), local("Panton-Bold"), url("../fonts/Panton-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Panton-Bold.woff") format("woff"), url("../fonts/Panton-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Panton';
  src: url("../fonts/Panton-Light.eot");
  src: local("Panton Light"), local("Panton-Light"), url("../fonts/Panton-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Panton-Light.woff") format("woff"), url("../fonts/Panton-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Panton';
  src: url("../fonts/Panton-Regular.eot");
  src: local("Panton Regular"), local("Panton-Regular"), url("../fonts/Panton-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Panton-Regular.woff") format("woff"), url("../fonts/Panton-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font: normal 16px Istok Web, sans-serif;
  color: #333; }
  body h1, body h2, body h3, body h4, body h5, body h6, body p, body ul, body li, body i, body b, body strong, body figure, body figcaption {
    margin: 0;
    padding: 0;
    line-height: 1.8em;
    box-sizing: border-box; }
  body a, body a:visited, body a:link {
    text-decoration: none;
    color: #4F4F4F;
    transition: color, box-shadow .2s ease; }
  body ul, body li {
    list-style: none; }
  body img {
    display: block;
    width: 100%; }
  body div {
    box-sizing: border-box; }
  body p a {
    color: #7186CF !important; }
  body svg path {
    fill: inherit;
    stroke: inherit; }
  body input, body textarea, body select, body button {
    font: normal 16px Istok Web, sans-serif;
    outline: none;
    border: none; }
    body input:focus, body textarea:focus, body select:focus, body button:focus {
      outline: none;
      border: none; }
  body input::placeholder, body textarea::placeholder {
    color: #929292;
    font: normal .875em/1.47em Istok Web; }
  body textarea {
    resize: none; }
  body div, body textarea {
    scrollbar-color: #666 transparent;
    scrollbar-width: thin;
    scrollbar-track-color: transparent;
    scrollbar-face-color: #929292;
    scrollbar-array-color: transparent; }
    body div::-webkit-scrollbar, body textarea::-webkit-scrollbar {
      width: 9px; }
    body div::-webkit-scrollbar-track, body textarea::-webkit-scrollbar-track {
      background: transparent;
      background-clip: padding-box; }
    body div::-webkit-scrollbar-thumb, body textarea::-webkit-scrollbar-thumb {
      background: #929292;
      border-radius: 4px;
      border: 2px solid transparent;
      border-top: none;
      border-bottom: none;
      background-clip: padding-box; }
      body div::-webkit-scrollbar-thumb:hover, body textarea::-webkit-scrollbar-thumb:hover {
        background: #666;
        border-radius: 4px;
        background-clip: padding-box;
        border: 2px solid transparent;
        border-top: none;
        border-bottom: none; }
      body div::-webkit-scrollbar-thumb:active, body textarea::-webkit-scrollbar-thumb:active {
        background: #666;
        border-radius: 4px;
        background-clip: padding-box;
        border: 2px solid transparent;
        border-top: none;
        border-bottom: none; }

@media (max-width: 480px) {
  body {
    font: normal 14px Istok Web, sans-serif; } }

.tau-container {
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 40px;
  position: relative; }

.tau-bg-gray {
  background: #ECEEF4; }

.tau-bg-red {
  background: #EB7E7F; }

.tau-flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between; }

.tau-title-h1 {
  font: bold 2.5em/3em Panton;
  color: #333;
  text-align: center; }
  .tau-title-h1-new {
    font: bold 1.75em/1.4em Panton;
    color: #4F4F4F;
    margin-bottom: 30px; }

.tau-title-h2 {
  font: bold 2.5em/3em Panton;
  color: #333;
  text-align: center; }

.tau-title-h3 {
  font: bold 1.75em/2.125em Panton;
  color: #333;
  text-align: center; }

.tau-title-h4 {
  font: bold 1.625em/1.5em Istok web;
  color: #333;
  margin: 0 0 35px;
  padding: 35px 0 0; }

.tau-title-h5 {
  font: normal 1em/1.5em Istok web;
  color: #4F4F4F;
  text-align: center;
  margin-bottom: 35px; }
  .tau-title-h5-new {
    font-size: 1.25em;
    font-weight: 500;
    color: #929292;
    text-transform: lowercase; }

.tau-inline-logo {
  height: 75px;
  width: 100%; }
  .tau-inline-logo_red {
    background-image: url(../images/inline-logo-red.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .tau-inline-logo_blue {
    background-image: url(../images/inline-logo-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }

.tau-inline-slogo {
  height: 35px;
  width: 100%;
  display: none; }

.tau-join-button {
  position: fixed;
  bottom: 100px;
  right: 88px;
  z-index: 1000; }
  .tau-join-button a {
    animation: pulse 1.8s infinite; }

.tau-attention {
  padding: 55px 50px;
  text-align: center; }
  .tau-attention__title {
    font-family: Panton;
    font-weight: bold;
    font-size: 2.375em;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px; }

#tau-header {
  background: #fff;
  color: #B3B8CC;
  padding: 25px 0; }
  #tau-header a {
    display: block;
    color: #2F438B; }

#tau-footer {
  background: #525F8B;
  padding: 30px 0; }
  #tau-footer a, #tau-footer span, #tau-footer p {
    display: inline-block;
    color: #F2F2F2; }

#tau-about {
  padding: 35px 0 25px; }

#tau-news {
  padding: 0 0 75px; }

@media (max-width: 480px) {
  .tau-container {
    padding: 0; }
  .tau-title-h1 {
    font: bold 1.75em/1.5em Panton;
    margin: 18px 0;
    padding: 0 20px; }
    .tau-title-h1-new {
      font: bold 1.15em/1.4em Panton;
      margin: 25px auto;
      padding-left: 15px;
      box-shadow: inset 5px 0 #eb7e7f; }
  .tau-title-h2 {
    font: bold 1.75em/2em Panton;
    margin-bottom: 15px;
    padding: 0 20px; }
  .tau-title-h4 {
    font: bold 1em/1.9em Istok web;
    margin: 15px 0;
    padding: 2px 20px; }
    .tau-title-h4:nth-of-type(odd) {
      box-shadow: inset 5px 0 #7186CF; }
    .tau-title-h4:nth-of-type(even) {
      box-shadow: inset 5px 0 #EB7E7F; }
  .tau-title-h5 {
    color: #6e6e6e;
    margin-bottom: 15px;
    padding: 0 40px; }
    .tau-title-h5-new {
      font-size: 1em;
      font-weight: bold;
      margin-bottom: 20px; }
  .tau-inline-logo {
    height: 35px; }
  .tau-inline-slogo {
    display: block; }
  .tau-join-button {
    display: none; }
  .tau-attention {
    padding: 25px 60px 35px; }
    .tau-attention__title {
      font-size: 1.5em;
      line-height: 1.5em;
      margin: 0 0 20px; }
  #tau-header {
    padding: 11px 20px; }
  #tau-slider {
    background: #525F8B; }
  #tau-about {
    padding: 40px 0 25px; }
  #tau-news {
    padding: 0 0 5px; }
    #tau-news .tau-carousel__dots {
      margin-top: 15px; }
  #tau-join {
    background: #EB7E7F; }
  #tau-footer {
    padding: 50px 0 55px; } }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  .slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer; }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.daterangepicker {
  left: 0 !important;
  top: 100% !important;
  border-radius: 0;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.07);
  margin-top: 1px; }
  .daterangepicker:before, .daterangepicker:after {
    display: none; }
  .daterangepicker.show-calendar .ranges {
    margin-top: 0; }
  .daterangepicker .drp-calendar {
    max-width: none;
    padding: 0 10px 12px !important; }
    .daterangepicker .drp-calendar.right {
      display: none !important; }
  .daterangepicker .calendar-table {
    padding: 0 !important; }
    .daterangepicker .calendar-table .prev span, .daterangepicker .calendar-table .next span {
      border: solid #929292;
      border-width: 0 1px 1px 0; }
    .daterangepicker .calendar-table thead tr {
      height: 50px; }
      .daterangepicker .calendar-table thead tr:first-child th {
        font-family: Panton;
        font-weight: bold;
        font-size: 1em;
        color: #333; }
      .daterangepicker .calendar-table thead tr:last-child {
        box-shadow: inset 0 1px 0 rgba(228, 228, 228, 0.2); }
        .daterangepicker .calendar-table thead tr:last-child th {
          font-size: .75em;
          font-family: Panton;
          font-weight: normal;
          color: #666; }
      .daterangepicker .calendar-table thead tr th.available:hover {
        background: transparent; }
    .daterangepicker .calendar-table td {
      min-width: 36px;
      height: 22px;
      line-height: 20px;
      font-size: .875em;
      font-family: Panton;
      font-weight: normal; }

.tau-calendar-day {
  position: relative;
  border: none !important; }
  .tau-calendar-day.available {
    color: #7186CF; }
    .tau-calendar-day.available:hover {
      color: #7186CF !important;
      background: transparent !important; }
  .tau-calendar-day.off {
    color: #E3E3E4 !important; }
  .tau-calendar-day.in-range {
    color: #7186CF !important;
    background: rgba(165, 179, 234, 0.6) !important; }
    .tau-calendar-day.in-range:hover {
      background: rgba(165, 179, 234, 0.6) !important; }
  .tau-calendar-day.active {
    background: #fff !important;
    border-radius: 50%;
    color: #fff !important; }
    .tau-calendar-day.active:before {
      content: attr(data-content);
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      top: 0;
      left: 50%;
      margin-left: -12px;
      background: #7186CF;
      border-radius: 50%;
      position: absolute;
      z-index: 1; }
    .tau-calendar-day.active:hover {
      background: #fff !important;
      color: #fff !important; }
  .tau-calendar-day.start-date:after {
    content: '';
    background: rgba(165, 179, 234, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 0; }
  .tau-calendar-day.start-date.end-date:after {
    display: none; }
  .tau-calendar-day.end-date:after {
    content: '';
    background: rgba(165, 179, 234, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 0; }

@media (max-width: 480px) {
  .daterangepicker {
    position: fixed;
    left: 0 !important;
    top: 56px !important;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07); } }

.ya-share2__icon {
  height: 30px !important;
  width: 30px !important; }
  .ya-share2__icon:active {
    box-shadow: none !important; }
  .ya-share2__item_service_vkontakte .ya-share2__icon {
    background-image: url(../images/icons/icon-vk-rp.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important; }
  .ya-share2__item_service_facebook .ya-share2__icon {
    background-image: url(../images/icons/icon-fb-rp.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important; }
  .ya-share2__item_service_twitter .ya-share2__icon {
    background-image: url(../images/icons/icon-tw-rp.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important; }
  .ya-share2__item_service_telegram .ya-share2__icon {
    background-image: url(../images/icons/icon-tel-rp.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important; }

.ya-share2__badge {
  background: transparent !important; }

.ya-share2__list_direction_horizontal > .ya-share2__item {
  margin: 0 12px !important; }

.tau-btn {
  display: inline-block;
  padding: 16px 15px 15px;
  width: 400px;
  text-align: center;
  border-radius: 5px;
  font: bold 1.25em Panton;
  cursor: pointer;
  box-sizing: border-box;
  transition: all .3s ease !important; }
  .tau-btn_s {
    display: inline-block;
    padding: 6px 35px;
    border-radius: 5px;
    font: bold 1.125em Panton;
    cursor: pointer;
    transition: all .3s ease !important; }
  .tau-btn_m {
    display: inline-block;
    padding: 10px 45px;
    border-radius: 5px;
    font: bold 1.125em Panton;
    cursor: pointer;
    transition: all .3s ease !important; }
  .tau-btn_red {
    background: #EB7E7F;
    color: #fff !important; }
    .tau-btn_red:hover {
      background: #fc8f90; }
  .tau-btn_blue {
    background: #7186CF;
    color: #fff !important; }
    .tau-btn_blue:hover {
      background: #8297e0; }
  .tau-btn_white {
    background: #fff;
    color: #525F8B !important; }
    .tau-btn_white:hover {
      box-shadow: inset -100px 0 100px -100px rgba(0, 0, 0, 0.2); }
    .tau-btn_white_s {
      background: #fff;
      color: #2F438B !important; }
      .tau-btn_white_s:hover {
        box-shadow: inset -100px 0 100px -100px rgba(0, 0, 0, 0.2); }
  .tau-btn_filter {
    border-radius: 5px;
    border: 2px solid #7186CF;
    padding: 1px 45px 0;
    display: inline-block; }
    .tau-btn_filter span {
      color: #7186CF;
      line-height: 2.4em; }
    .tau-btn_filter .tau-label-filter {
      display: inline-block;
      margin-left: 30px;
      vertical-align: sub; }
    .tau-btn_filter-search {
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 0 5px 5px 0;
      font: bold 1em Panton;
      background: #7186CF;
      color: #fff !important;
      transition: all .3s ease !important; }
      .tau-btn_filter-search:hover {
        background: #8297e0; }

@media (max-width: 480px) {
  .tau-sbtn_blue {
    padding: 9px 15px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font: bold 1.05em Panton;
    cursor: pointer;
    background: #7186CF;
    color: #fff !important; }
    .tau-sbtn_blue:hover {
      background: #7186CF; }
  .tau-sbtn_white {
    padding: 9px 15px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font: bold 1.05em Panton;
    cursor: pointer;
    background: #fff;
    color: #333 !important; }
    .tau-sbtn_white:hover {
      background: #fff; }
  .tau-btn_filter-search {
    padding: 9px 10px; }
  .tau-btn_filter-clear {
    padding: 7px 10px;
    text-align: center;
    font: bold 1em Panton;
    border: 2px solid #7186CF;
    color: #7186CF !important; } }

.tau-icon {
  display: block; }

.tau-search {
  height: 23px;
  width: 23px;
  cursor: pointer;
  background-image: url(../images/icons/icon-search.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-lk {
  position: absolute;
  height: 18px;
  width: 12px;
  left: 20px;
  top: 8px;
  background-image: url(../images/icons/icon-lk.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

@media (max-width: 480px) {
  .tau-lk {
    position: relative;
    left: 0;
    top: 0; } }

.tau-vision {
  width: 31px;
  height: 15px;
  margin: 14px auto;
  background-image: url(../images/icons/icon-vision.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-phone {
  width: 18px;
  height: 16px;
  top: 13px;
  background-image: url(../images/icons/icon-phone.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-handset {
  width: 16px;
  height: 16px;
  background-image: url(../images/icons/icon-handset.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-email {
  width: 15px;
  height: 12px;
  top: 16px;
  background-image: url(../images/icons/icon-email.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-email-gray {
    width: 15px;
    height: 15px;
    background-image: url(../images/icons/icon-email-gray.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-time {
  width: 16px;
  height: 16px;
  top: 14px;
  background-image: url(../images/icons/icon-time.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-review {
  width: 16px;
  height: 16px;
  top: 12px;
  background-image: url(../images/icons/icon-review.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-date {
  width: 14px;
  height: 14px;
  background-image: url(../images/icons/icon-date.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-age {
  width: 15px;
  height: 15px;
  background-image: url(../images/icons/icon-age.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-participant {
  width: 14px;
  height: 16px;
  background-image: url(../images/icons/icon-participant.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-vk {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/icon-vk.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-vk-rp {
    height: 30px;
    width: 30px;
    background-image: url(../images/icons/icon-vk-rp.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }
  .tau-vk-menu {
    height: 24px;
    width: 24px;
    background-image: url(../images/icons/icon-vk-menu.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-fb {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/icon-fb.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-fb-rp {
    height: 30px;
    width: 30px;
    background-image: url(../images/icons/icon-fb-rp.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }
  .tau-fb-menu {
    height: 24px;
    width: 24px;
    background-image: url(../images/icons/icon-fb-menu.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-inst {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/icon-inst.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-inst-menu {
    width: 24px;
    height: 24px;
    background-image: url(../images/icons/icon-inst-menu.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-tel-rp {
  height: 30px;
  width: 30px;
  background-image: url(../images/icons/icon-tel-rp.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-tw-rp {
  height: 30px;
  width: 30px;
  background-image: url(../images/icons/icon-tw-rp.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-doc {
  width: 20px;
  height: 20px;
  background-image: url(../images/icons/icon-doc.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

@media (max-width: 480px) {
  .tau-doc {
    width: 15px;
    height: 15px; } }

.tau-close {
  width: 20px;
  height: 20px;
  background-image: url(../images/icons/icon-close.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-label {
  width: 11px;
  height: 15px;
  background-image: url(../images/icons/icon-label.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-label-filter {
  width: 14px;
  height: 17px;
  background-image: url(../images/icons/icon-filter.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-ok {
  height: 25px;
  width: 25px;
  background-image: url(../images/icons/icon-ok.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-no {
  height: 25px;
  width: 25px;
  background-image: url(../images/icons/icon-no.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-students-grid-1 {
  width: 67px; }

.tau-students-grid-2 {
  width: 46px; }
  .tau-students-grid-2 use {
    transform: translate(-72px, 0); }

.tau-students-grid-3 {
  width: 56px; }
  .tau-students-grid-3 use {
    transform: translate(-120px, 0); }

.tau-students-grid-4 {
  width: 40px; }
  .tau-students-grid-4 use {
    transform: translate(-177px, 0); }

.tau-students-grid-5 {
  width: 50px; }
  .tau-students-grid-5 use {
    transform: translate(-221px, 0); }

.tau-students-grid-6 {
  width: 55px; }
  .tau-students-grid-6 use {
    transform: translate(-279px, 0); }

.tau-students-grid-7 {
  width: 56px; }
  .tau-students-grid-7 use {
    transform: translate(-338px, 0); }

.tau-students-grid-8 {
  width: 69px; }
  .tau-students-grid-8 use {
    transform: translate(-401px, 0); }

.tau-partners-grid-1 {
  width: 69px; }
  .tau-partners-grid-1 use {
    transform: translate(0, -60px); }

.tau-partners-grid-2 {
  width: 53px; }
  .tau-partners-grid-2 use {
    transform: translate(-75px, -60px); }

.tau-about-grid-1 {
  width: 50px; }
  .tau-about-grid-1 use {
    transform: translate(0, -120px); }

.tau-about-grid-2 {
  width: 64px; }
  .tau-about-grid-2 use {
    transform: translate(-54px, -120px); }

.tau-about-grid-3 {
  width: 61px; }
  .tau-about-grid-3 use {
    transform: translate(-121px, -120px); }

.tau-about-grid-4 {
  width: 51px; }
  .tau-about-grid-4 use {
    transform: translate(-188px, -120px); }

.tau-about-grid-5 {
  width: 50px; }
  .tau-about-grid-5 use {
    transform: translate(-246px, -120px); }

.tau-about-grid-6 {
  width: 65px; }
  .tau-about-grid-6 use {
    transform: translate(-300px, -120px); }

.tau-about-grid-7 {
  width: 41px; }
  .tau-about-grid-7 use {
    transform: translate(-370px, -120px); }

.tau-about-grid-8 {
  width: 56px; }
  .tau-about-grid-8 use {
    transform: translate(-414px, -120px); }

.tau-about-grid-9 {
  width: 48px; }
  .tau-about-grid-9 use {
    transform: translate(-474px, -120px); }

.tau-about-grid-10 {
  width: 59px; }
  .tau-about-grid-10 use {
    transform: translate(-530px, -120px); }

.tau-about-grid-11 {
  width: 50px; }
  .tau-about-grid-11 use {
    transform: translate(0, -120px); }

.tau-services-grid-1 {
  width: 55px; }
  .tau-services-grid-1 use {
    transform: translate(0, -180px); }

.tau-services-grid-2 {
  width: 50px; }
  .tau-services-grid-2 use {
    transform: translate(-63px, -180px); }

.tau-services-grid-3 {
  width: 50px; }
  .tau-services-grid-3 use {
    transform: translate(-123px, -180px); }

.tau-services-grid-4 {
  width: 50px; }
  .tau-services-grid-4 use {
    transform: translate(-179px, -180px); }

.tau-services-grid-5 {
  width: 55px; }
  .tau-services-grid-5 use {
    transform: translate(-238px, -180px); }

.tau-services-grid-6 {
  width: 38px; }
  .tau-services-grid-6 use {
    transform: translate(-298px, -180px); }

.tau-services-grid-7 {
  width: 64px; }
  .tau-services-grid-7 use {
    transform: translate(-345px, -180px); }

.tau-services-grid-8 {
  width: 67px; }
  .tau-services-grid-8 use {
    transform: translate(-416px, -180px); }

.tau-services-grid-9 {
  width: 69px; }
  .tau-services-grid-9 use {
    transform: translate(-487px, -180px); }

.tau-form-wrap form {
  padding: 10px 0;
  text-align: left; }

.tau-form-label {
  display: inline-block;
  margin-bottom: 12px; }
  .tau-form-label-file {
    font-size: 0.875em;
    color: #2F438B;
    text-align: center;
    line-height: 36px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: 2px solid #7186CF;
    border-radius: 20px;
    width: 60%;
    max-width: 230px;
    user-select: none;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.tau-form__field {
  margin-bottom: 30px;
  position: relative; }
  .tau-form__field-desc {
    color: #333;
    margin: 10px 0 60px;
    position: relative;
    padding-left: 12px; }
    .tau-form__field-desc:before {
      content: '*';
      position: absolute;
      left: 0;
      top: 0; }

.tau-form__buttons {
  text-align: center; }

.tau-form-success {
  text-align: center;
  color: #338e37; }

.tau-form-error {
  text-align: center;
  color: #EB7E7F; }

.tau-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  transition: border .2s ease; }
  .tau-input_text {
    padding: 8px 15px 7px;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.05);
    position: relative; }
    .tau-input_text:focus {
      border: 1px solid #7186CF; }
    .tau-input_text:disabled {
      background: #fff;
      color: #929292; }
    .tau-input_text-phone {
      padding-left: 35px; }
  .tau-input_file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .tau-input_select {
    padding: 8px 15px 7px;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.05);
    position: relative; }
    .tau-input_select:focus {
      border: 1px solid #7186CF; }
  .tau-input_radio {
    margin: 0;
    position: absolute;
    height: 0;
    left: -15px;
    visibility: hidden; }
    .tau-input_radio + label {
      cursor: pointer; }
      .tau-input_radio + label:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        height: 28px;
        width: 28px;
        background: #fff;
        border-radius: 50%;
        border: 2px solid #bdbdbd;
        box-shadow: inset 0 0 0 5px #fff; }
    .tau-input_radio:checked + label:before {
      background: #7186CF; }
  .tau-input-desc {
    display: inline-block;
    color: #929292;
    margin-left: 5px;
    font-size: .875em;
    user-select: none;
    max-width: 200px;
    width: 36%;
    vertical-align: middle; }

.tau-textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 15px 7px;
  border: 1px solid #cecece;
  border-radius: 5px;
  box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.05);
  transition: border .2s ease; }
  .tau-textarea:focus {
    border: 1px solid #7186CF; }
  .tau-textarea:disabled {
    background: #fff;
    color: #929292; }

.tau-error {
  border: 1px solid #EB7E7F; }

.tau-phone-prefix {
  color: #929292;
  font: normal .875em/1.47em Istok Web;
  position: absolute;
  bottom: 9px;
  left: 16px; }

@media (max-width: 480px) {
  .tau-form-label {
    margin-bottom: 15px; }
    .tau-form-label-file {
      margin: 15px 0;
      font-family: Panton;
      font-weight: bold;
      font-size: 1em;
      color: #7186CF;
      border-radius: 5px; }
  .tau-form__field {
    margin-bottom: 15px; }
    .tau-form__field-desc {
      margin-bottom: 40px; }
  .tau-input-desc {
    font-size: .715em; } }

.tau-header__logo {
  height: 75px;
  width: 75px;
  display: inline-block;
  vertical-align: top;
  background-image: url(../images/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.tau-header__logo-text {
  display: inline-block;
  position: relative;
  margin-left: 6px; }
  .tau-header__logo-text-tau {
    height: 75px;
    background-image: url(../images/logo-text.svg);
    background-repeat: no-repeat;
    background-position: 0 22px;
    background-size: auto 40px; }
  .tau-header__logo-text-other {
    color: #B3B8CC;
    display: block;
    margin-top: -12px;
    font-family: Panton;
    font-weight: bold;
    font-size: .875em; }

.tau-header__menu {
  padding-top: 5px; }
  .tau-header__menu .tau-header__lk, .tau-header__menu .tau-social {
    display: none; }
  .tau-header__menu-item {
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .tau-header__menu-item > a {
      font-family: Panton;
      font-weight: bold;
      font-size: 1.125em;
      padding: 20px;
      position: relative; }
    .tau-header__menu-item:hover > a {
      background: #fff;
      box-shadow: inset 0 5px 0 #EB7E7F, 0px -22px 28px rgba(0, 0, 0, 0.07);
      z-index: 6; }
    .tau-header__menu-item:hover .tau-header__submenu {
      display: block; }
    .tau-header__menu-item_active > a {
      box-shadow: inset 0 5px 0 #EB7E7F; }
    .tau-header__menu-item_with_submenu > a:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 5px;
      width: 5px;
      border: 2px solid #2F438B;
      border-left: none;
      border-top: none;
      transform: rotate(45deg);
      margin: -3px 0 0 5px; }

.tau-header__submenu {
  display: none;
  position: absolute;
  background: #fff;
  width: 410px;
  padding: 20px 0;
  box-shadow: 0 12px 42px rgba(0, 0, 0, 0.15);
  z-index: 5; }
  .tau-header__submenu li {
    display: block; }
    .tau-header__submenu li a {
      font-family: Istok Web;
      font-weight: normal;
      font-size: 1em;
      padding: 10px 20px;
      transition: background .2s ease; }
      .tau-header__submenu li a:hover {
        background: #ECEEF4;
        box-shadow: none; }

.tau-header__right-wrapper {
  height: 75px; }

.tau-header__search {
  margin: auto 15px auto 0;
  position: relative; }

.tau-header__lk {
  margin: auto;
  position: relative;
  font-family: Panton;
  font-weight: bold;
  line-height: 2.375em;
  padding-left: 40px;
  border-left: 1px solid #cecece; }

.tau-header__hamburger {
  display: none;
  margin: auto;
  padding-left: 30px; }
  .tau-header__hamburger a {
    display: block;
    height: 2px;
    width: 20px;
    background: #525F8B;
    position: relative; }
    .tau-header__hamburger a:before {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #525F8B;
      position: absolute;
      top: -7px;
      left: 0;
      transition: all .2s ease; }
    .tau-header__hamburger a:after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #525F8B;
      position: absolute;
      bottom: -7px;
      left: 0;
      transition: all .2s ease; }

.tau-header__vision {
  position: absolute;
  top: -25px;
  right: 40px;
  width: 45px;
  height: 45px;
  background: #EB7E7F; }

@media (max-width: 480px) {
  .tau-header__logo {
    height: 37px;
    width: 37px; }
    .tau-header__logo-text {
      margin-left: 0; }
      .tau-header__logo-text-tau {
        height: 40px;
        width: 45px;
        background-position: 0;
        background-size: contain; }
      .tau-header__logo-text-other {
        display: none; }
  .tau-header__right-wrapper {
    height: auto; }
  .tau-header__left-wrapper a {
    height: 37px; }
  .tau-header__search {
    margin: auto 0; }
  .tau-header__lk {
    border: none;
    padding-left: 25px; }
    .tau-header__lk-ex a span {
      display: none; }
  .tau-header__hamburger {
    display: block; }
  .tau-header__vision {
    display: none; }
  .tau-header__menu {
    display: none;
    position: fixed;
    top: 59px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 35px;
    z-index: 100;
    background: #fff; }
    .tau-header__menu ul {
      padding: 0 20px; }
      .tau-header__menu ul:before, .tau-header__menu ul:after {
        content: '';
        display: block;
        height: 1px;
        width: 115px;
        background: #C7C2CD; }
    .tau-header__menu-item {
      display: block; }
      .tau-header__menu-item:hover .tau-header__submenu {
        display: none; }
      .tau-header__menu-item > a {
        font-size: 1.25em;
        padding: 12px 0; }
        .tau-header__menu-item > a:hover {
          box-shadow: none; }
      .tau-header__menu-item_active a {
        box-shadow: none; }
      .tau-header__menu-item:first-child a {
        padding: 24px 0 12px; }
      .tau-header__menu-item:last-child a {
        padding: 12px 0 30px; }
      .tau-header__menu-item_with_submenu > a:after {
        display: none; }
    .tau-header__menu .tau-header__lk {
      display: block;
      margin-bottom: 25px; }
      .tau-header__menu .tau-header__lk i {
        display: inline-block;
        vertical-align: middle; }
      .tau-header__menu .tau-header__lk span {
        vertical-align: middle;
        font-weight: normal;
        margin-left: 6px; }
    .tau-header__menu .tau-social {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0; }
      .tau-header__menu .tau-social__item {
        display: inline-block !important; }
  .tau-open-menu .tau-header__search, .tau-open-menu .tau-header__lk {
    display: none; }
  .tau-open-menu .tau-header__menu {
    display: block; }
    .tau-open-menu .tau-header__menu .tau-header__lk, .tau-open-menu .tau-header__menu .tau-social {
      display: block; }
  .tau-open-menu .tau-header__hamburger a {
    height: 0; }
    .tau-open-menu .tau-header__hamburger a:before {
      top: -1px;
      background: #4F4F4F;
      transform: rotate(45deg); }
    .tau-open-menu .tau-header__hamburger a:after {
      bottom: -1px;
      background: #4F4F4F;
      transform: rotate(-45deg); } }

.tau-footer__logo {
  height: 50px;
  background-image: url(../images/logo-white.svg);
  background-repeat: no-repeat;
  background-size: contain; }

.tau-footer__info {
  font-size: .875em;
  margin-top: 25px; }

.tau-footer__grid {
  flex-wrap: wrap; }

.tau-footer__item {
  flex: 0 1 25%;
  text-align: center; }
  .tau-footer__item-with-logo {
    display: flex;
    text-align: left; }
    .tau-footer__item-with-logo > div {
      margin: auto 0; }

.tau-footer__menu {
  display: inline-block;
  text-align: left; }
  .tau-footer__menu li a, .tau-footer__menu li span {
    font-family: Panton;
    font-weight: bold;
    padding: 8px 0 7px; }
  .tau-footer__menu_with_icons li {
    padding-left: 25px;
    position: relative; }
    .tau-footer__menu_with_icons li a, .tau-footer__menu_with_icons li span {
      padding: 8px 0 7px;
      vertical-align: middle; }
    .tau-footer__menu_with_icons li .tau-icon {
      position: absolute;
      left: 0; }

.tau-social {
  text-align: center; }
  .tau-social__item {
    display: inline-block;
    margin: 0 9px; }

@media (max-width: 480px) {
  .tau-footer__grid {
    padding: 0 20px; }
  .tau-footer__info {
    margin: 0; }
  .tau-footer__item {
    flex: 0 1 100%;
    text-align: left;
    margin-bottom: 35px; }
    .tau-footer__item:nth-child(1) {
      order: 3; }
    .tau-footer__item:nth-child(2) {
      order: 1; }
    .tau-footer__item:nth-child(3) {
      order: 2; }
    .tau-footer__item:nth-child(4) {
      order: 4;
      margin-bottom: 0;
      margin-top: 15px; }
  .tau-footer__logo {
    display: none; }
  .tau-footer__menu li a, .tau-footer__menu li span {
    padding: 8px 0 18px; }
  .tau-footer__menu_with_icons li:last-child {
    margin-top: -18px; } }

.tau-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: -17px;
  display: none;
  z-index: 10000;
  overflow-x: auto;
  background: rgba(51, 51, 51, 0.3);
  padding: 40px 0; }
  .tau-modal_open {
    display: flex;
    animation: show-modal .2s ease; }
    .tau-modal_open .tau-modal__wrap {
      display: block;
      animation: show-modal-wrap .4s ease; }
  .tau-modal_close {
    animation: hide-modal .3s ease;
    opacity: 0; }
    .tau-modal_close .tau-modal__wrap {
      animation: hide-modal-wrap .2s ease;
      opacity: 0; }
  .tau-modal__wrap {
    background: #fff;
    border-radius: 4px;
    padding: 55px;
    flex-basis: 20%;
    box-sizing: border-box;
    position: relative;
    margin: auto;
    z-index: 1;
    display: none;
    transition: all .8s ease; }
  .tau-modal__title {
    font-family: Panton;
    font-weight: bold;
    font-size: 2.125em;
    color: #333;
    margin-bottom: 45px;
    text-align: center; }
  .tau-modal__close {
    position: absolute;
    right: 35px;
    top: 35px;
    cursor: pointer; }

.tau-form-feedback {
  width: 400px;
  padding: 0 60px;
  box-sizing: border-box; }

.tau-form-vacancy {
  width: 620px;
  padding: 0 80px;
  box-sizing: border-box; }

.tau-form-auth {
  width: 350px;
  padding: 0 80px;
  box-sizing: border-box; }

.tau-form-business {
  width: 400px;
  padding: 0 60px;
  box-sizing: border-box; }

@media (max-width: 480px) {
  .tau-modal {
    padding: 0;
    right: -9px; }
    .tau-modal__wrap {
      border-radius: 0;
      flex-basis: 100%;
      min-height: 100%;
      padding: 50px 29px 50px 20px; }
    .tau-modal__close {
      top: 25px;
      right: 29px; }
    .tau-modal__title {
      margin-bottom: 35px; }
  .tau-form-feedback, .tau-form-vacancy, .tau-form-auth, .tau-form-business {
    padding: 0 5px;
    width: auto; } }

.tau-tab-title_open + .tau-tab-content {
  height: auto; }

.tau-tab-content {
  height: 0;
  overflow: hidden; }

.tau-gtab__titles {
  display: flex; }

.tau-gtab__title {
  flex: 0 0 50%;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #333;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  box-sizing: border-box; }
  .tau-gtab__title_open {
    color: #fff !important;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); }
  .tau-gtab__title:nth-child(odd).tau-gtab__title_open {
    background: #EB7E7F; }
  .tau-gtab__title:nth-child(even).tau-gtab__title_open {
    background: #7186CF; }

.tau-gtab__content {
  display: none; }
  .tau-gtab__content_open {
    display: block !important; }
  .tau-gtab__content:nth-child(odd).tau-gtab__content_open {
    box-shadow: 0 -1px 0 #EB7E7F; }
  .tau-gtab__content:nth-child(even).tau-gtab__content_open {
    box-shadow: 0 -1px 0 #7186CF; }

.tau-table {
  width: 100%;
  min-width: 550px;
  display: table;
  margin: 40px 0 50px;
  border: 1px solid #E3E3E4; }
  .tau-table__header {
    display: flex;
    width: 100%;
    background: #ECEEF4;
    text-align: center; }
    .tau-table__header > div {
      display: table-cell;
      padding: 25px 20px;
      font-weight: bold;
      line-height: 1.5em;
      border-bottom: 1px solid #E3E3E4;
      border-right: 1px solid #E3E3E4; }
      .tau-table__header > div:nth-child(1) {
        width: 6%; }
      .tau-table__header > div:nth-child(2) {
        width: 35%; }
      .tau-table__header > div:nth-child(3) {
        width: 29%; }
      .tau-table__header > div:nth-child(4) {
        width: 30%; }
      .tau-table__header > div:last-child {
        border-right: none; }
  .tau-table__body {
    width: 100%;
    display: table; }
  .tau-table__item {
    display: table-row; }
    .tau-table__item:last-child > div {
      border-bottom: none; }
    .tau-table__item > div {
      display: table-cell;
      padding: 25px 20px;
      width: 20%;
      font-size: .875em;
      text-align: center;
      border-bottom: 1px solid #E3E3E4;
      border-right: 1px solid #E3E3E4; }
      .tau-table__item > div:nth-child(1) {
        width: 6%; }
      .tau-table__item > div:nth-child(2) {
        width: 35%;
        text-align: left; }
      .tau-table__item > div:nth-child(3) {
        width: 29%; }
      .tau-table__item > div:nth-child(4) {
        width: 30%; }
      .tau-table__item > div:last-child {
        font-size: 1.125em;
        font-weight: bold;
        border-right: none; }
  .tau-table_big {
    width: 100%;
    min-width: 550px;
    display: table;
    margin: 0 0 90px;
    position: relative; }
    .tau-table_big__wrap {
      margin-top: 50px; }
    .tau-table_big__header {
      display: flex;
      width: 100%; }
      .tau-table_big__header > div {
        display: block;
        width: 20%;
        padding: 20px 0;
        font-weight: bold;
        line-height: 1.5em;
        color: #2F438B;
        border: 1px solid #E3E3E4;
        border-left: none; }
        .tau-table_big__header > div:first-child {
          border-left: 1px solid #E3E3E4; }
        .tau-table_big__header > div span {
          display: block;
          text-align: center; }
        .tau-table_big__header > div.tau-table_big__header-item_light {
          background: rgba(113, 134, 207, 0.1); }
        .tau-table_big__header > div.tau-table_big__header-item_blue {
          background: #8297e0;
          color: #fff;
          border: 1px solid #7186CF;
          border-left: none; }
        .tau-table_big__header > div.tau-table_big__col_active:before {
          content: '';
          position: absolute;
          top: 0;
          width: 20%;
          height: 100%;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          transition: all .2s ease; }
      .tau-table_big__header_additional {
        font-size: 1.25em;
        font-weight: bold;
        color: #fff;
        background: #7186CF;
        text-align: center;
        padding: 15px;
        display: block;
        width: 60%;
        float: right;
        border-radius: 5px 5px 0 0;
        margin-top: 30px; }
      .tau-table_big__header_simple {
        display: flex; }
        .tau-table_big__header_simple > div {
          background: #7186CF;
          border-bottom: none;
          display: table-cell;
          padding: 20px 0;
          width: 20%;
          font-weight: bold;
          line-height: 1.5em;
          color: #fff;
          transition: all .2s ease; }
          .tau-table_big__header_simple > div span {
            display: block;
            text-align: center; }
          .tau-table_big__header_simple > div:first-child {
            border-radius: 5px 0 0 0; }
          .tau-table_big__header_simple > div:last-child {
            border-radius: 0 5px 0 0; }
          .tau-table_big__header_simple > div.tau-table_big__col_active {
            background: #8297e0; }
            .tau-table_big__header_simple > div.tau-table_big__col_active:before {
              content: '';
              position: absolute;
              top: 0;
              width: 20%;
              height: 100%;
              box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
              transition: all .2s ease; }
    .tau-table_big__body {
      width: 100%;
      display: table; }
    .tau-table_big__item {
      display: table-row; }
      .tau-table_big__item.tau-table_big__item_blue {
        color: #2F438B; }
      .tau-table_big__item .tau-icon {
        margin: auto; }
      .tau-table_big__item.tau-table_big__item_finally > div {
        height: 360px; }
      .tau-table_big__item > div {
        display: table-cell;
        padding: 20px 25px;
        width: 20%;
        font-size: .875em;
        position: relative;
        vertical-align: top;
        border-bottom: 1px solid #E3E3E4;
        border-right: 1px solid #E3E3E4; }
        .tau-table_big__item > div:nth-child(2) {
          text-align: left; }
        .tau-table_big__item > div:first-child {
          border-left: 1px solid #E3E3E4; }
        .tau-table_big__item > div p {
          margin-bottom: 20px; }
    .tau-table_big__price {
      font-family: Panton;
      font-weight: bold;
      font-size: 1.125em;
      color: #333;
      text-transform: uppercase;
      margin-bottom: 25px; }
      .tau-table_big__price-additional {
        font-size: .875em; }
    .tau-table_big__btn {
      position: absolute;
      bottom: 50px;
      left: 50%;
      margin-left: -87px; }

@media (max-width: 480px) {
  .tau-table {
    margin: 0; }
    .tau-table__wrap {
      margin: 20px 20px 40px;
      overflow-y: scroll; }
    .tau-table__header > div {
      padding: 10px;
      font-weight: normal; }
    .tau-table__item > div {
      padding: 10px;
      font-size: 1em; }
      .tau-table__item > div:nth-child(2) {
        text-align: left; }
      .tau-table__item > div:last-child {
        font-size: 1em;
        font-weight: normal; }
    .tau-table_big {
      margin: 0; }
      .tau-table_big__wrap {
        margin: 20px 20px 40px;
        overflow-y: scroll; }
      .tau-table_big__btn {
        width: auto;
        margin-left: -49px; }
      .tau-table_big__header_additional {
        display: none; } }

.tau-search-form {
  overflow: hidden;
  position: absolute;
  right: -15px;
  top: -7px;
  background: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  z-index: 10; }
  .tau-search-form form {
    width: 0;
    transition: all .3s ease; }
  .tau-search-form_open {
    width: 400px !important; }
  .tau-search-form-input input {
    line-height: 2.4em;
    padding: 0 50px 0 15px;
    width: 100%;
    box-sizing: border-box; }
    .tau-search-form-input input:focus {
      border: none;
      outline: none; }
  .tau-search-form-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    padding: 7px 15px 0 0; }

.tau-search__content {
  max-width: 920px;
  margin: 0 auto 70px; }

.tau-search__list {
  margin-bottom: 40px; }

.tau-search__item {
  padding: 30px 0;
  border-top: 1px solid #c4c4c4; }
  .tau-search__item:first-child {
    border: none; }
  .tau-search__item-title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.15em;
    color: #2F438B !important;
    margin-bottom: 7px;
    display: inline-block; }
  .tau-search__item-path {
    margin-top: 5px; }
    .tau-search__item-path a {
      font-size: .875em; }

@media (max-width: 480px) {
  .tau-search__content {
    margin: 0 0 40px;
    padding: 0 20px; }
  .tau-search__list {
    margin-bottom: 20px; }
  .tau-search__item {
    padding: 20px 0; }
    .tau-search__item:first-child {
      padding-top: 0; }
  .tau-search-form form {
    height: 0;
    width: 100% !important;
    position: fixed;
    top: 59px;
    left: 0;
    overflow: hidden;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1); }
  .tau-search-form_open {
    height: auto !important; } }

.tau-loader, .tau-loader:before, .tau-loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: loading 1.8s infinite ease-in-out; }

.tau-loader {
  color: #8297e0;
  font-size: 10px;
  margin: 0 auto 3em;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s; }
  .tau-loader:before, .tau-loader:after {
    content: '';
    position: absolute;
    top: 0; }
  .tau-loader:before {
    left: -3em;
    animation-delay: -0.32s; }
  .tau-loader:after {
    left: 3em; }

.tau-breadcrumbs__item {
  display: inline-block; }
  .tau-breadcrumbs__item a, .tau-breadcrumbs__item span {
    font-size: .75em; }
  .tau-breadcrumbs__item a {
    color: #2F438B; }
    .tau-breadcrumbs__item a:after {
      content: '/';
      display: inline-block;
      color: #666;
      margin-left: 6px; }
  .tau-breadcrumbs__item span {
    color: #666;
    margin-left: 6px; }

@media (max-width: 480px) {
  .tau-breadcrumbs {
    display: none; } }

.tau-pagination {
  text-align: center; }
  .tau-pagination__item {
    display: inline-block;
    margin: 0 8px; }
    .tau-pagination__item a, .tau-pagination__item span {
      font-size: 1.125em;
      color: #2F438B; }
    .tau-pagination__item_active a, .tau-pagination__item_active span {
      color: #333;
      font-weight: bold; }

.tau-dropdown {
  padding: 13px 25px;
  position: relative;
  box-shadow: inset -1px 0 #c4c4c4; }
  .tau-dropdown__wrap {
    background: #fff;
    z-index: 2;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    width: inherit; }
  .tau-dropdown_open {
    box-shadow: 0 0 0 1px #c4c4c4;
    background: #fff; }
    .tau-dropdown_open .tau-dropdown__value:after {
      transform: rotate(-135deg);
      top: 23px; }
    .tau-dropdown_open .tau-dropdown__wrap {
      box-shadow: 0 0 0 1px #c4c4c4, 0 2px 4px rgba(0, 0, 0, 0.07); }
    .tau-dropdown_open .tau-dropdown__list {
      display: block; }
  .tau-dropdown_calendar .tau-dropdown__value:after {
    content: '';
    height: 18px;
    width: 18px;
    right: 24px;
    position: absolute;
    top: 15px;
    border: none;
    transform: none;
    background-image: url(../images/icons/icon-calendar.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }
  .tau-dropdown__value {
    cursor: pointer;
    color: #333; }
    .tau-dropdown__value span {
      font-size: 1em;
      user-select: none;
      position: relative;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 20px; }
    .tau-dropdown__value:after {
      content: '';
      height: 6px;
      width: 6px;
      right: 27px;
      position: absolute;
      top: 18px;
      border: 1px solid #2F438B;
      border-left: none;
      border-top: none;
      transform: rotate(45deg); }
    .tau-dropdown__value_blue {
      color: #2F438B; }
    .tau-dropdown__value-count {
      color: #2F438B;
      display: inline !important;
      padding-left: 8px; }
    .tau-dropdown__value-sub {
      position: absolute !important;
      font-size: .875em !important;
      color: #4F4F4F;
      top: -15px; }
  .tau-dropdown__list {
    display: none; }
    .tau-dropdown__list li {
      display: block;
      padding: 9px 13px 9px 40px;
      cursor: pointer;
      position: relative;
      transition: all .2s ease; }
      .tau-dropdown__list li:first-child {
        margin-top: 6px; }
      .tau-dropdown__list li:last-child {
        margin-bottom: 6px; }
      .tau-dropdown__list li:before {
        content: '';
        height: 18px;
        width: 18px;
        border-radius: 50%;
        position: absolute;
        border: 1px solid #7186CF;
        left: 13px;
        top: 11px; }
      .tau-dropdown__list li:after {
        content: '';
        display: none;
        position: absolute;
        width: 3px;
        height: 6px;
        left: 21px;
        top: 16px;
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg); }
  .tau-dropdown__item_active:before {
    background: #7186CF; }
  .tau-dropdown__item_active:after {
    display: block !important; }

@media (max-width: 480px) {
  .tau-dropdown {
    width: 100%;
    box-shadow: none;
    padding: 0;
    border-bottom: 1px solid #c4c4c4; }
    .tau-dropdown_open .tau-dropdown__wrap {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07); }
    .tau-dropdown__wrap {
      position: fixed;
      top: 56px;
      padding: 8px; }
    .tau-dropdown__list li {
      line-height: 1.7em;
      padding: 7px 12px 7px 40px; }
      .tau-dropdown__list li:last-child {
        margin-bottom: 4px; }
      .tau-dropdown__list li:before {
        top: 8px; }
      .tau-dropdown__list li:after {
        top: 13px; }
    .tau-dropdown__value {
      padding: 15px 0; }
      .tau-dropdown__value:after {
        display: none; }
      .tau-dropdown__value span {
        font-size: 1em;
        font-weight: bold;
        font-family: Panton;
        line-height: 1.7em;
        display: inline-flex;
        padding: 0; }
      .tau-dropdown__value-count {
        margin-left: 5px; } }

.tau-docs__item {
  padding-left: 25px;
  margin-bottom: 15px; }
  .tau-docs__item .tau-icon {
    float: left; }
  .tau-docs__item a {
    margin-left: 15px;
    color: #2F438B; }

.tau-docs__list-title {
  font-weight: bold;
  margin: 40px 0 20px; }
  .tau-docs__list-title + ul li {
    padding-left: 0  !important; }

@media (max-width: 480px) {
  .tau-docs__item {
    padding: 0 20px;
    margin-bottom: 10px; }
    .tau-docs__item .tau-icon {
      margin-top: 3px; }
    .tau-docs__item a {
      margin: 0;
      display: block;
      padding-left: 20px;
      font-size: .85em; }
  .tau-docs__list-title {
    margin: 20px 0;
    padding: 0 20px; } }

.tau-filter {
  text-align: center; }
  .tau-filter__grid {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07); }
    .tau-filter__grid-full {
      width: 100%; }
      .tau-filter__grid-full .tau-filter__item {
        min-width: auto; }
  .tau-filter__title {
    display: none;
    font-family: Panton;
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
    padding: 16px 0;
    border-bottom: 1px solid rgba(113, 134, 207, 0.2); }
  .tau-filter__close {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px; }
  .tau-filter__return {
    display: none;
    position: absolute;
    top: 7px;
    left: 10px;
    padding: 15px; }
    .tau-filter__return span {
      height: 12px;
      width: 12px;
      display: block;
      border: 1px solid #41688c;
      border-bottom: none;
      border-right: none;
      transform: rotate(-45deg); }
  .tau-filter__item {
    flex: 1 0 16%;
    min-width: 215px;
    box-sizing: border-box;
    text-align: left; }
    .tau-filter__item:last-child .tau-dropdown:not(.tau-dropdown_open) {
      box-shadow: none; }
    .tau-filter__item .tau-dropdown__clear {
      display: none; }
  .tau-filter__info {
    margin: 40px 0 25px; }
    .tau-filter__info > * {
      padding: 0 10px; }
    .tau-filter__info a {
      color: #2F438B; }
  .tau-filter__input {
    padding: 13px 25px;
    width: 100%;
    box-sizing: border-box; }
    .tau-filter__input::placeholder {
      color: #333;
      font-size: 1em; }
  .tau-filter-button {
    display: none;
    text-align: center; }

@media (max-width: 480px) {
  .tau-filter {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    display: none; }
    .tau-filter_open {
      display: block; }
    .tau-filter__grid {
      box-shadow: none; }
    .tau-filter__title, .tau-filter__close {
      display: block; }
    .tau-filter__item {
      flex: 0 1 100%;
      padding: 0 20px; }
      .tau-filter__item a {
        display: inline-block !important; }
      .tau-filter__item:last-child {
        text-align: center;
        margin-top: 10px; }
        .tau-filter__item:last-child a {
          border-radius: 5px;
          width: 105px;
          margin: 0 5px; }
    .tau-filter__info {
      display: none; }
    .tau-filter__input {
      width: auto;
      padding: 15px 0;
      font-size: 1em; }
      .tau-filter__input::placeholder {
        font-family: Panton;
        font-weight: bold; }
    .tau-filter-button {
      display: block; } }

.tau-news__content .tau-pagination {
  margin-bottom: 85px; }

.tau-news__grid {
  margin: 0 -20px 60px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-news__item {
  flex: 0 1 25%;
  margin: 25px 0; }
  .tau-news__item figure {
    padding: 0 20px; }

.tau-news__photo {
  height: 270px;
  border-radius: 5px;
  background-position: center;
  background-size: cover; }

.tau-news-info {
  margin-top: 20px; }
  .tau-news-info__date {
    color: #666;
    margin-bottom: 12px;
    text-transform: lowercase; }
  .tau-news-info__desc {
    color: #2F438B;
    line-height: 1.6em;
    word-break: break-word; }

.tau-news__buttons {
  text-align: center; }
  .tau-news__buttons .tau-btn {
    margin: 0 18px;
    box-sizing: border-box; }

.tau-new-single__header {
  max-width: 920px;
  margin: 40px auto 0; }

.tau-new-single__content {
  max-width: 920px;
  margin: 30px auto 50px;
  line-height: 1.9em; }
  .tau-new-single__content-title {
    font-weight: bold;
    margin-bottom: 30px; }
  .tau-new-single__content p {
    margin-bottom: 40px;
    line-height: 1.9em; }
  .tau-new-single__content ul li {
    padding-left: 15px;
    position: relative;
    line-height: 1.9em; }
    .tau-new-single__content ul li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #333; }
  .tau-new-single__content img {
    margin: 30px 0 0;
    position: relative;
    border-radius: 5px;
    overflow: hidden; }

.tau-new-repost {
  margin-bottom: 50px; }

@media (max-width: 480px) {
  .tau-news__content .tau-pagination {
    margin-bottom: 30px; }
  .tau-news__item {
    flex: 0 1 100%;
    max-width: 100%;
    margin: 10px 0 20px; }
    .tau-news__item figure {
      padding: 0 20px; }
  .tau-news__grid {
    margin: 0 0 40px; }
  .tau-news-info {
    margin-top: 15px; }
    .tau-news-info__date {
      margin-bottom: 7px; }
  .tau-news__buttons {
    text-align: left;
    padding: 0 45px; }
    .tau-news__buttons .tau-btn {
      margin: 0 0 35px;
      width: 100%; }
  .tau-news-inner .tau-news__grid {
    margin: 0 0 10px; }
  .tau-new-single__header {
    padding: 0 20px;
    margin: 0 auto; }
  .tau-new-single__content {
    padding: 0 20px;
    margin: 0 0 40px; }
    .tau-new-single__content-title, .tau-new-single__content p {
      margin-bottom: 20px; }
    .tau-new-single__content img {
      margin: 40px 0 0; }
    .tau-new-single__content ul li {
      padding-left: 12px; }
  .tau-new-repost {
    margin-bottom: 40px; } }

.tau-management__content {
  max-width: 920px;
  margin: 0 auto 30px; }

.tau-management__list-title {
  flex-basis: 100% !important;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #2F438B;
  margin-bottom: 18px; }
  .tau-management__list-title:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 35px;
    width: 5px;
    height: 55px;
    background: #EB7E7F; }

.tau-management__item {
  padding: 50px 20px 50px 35px;
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #c4c4c4; }
  .tau-management__item div {
    flex: 0 0 50%; }
  .tau-management__item:first-child {
    border: none; }

.tau-management__name {
  font-size: 1.125em;
  font-weight: bold; }

.tau-management__desc {
  line-height: 1.9em; }

@media (max-width: 480px) {
  .tau-management__item {
    padding: 0 20px 25px;
    border: none; }
    .tau-management__item div {
      flex: 0 0 100%; }
  .tau-management__list-title {
    font-size: 1.15em;
    margin-bottom: 5px;
    line-height: 35px; }
    .tau-management__list-title:before {
      height: 35px;
      top: 0;
      background: #7186CF; }
  .tau-management__name {
    font-size: 1.15em; } }

.tau-expert__content {
  max-width: 920px;
  margin: 0 auto 30px; }

.tau-expert__list-title {
  flex-basis: 100% !important;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #2F438B;
  margin-bottom: 18px; }
  .tau-expert__list-title:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 35px;
    width: 5px;
    height: 55px;
    background: #7186CF; }

.tau-expert__item {
  padding: 50px 20px 50px 35px;
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #c4c4c4; }
  .tau-expert__item div {
    flex: 0 0 50%; }
  .tau-expert__item:first-child {
    border: none; }

.tau-expert__name {
  font-size: 1.125em;
  font-weight: bold; }

.tau-expert__desc {
  line-height: 1.9em; }

@media (max-width: 480px) {
  .tau-expert__item {
    padding: 0 20px 25px;
    border: none; }
    .tau-expert__item div {
      flex: 0 0 100%; }
  .tau-expert__list-title {
    font-size: 1.15em;
    margin-bottom: 5px;
    line-height: 35px; }
    .tau-expert__list-title:before {
      height: 35px;
      top: 0;
      background: #7186CF; }
  .tau-expert__name {
    font-size: 1.15em; } }

.tau-team__content {
  max-width: 920px;
  margin: 0 auto 60px; }

.tau-team__item {
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #c4c4c4; }
  .tau-team__item:last-child {
    border-bottom: 1px solid #c4c4c4; }
  .tau-team__item > div {
    flex: 0 0 50%; }

.tau-team__list {
  padding-top: 35px;
  margin-bottom: 60px; }

.tau-team__title {
  position: relative;
  padding-left: 35px;
  margin: 30px 0;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 55px;
  user-select: none;
  color: #2F438B;
  box-shadow: inset 5px 0 0 #EB7E7F;
  cursor: pointer;
  transition: color .2s ease; }
  .tau-team__title:hover {
    color: #7186CF; }
  .tau-team__title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 3px solid;
    border-left: none;
    border-top: none;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: color .2s ease;
    transform: rotate(45deg); }
  .tau-team__title.tau-tab-title_open {
    color: #333; }
    .tau-team__title.tau-tab-title_open:after {
      transform: rotate(-135deg); }

.tau-team-person__list {
  flex-wrap: wrap; }

.tau-team-person__item {
  flex: 1 0 50%;
  padding: 40px 0 10px; }
  .tau-team-person__item figure {
    display: flex; }
  .tau-team-person__item figcaption {
    padding-left: 15px; }
  .tau-team-person__item span, .tau-team-person__item a {
    display: block; }
  .tau-team-person__item:last-child {
    padding: 40px 0 80px; }

.tau-team-person__image {
  height: 75px;
  width: 75px;
  min-width: 75px;
  border-radius: 50%;
  overflow: hidden; }
  .tau-team-person__image img {
    height: 100%;
    width: auto !important; }

.tau-team-person__name {
  margin-bottom: 8px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #2F438B !important;
  cursor: pointer;
  transition: all .2s ease; }
  .tau-team-person__name:hover {
    color: #7186CF; }
  .tau-team-person__name_mobile {
    display: none !important; }

.tau-team-person__post {
  font-family: Panton;
  font-weight: bold;
  color: #bdbdbd; }

.tau-team-person-about {
  margin: 0 30px;
  width: 800px; }
  .tau-team-person-about > div {
    flex: 0 0 50%; }
  .tau-team-person-about__view {
    text-align: right;
    margin-bottom: 30px; }
  .tau-team-person-about__info {
    padding: 30px 0 0 0;
    margin-bottom: 30px; }
  .tau-team-person-about__name {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.5em;
    color: #666;
    padding-left: 15px;
    margin-bottom: 65px;
    box-shadow: inset 5px 0 #7186CF; }
  .tau-team-person-about__list {
    padding-left: 15px; }
    .tau-team-person-about__list li {
      margin-top: 30px; }
      .tau-team-person-about__list li span, .tau-team-person-about__list li p {
        font-size: 1.125em;
        color: #666;
        display: block; }
        .tau-team-person-about__list li span:first-child, .tau-team-person-about__list li p:first-child {
          font-weight: bold; }
  .tau-team-person-about__image {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block; }
  .tau-team-person-about__text {
    font-style: italic;
    line-height: 2em;
    color: #929292;
    text-align: left;
    padding: 35px 0 0 40px;
    position: relative; }
    .tau-team-person-about__text:before, .tau-team-person-about__text:after {
      font-weight: bold;
      font-style: normal;
      font-size: 3em;
      color: #B8C2E7; }
    .tau-team-person-about__text:before {
      content: open-quote;
      position: absolute;
      left: -5px;
      top: 32px; }
    .tau-team-person-about__text:after {
      content: close-quote;
      display: inline-block;
      vertical-align: text-top;
      margin-left: 7px; }

@media (max-width: 480px) {
  .tau-team__content {
    margin: 0 auto 35px; }
  .tau-team__list {
    padding-top: 10px;
    margin-bottom: 35px; }
  .tau-team__item {
    margin: 0 20px; }
  .tau-team__title {
    line-height: 1.5em;
    font-size: 1.15em;
    padding: 0 20px;
    margin: 25px 0 25px -20px;
    height: 40px;
    display: flex;
    box-shadow: inset 5px 0 0 #7186CF; }
    .tau-team__title:after {
      height: 5px;
      width: 5px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      right: 3px;
      top: 50%;
      margin-top: -5px; }
    .tau-team__title span {
      margin: auto 0; }
  .tau-team-person__item {
    flex: 0 0 100%;
    padding: 20px 0; }
    .tau-team-person__item figcaption {
      padding-left: 20px;
      width: calc(100% - 100px);
      margin: auto 0; }
    .tau-team-person__item a {
      margin-bottom: 6px; }
    .tau-team-person__item:last-child {
      padding: 20px 0 50px; }
  .tau-team-person__name {
    font-size: 1em;
    line-height: 1.2em;
    margin-bottom: 4px; }
    .tau-team-person__name_desktop {
      display: none !important; }
    .tau-team-person__name_mobile {
      display: block !important; }
  .tau-team-person__post {
    color: #333;
    line-height: 1.2em;
    font-family: Istok Web;
    font-weight: normal; }
  .tau-team-person-about {
    margin: 0;
    width: auto;
    flex-wrap: wrap; }
    .tau-team-person-about > div {
      flex: 0 0 100%; }
    .tau-team-person-about__info {
      margin-bottom: 40px;
      padding: 0; }
    .tau-team-person-about__name {
      font-size: 1.3em;
      color: #333;
      margin-bottom: 20px; }
    .tau-team-person-about__list {
      padding: 0; }
      .tau-team-person-about__list li {
        margin-top: 12px; }
        .tau-team-person-about__list li span, .tau-team-person-about__list li p {
          color: #333; }
    .tau-team-person-about__image {
      display: none; }
    .tau-team-person-about__text {
      color: #4F4F4F;
      font-size: .925em;
      padding: 0; }
      .tau-team-person-about__text:before {
        top: -27px; }
      .tau-team-person-about__text:after {
        margin: 0;
        position: absolute;
        right: 0;
        bottom: -8px; } }

.tau-official__content {
  max-width: 920px;
  margin: 0 auto; }

.tau-official__list {
  padding: 10px 0 25px; }

.tau-official-tab__list .tau-docs__list {
  padding: 10px 0 0 10px; }

.tau-official__item {
  margin-bottom: 40px; }
  .tau-official__item:nth-child(odd) .tau-official__title {
    box-shadow: inset 5px 0 #EB7E7F; }
  .tau-official__item:nth-child(even) .tau-official__title {
    box-shadow: inset 5px 0 #7186CF; }

.tau-official__title {
  font-weight: bold;
  font-size: 1.25em;
  line-height: 55px;
  padding-left: 35px; }
  .tau-official__title.tau-tab-title {
    cursor: pointer; }
    .tau-official__title.tau-tab-title:after {
      content: '';
      height: 7px;
      width: 7px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      display: inline-block;
      position: relative;
      margin-left: 12px;
      top: -3px;
      transform: rotate(45deg); }
  .tau-official__title.tau-tab-title_open:after {
    transform: rotate(-135deg);
    top: -1px; }

.tau-official-data__item {
  padding: 35px;
  border-bottom: 1px solid #c4c4c4;
  line-height: 3em;
  flex-wrap: wrap; }
  .tau-official-data__item > div {
    flex: 0 0 50%; }

.tau-official-data__title {
  font-weight: bold;
  font-size: 1.125em; }

.tau-official-data__desc {
  padding-top: 3px; }
  .tau-official-data__desc_middle {
    margin: auto; }
  .tau-official-data__desc a {
    color: #2F438B; }

@media (max-width: 480px) {
  .tau-official__title {
    line-height: normal;
    height: 35px;
    padding: 0 20px;
    color: #2F438B;
    vertical-align: middle;
    display: table-cell;
    font-size: 1.15em;
    font-family: Panton; }
    .tau-official__title.tau-tab-title_open {
      color: #333; }
      .tau-official__title.tau-tab-title_open:after {
        top: 16px !important; }
    .tau-official__title.tau-tab-title:after {
      height: 4px;
      width: 4px;
      top: 14px;
      position: absolute;
      right: 22px; }
  .tau-official__item {
    margin-bottom: 25px;
    padding-bottom: 25px;
    position: relative; }
    .tau-official__item:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      border-bottom: 1px solid #c4c4c4; }
    .tau-official__item:nth-child(odd) .tau-official__title {
      box-shadow: inset 5px 0 #7186CF; }
    .tau-official__item:first-child {
      padding-bottom: 40px; }
      .tau-official__item:first-child .tau-official__title {
        box-shadow: none;
        line-height: normal;
        color: #333;
        font-family: Istok Web;
        font-weight: normal; }
  .tau-official-tab__list .tau-docs__list {
    padding: 15px 0 0; }
  .tau-official-data__item {
    padding: 20px 20px 0;
    line-height: 1.9em;
    border: none; }
    .tau-official-data__item > div {
      flex: 0 0 100%; }
  .tau-official-data__title {
    font-size: 1em; } }

.tau-gallery__content {
  margin: 0 -20px 90px; }

.tau-gallery__grid {
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-gallery__item {
  flex: 0 1 33.3%;
  margin-bottom: 90px;
  padding: 0 20px;
  box-sizing: border-box; }
  .tau-gallery__item a {
    display: block;
    height: 350px;
    position: relative;
    overflow: hidden; }
    .tau-gallery__item a:hover .tau-album__desc {
      background: #8297e0; }
  .tau-gallery__item-photo {
    flex: 0 1 33.3%;
    margin: 0 0 40px !important;
    padding: 0 20px !important;
    box-sizing: border-box; }
    .tau-gallery__item-photo img {
      position: relative !important; }

.tau-gallery__more {
  margin-top: 50px;
  text-align: center; }
  .tau-gallery__more a {
    color: #2F438B;
    font-weight: bold;
    font-size: 1.125em; }

.tau-album__desc {
  position: absolute;
  height: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #7186CF;
  color: #fff;
  box-sizing: border-box;
  padding: 10px 15px;
  box-shadow: 0 0 50px #2f438b;
  transition: background .2s ease; }

.tau-album__photo-count {
  position: absolute;
  font-family: Panton;
  font-size: 1.125em;
  right: 15px;
  top: 10px; }

.tau-album__title {
  font-family: Panton;
  font-weight: bold;
  font-size: 1.125em;
  margin-bottom: 4px;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.tau-album__date {
  font-size: .875em;
  text-transform: lowercase; }

@media (max-width: 480px) {
  .tau-gallery__content {
    margin: 0 0 30px; }
  .tau-gallery__grid {
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .tau-gallery__item {
    flex: 0 1 100%;
    margin-bottom: 30px; }
    .tau-gallery__item a {
      height: auto; }
    .tau-gallery__item-photo {
      flex: 0 1 100%;
      margin-bottom: 30px; }
  .tau-gallery__more {
    margin-top: 0; }
  .tau-album__title {
    font-size: 1em;
    margin-bottom: 2px; }
  .tau-album__date {
    font-size: .875em; }
  .tau-album__desc {
    padding: 6px 12px;
    height: 50px; }
  .tau-album__photo-count {
    font-size: .875em;
    right: 12px;
    top: 6px; } }

.tau-vacancy__content {
  max-width: 920px;
  margin: 0 auto 60px; }
  .tau-vacancy__content-title, .tau-vacancy__content-list {
    padding-left: 35px; }
  .tau-vacancy__content-title {
    font-size: 1.375em;
    font-weight: bold;
    margin-bottom: 12px; }
  .tau-vacancy__content-list {
    padding-bottom: 30px; }
    .tau-vacancy__content-list li {
      line-height: 2.4em; }
      .tau-vacancy__content-list li:before {
        content: '';
        display: inline-block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 10px;
        background: #333; }
  .tau-vacancy__content-text {
    padding: 0 0 30px 35px; }

.tau-vacancy__item {
  position: relative;
  border-top: 1px solid #c4c4c4; }
  .tau-vacancy__item:last-child {
    border-bottom: 1px solid #c4c4c4; }
  .tau-vacancy__item:nth-child(odd) .tau-vacancy__title {
    box-shadow: inset 5px 0 0 #7186CF; }
  .tau-vacancy__item:nth-child(even) .tau-vacancy__title {
    box-shadow: inset 5px 0 0 #EB7E7F; }

.tau-vacancy__list {
  padding-top: 35px;
  margin-bottom: 60px; }

.tau-vacancy__title {
  position: relative;
  padding-left: 35px;
  margin: 30px 0;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 55px;
  user-select: none;
  color: #2F438B;
  cursor: pointer;
  transition: color .2s ease; }
  .tau-vacancy__title:hover {
    color: #7186CF; }
  .tau-vacancy__title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 3px solid;
    border-left: none;
    border-top: none;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: color .2s ease;
    transform: rotate(45deg); }
  .tau-vacancy__title.tau-tab-title_open {
    color: #333; }
    .tau-vacancy__title.tau-tab-title_open:after {
      transform: rotate(-135deg); }

.tau-vacancy__desc {
  margin-bottom: 60px; }

@media (max-width: 480px) {
  .tau-vacancy__list {
    padding-top: 25px;
    margin-bottom: 50px; }
  .tau-vacancy__title {
    padding-left: 20px;
    margin: 20px 0;
    line-height: 35px;
    font-size: 1.15em; }
    .tau-vacancy__title:after {
      height: 4px;
      width: 4px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      top: 14px; }
    .tau-vacancy__title.tau-tab-title_open:after {
      top: 16px !important; }
  .tau-vacancy__content-title {
    font-size: 1em;
    margin-bottom: 5px;
    padding: 0 20px; }
  .tau-vacancy__content-list {
    padding: 0 20px 30px; }
    .tau-vacancy__content-list li {
      line-height: 2.2em; }
  .tau-vacancy__content-text {
    padding: 0 20px 30px; }
  .tau-vacancy__desc {
    margin-bottom: 45px; }
  .tau-vacancy__buttons {
    text-align: center; }
    .tau-vacancy__buttons a {
      width: auto; }
  .tau-vacancy__item {
    border: none; }
    .tau-vacancy__item:last-child {
      border: none; }
      .tau-vacancy__item:last-child:after {
        content: '';
        display: block;
        border-top: 1px solid #c4c4c4;
        margin: 0 20px; }
    .tau-vacancy__item:before {
      content: '';
      display: block;
      border-top: 1px solid #c4c4c4;
      margin: 0 20px; } }

.tau-services__content {
  max-width: 920px;
  margin: 0 auto 45px; }

.tau-services__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-services__grid a {
    display: block;
    flex: 0 1 33.3%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-services__grid a:hover > div:after {
      background: #EB7E7F; }
    .tau-services__grid a:hover use {
      fill: #EB7E7F; }

.tau-services__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-services__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-services__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #7186CF;
    transition: all .3s ease; }

.tau-services__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-services__icon use {
    fill: #C7C2CD;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-services__grid {
    margin: 0; }
    .tau-services__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-services__item {
    padding: 15px 0 25px; }
    .tau-services__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-services__icon {
    margin: 5px 0 10px; } }

.tau-services__grid {
  padding: 60px 0;
  justify-content: flex-start; }

@media (max-width: 480px) {
  .tau-services__content {
    padding: 0 20px;
    margin-bottom: 40px; }
  .tau-services__grid {
    padding: 45px 0; } }

.tau-methodic__content {
  max-width: 920px;
  margin: 0 auto 50px; }

.tau-methodic__list {
  margin-bottom: 50px; }
  .tau-methodic__list:nth-child(odd) .tau-methodic__title {
    box-shadow: inset 5px 0 0 #7186CF; }
  .tau-methodic__list:nth-child(even) .tau-methodic__title {
    box-shadow: inset 5px 0 0 #EB7E7F; }

.tau-methodic__title {
  color: #333;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  line-height: 55px;
  padding-left: 30px; }

.tau-methodic__item-title {
  font-family: Istok Web;
  font-weight: bold;
  font-size: 1.25em;
  padding: 15px 30px;
  color: #2F438B;
  cursor: pointer; }
  .tau-methodic__item-title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 2px solid;
    border-left: none;
    border-top: none;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: -3px;
    transform: rotate(45deg); }
  .tau-methodic__item-title.tau-tab-title_open {
    color: #333; }
    .tau-methodic__item-title.tau-tab-title_open:after {
      transform: rotate(-135deg);
      top: -1px; }

.tau-methodic__item .tau-docs__item {
  padding-left: 30px; }

@media (max-width: 480px) {
  .tau-methodic__content {
    margin: 0 0 30px; }
  .tau-methodic__list {
    margin-bottom: 20px; }
  .tau-methodic__title {
    font-size: 1.15em;
    line-height: 35px;
    padding-left: 20px;
    margin-bottom: 5px;
    box-shadow: inset 5px 0 0 #EB7E7F !important; }
  .tau-methodic__item-title {
    font-size: 1em;
    padding: 10px 20px;
    position: relative; }
    .tau-methodic__item-title:after {
      height: 4px;
      width: 4px;
      position: absolute;
      top: 18px;
      right: 22px; }
    .tau-methodic__item-title.tau-tab-title_open:after {
      top: 20px; }
  .tau-methodic__item .tau-docs__item {
    padding-left: 20px; } }

.tau-mice__content {
  max-width: 920px;
  margin: 0 auto 90px; }

.tau-mice__title {
  color: #333;
  line-height: 55px;
  padding-left: 30px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.625em;
  box-shadow: inset 5px 0 0 #EB7E7F; }

.tau-mice__buttons {
  text-align: center;
  margin-bottom: 50px; }

.tau-mice-inner .tau-docs__list li {
  padding-left: 0; }

@media (max-width: 480px) {
  .tau-mice__content {
    margin: 0 0 40px; }
  .tau-mice__title {
    padding-left: 20px;
    font-size: 1.15em;
    line-height: 35px; }
  .tau-mice__buttons {
    margin-bottom: 40px; }
    .tau-mice__buttons a {
      width: auto; }
  .tau-mice-inner .tau-docs__list li {
    padding-left: 20px; } }

.tau-events__content .tau-pagination {
  margin-bottom: 45px; }

.tau-events__grid {
  margin: 0 -20px 40px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-events__item {
  flex: 0 1 25%;
  margin: 25px 0; }
  .tau-events__item figure {
    padding: 0 20px; }

.tau-events__photo {
  height: 215px;
  border-radius: 5px;
  background-position: center;
  background-size: cover; }

.tau-events__desc {
  max-width: 920px;
  margin: 0 auto 20px;
  line-height: 1.9em;
  text-align: justify; }

.tau-events-info {
  margin-top: 17px; }
  .tau-events-info__date {
    color: #fff;
    margin-left: 20px;
    padding: 3px 10px 0;
    background: #7186CF;
    display: inline-block;
    font: bold 1.375em/1.8em Istok Web; }
  .tau-events-info__title {
    color: #2F438B;
    word-break: break-word;
    font: bold 1.25em/1.4em Panton; }
  .tau-events-info__place {
    position: relative; }
    .tau-events-info__place .tau-label {
      position: absolute;
      left: 0;
      top: 1px; }
    .tau-events-info__place span {
      padding: 0 18px;
      line-height: 1.4em;
      margin-bottom: 12px;
      display: block;
      height: 40px; }

.tau-events-more {
  display: none;
  text-align: center;
  margin: 35px 0 45px; }
  .tau-events-more a {
    display: inline-block;
    margin: 7px 0 6px 0;
    color: #2F438B;
    font-weight: 700;
    font-size: 1.125em; }

.tau-event-single__header {
  max-width: 920px;
  margin: 40px auto; }

.tau-event-single__content {
  max-width: 920px;
  margin: 0 auto 90px; }

.tau-event-single__grid {
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-event-single__item {
  flex: 0 1 33.3%;
  margin: 0 0 40px; }
  .tau-event-single__item_w100 {
    flex: 0 1 100%;
    margin: 0 0 40px; }
  .tau-event-single__item .tau-date {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px; }
  .tau-event-single__item .tau-age {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0; }
  .tau-event-single__item .tau-participant {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 2px 0 0; }
  .tau-event-single__item .tau-label {
    display: inline-block;
    vertical-align: middle;
    margin: -4px 1px 0 0; }

.tau-event-single__image {
  margin: 0 0 40px;
  max-height: 500px;
  border-radius: 5px;
  overflow: hidden; }

.tau-event-single__title {
  font-weight: bold;
  margin-bottom: 8px; }

.tau-event-single__date span {
  color: #2F438B;
  display: inline-block;
  margin-left: 2px; }

.tau-event-single__direction {
  border-radius: 45px;
  border: 1px solid #929292;
  color: #929292;
  display: inline-block;
  padding: 2px 40px 3px;
  margin-top: 15px; }

.tau-event-single__desc, .tau-event-single__rewards, .tau-event-single__org {
  margin-bottom: 45px; }

.tau-event-single__desc p a, .tau-event-single__desc p a:hover {
  color: #7186CF; }

.tau-event-single__docs {
  margin-bottom: 40px; }

.tau-event-single__buttons {
  text-align: center; }

.tau-event-single .tau-docs__item {
  padding: 0; }

.tau-event-single .tau-docs__list {
  margin-top: 22px; }

.tau-event-single__text {
  padding-right: 20px; }
  .tau-event-single__text > div {
    display: inline-block;
    vertical-align: top; }
  .tau-event-single__text .tau-email-gray, .tau-event-single__text .tau-handset {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px; }
  .tau-event-single__text-info {
    width: 340px;
    padding-right: 20px; }
    .tau-event-single__text-info div {
      margin-top: 5px; }
  .tau-event-single__text-data div {
    margin-top: 5px; }

@media (max-width: 480px) {
  .tau-events__grid {
    margin: 0 0 20px; }
  .tau-events__item {
    flex: 0 1 100%;
    margin: 25px 0 5px; }
  .tau-events__content .tau-pagination {
    display: none; }
  .tau-events__desc {
    padding: 0 20px;
    margin: 0; }
  .tau-events-info {
    margin-top: 12px; }
    .tau-events-info__date {
      padding: 1px 10px 0;
      font: bold 1.1em/2.3em Istok Web; }
    .tau-events-info__place {
      color: #929292; }
      .tau-events-info__place span {
        height: auto;
        margin-bottom: 8px; }
    .tau-events-info__title {
      font: bold 1em/1.6em Istok Web; }
  .tau-events-more {
    display: block; }
  .tau-event-single__header {
    padding: 0 20px;
    margin: 0 auto; }
  .tau-event-single__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 35px; }
    .tau-event-single__content > div {
      order: 1; }
  .tau-event-single__image {
    order: 2 !important;
    margin: 30px 20px 35px; }
  .tau-event-single__item {
    flex: 0 1 100%;
    margin: 0 0 25px; }
    .tau-event-single__item_w100 {
      margin: 0 0 25px; }
  .tau-event-single__desc, .tau-event-single__rewards, .tau-event-single__org {
    margin-bottom: 25px; }
  .tau-event-single__docs {
    margin: 0; }
  .tau-event-single__buttons {
    width: 100%;
    padding: 0 20px;
    order: 2 !important; }
  .tau-event-single__grid, .tau-event-single__desc, .tau-event-single__rewards, .tau-event-single__org, .tau-event-single__docs {
    padding: 0 20px; }
  .tau-event-single__direction {
    margin-top: 5px; }
  .tau-event-single .tau-docs__list {
    margin-top: 10px; }
  .tau-event-single__text-info {
    width: auto;
    padding: 0; } }

.tau-contacts__content {
  max-width: 920px;
  margin: 0 auto; }
  .tau-contacts__content .tau-inline-logo {
    display: none; }

.tau-contacts__info {
  margin-bottom: 55px;
  flex-wrap: wrap; }
  .tau-contacts__info-item {
    margin-bottom: 35px; }
    .tau-contacts__info-item:nth-child(odd) .tau-contacts__title {
      box-shadow: inset 5px 0 0 #7186CF; }
    .tau-contacts__info-item:nth-child(even) .tau-contacts__title {
      box-shadow: inset 5px 0 0 #EB7E7F; }

.tau-contacts__item {
  flex: 0 0 50%; }

.tau-contacts__title {
  font-family: Panton;
  font-weight: bold;
  font-size: 1.625em;
  color: #2F438B;
  line-height: 55px;
  padding-left: 30px; }

.tau-contacts__value {
  font-size: 1.125em;
  padding-left: 30px; }

@media (max-width: 480px) {
  .tau-contacts__content {
    margin-bottom: 35px; }
    .tau-contacts__content .tau-inline-logo {
      display: block; }
  .tau-contacts__info {
    margin-bottom: 30px; }
    .tau-contacts__info-item {
      margin-bottom: 25px; }
  .tau-contacts__title {
    font-size: 1.15em;
    padding: 0 20px;
    line-height: 35px;
    margin-bottom: 5px; }
  .tau-contacts__value {
    font-size: 1em;
    padding-left: 20px; }
  .tau-contacts__item {
    flex: 0 0 100%; }
    .tau-contacts__item:last-child {
      padding: 0 20px; } }

.tau-business__content {
  max-width: 920px;
  margin: 0 auto 80px; }
  .tau-business__content p {
    margin-bottom: 40px;
    padding: 0 30px; }

.tau-business__title {
  padding-left: 30px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  color: #333;
  line-height: 55px;
  margin: 60px 0 20px;
  box-shadow: inset 5px 0 0 #EB7E7F; }
  .tau-business__title:first-child {
    margin-top: 0; }

.tau-business__buttons {
  text-align: center;
  margin-bottom: 90px; }

@media (max-width: 480px) {
  .tau-business__content {
    margin: 0 0 35px; }
    .tau-business__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
  .tau-business__title {
    padding-left: 20px;
    font-size: 1.15em;
    line-height: 35px;
    margin: 40px 0 20px; }
    .tau-business__title:first-child {
      margin-top: 0; }
  .tau-business__buttons {
    margin-bottom: 45px; }
    .tau-business__buttons a {
      width: auto; } }

.tau-teachers__content {
  max-width: 920px;
  margin: 0 auto 45px; }
  .tau-teachers__content p {
    margin-bottom: 40px; }
  .tau-teachers__content li {
    padding-left: 15px;
    position: relative; }
    .tau-teachers__content li:not(.tau-docs__item):before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #333; }

.tau-teachers__grid {
  max-width: 920px;
  margin: 25px auto 75px;
  flex-wrap: wrap; }

.tau-teachers__title {
  color: #333;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  padding: 0 25px;
  line-height: 55px;
  box-shadow: inset 5px 0 0 #EB7E7F; }

.tau-teachers__item {
  flex: 0 0 50%; }
  .tau-teachers__item li {
    padding: 12px 0 12px 25px; }
    .tau-teachers__item li a {
      color: #2F438B;
      font-family: Panton;
      font-weight: bold;
      font-size: 1.125em; }

.tau-teachers__buttons {
  text-align: center;
  margin-bottom: 70px; }

.tau-teachers .tau-partners__grid {
  padding: 60px 0; }
  .tau-teachers .tau-partners__grid a {
    flex: 0 1 33.3%; }

@media (max-width: 480px) {
  .tau-teachers__grid {
    margin: 0 0 30px; }
  .tau-teachers__title {
    font-size: 1.15em;
    line-height: 35px;
    padding: 0 20px;
    margin-bottom: 4px; }
  .tau-teachers__item {
    flex: 0 0 100%;
    margin: 20px 0; }
    .tau-teachers__item li {
      padding: 8px 20px; }
      .tau-teachers__item li a {
        font-family: Istok Web;
        font-size: 1em;
        font-weight: normal; }
  .tau-teachers__content {
    margin: 0 0 30px; }
    .tau-teachers__content p {
      padding: 0 20px;
      margin-bottom: 20px; }
    .tau-teachers__content ul {
      padding: 0 20px; }
      .tau-teachers__content ul p {
        padding: 0; }
  .tau-teachers__buttons {
    margin: 35px 0 45px; }
    .tau-teachers__buttons a {
      width: auto; }
  .tau-teachers .tau-partners__grid {
    padding: 45px 0; }
    .tau-teachers .tau-partners__grid a {
      flex: 0 1 100%; } }

.tau-diagnostics__content {
  max-width: 920px;
  margin: 0 auto 90px; }
  .tau-diagnostics__content img {
    max-height: 440px;
    width: 100%;
    margin-bottom: 25px; }
  .tau-diagnostics__content p {
    margin-bottom: 40px; }
  .tau-diagnostics__content strong {
    margin-bottom: 20px;
    display: inline-block; }
  .tau-diagnostics__content ul {
    margin-bottom: 40px; }
    .tau-diagnostics__content ul li {
      padding-left: 15px;
      position: relative; }
      .tau-diagnostics__content ul li:before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #333; }
  .tau-diagnostics__content-title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.5em;
    color: #333;
    margin: 10px 0 20px; }

.tau-diagnostics__table_min {
  margin-bottom: 60px !important; }
  .tau-diagnostics__table_min-header {
    font-family: Panton;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    background: #7186CF;
    border-radius: 5px 5px 0 0;
    padding: 11px 0 12px 120px; }
  .tau-diagnostics__table_min-item {
    padding: 20px 25px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #E3E3E4; }
    .tau-diagnostics__table_min-item div {
      flex: 0 0 50%;
      font-size: .875em; }
      .tau-diagnostics__table_min-item div:first-child {
        font-weight: bold;
        font-size: 1em; }
    .tau-diagnostics__table_min-item:before {
      display: none; }

.tau-diagnostics__title {
  padding-left: 30px;
  line-height: 55px;
  color: #333;
  box-shadow: inset 5px 0 0 #EB7E7F;
  margin-bottom: 45px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em; }

.tau-diagnostics__buttons {
  text-align: center; }

@media (max-width: 480px) {
  .tau-diagnostics__content {
    margin: 0 0 40px; }
    .tau-diagnostics__content img {
      border-radius: 5px;
      overflow: hidden;
      margin: 0 20px 25px;
      width: calc(100% - 40px); }
    .tau-diagnostics__content p, .tau-diagnostics__content strong {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-diagnostics__content ul {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-diagnostics__content-title {
      padding: 0 20px;
      font-size: 1.15em; }
  .tau-diagnostics__table_min {
    margin-bottom: 0 !important;
    padding: 0 !important; }
    .tau-diagnostics__table_min-header {
      border-radius: 0;
      padding: 11px 20px 12px;
      text-align: center; }
    .tau-diagnostics__table_min-item {
      padding: 20px; }
      .tau-diagnostics__table_min-item div {
        flex: 0 0 100%; }
        .tau-diagnostics__table_min-item div:first-child {
          margin-bottom: 10px; }
  .tau-diagnostics__title {
    padding: 0 20px;
    font-size: 1.15em;
    line-height: 30px;
    margin: 40px 0; }
  .tau-diagnostics__buttons a {
    width: auto; } }

.tau-structure__content {
  max-width: 920px;
  margin: 0 auto 50px; }
  .tau-structure__content p {
    margin-bottom: 5px; }
  .tau-structure__content-list {
    padding-left: 30px; }
    .tau-structure__content-list-item {
      margin: 7px 0 12px; }

.tau-structure__item {
  padding: 30px 0 15px;
  border-bottom: 1px solid #c4c4c4; }
  .tau-structure__item:first-child {
    border-top: 1px solid #c4c4c4; }

.tau-structure__title {
  position: relative;
  padding-left: 30px;
  margin: 0 0 15px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  line-height: 55px;
  color: #2F438B;
  box-shadow: inset 5px 0 0 #EB7E7F;
  cursor: pointer; }
  .tau-structure__title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 3px solid;
    border-left: none;
    border-top: none;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: color .2s ease;
    transform: rotate(45deg); }
  .tau-structure__title.tau-tab-title_open {
    color: #333; }
    .tau-structure__title.tau-tab-title_open:after {
      transform: rotate(-135deg); }

.tau-structure-tab__title {
  font-family: Istok Web;
  font-weight: normal;
  font-size: 1em;
  color: #2F438B;
  cursor: pointer; }
  .tau-structure-tab__title:after {
    content: '';
    height: 4px;
    width: 4px;
    border: 2px solid;
    border-left: none;
    border-top: none;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: -3px;
    transform: rotate(45deg); }
  .tau-structure-tab__title.tau-tab-title_open {
    color: #333; }
    .tau-structure-tab__title.tau-tab-title_open:after {
      transform: rotate(-135deg);
      top: -1px; }

.tau-structure-tab__content {
  padding-left: 20px;
  margin-top: 5px; }

.tau-structure-tab__desc {
  font-size: .875em;
  margin-bottom: 10px; }
  .tau-structure-tab__desc ul li {
    padding-left: 15px;
    position: relative; }
    .tau-structure-tab__desc ul li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #333; }

@media (max-width: 480px) {
  .tau-structure__content {
    margin: 0 0 30px; }
    .tau-structure__content-list {
      padding: 0; }
  .tau-structure__item {
    padding: 25px 0 15px;
    margin: 0 20px; }
  .tau-structure__title {
    font-size: 1.15em;
    line-height: 1.5em;
    padding: 0 20px;
    margin: 0 0 10px -20px;
    box-shadow: inset 5px 0 0 #7186CF; }
    .tau-structure__title:after {
      height: 5px;
      width: 5px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      right: 3px;
      top: 50%;
      margin-top: -5px; }
  .tau-structure-tab__desc {
    font-size: 1em; }
  .tau-structure-tab__title {
    position: relative;
    padding-right: 15px; }
    .tau-structure-tab__title:after {
      border: 1px solid;
      border-left: none;
      border-top: none;
      position: absolute;
      right: 4px;
      top: 50%;
      margin-top: -5px; }
    .tau-structure-tab__title.tau-tab-title_open {
      color: #333; }
      .tau-structure-tab__title.tau-tab-title_open:after {
        transform: rotate(-135deg);
        top: 50%;
        margin-top: -3px; } }

.tau-students-registry img {
  width: 100%;
  margin-bottom: 60px; }

.tau-students-registry__content {
  max-width: 920px;
  margin: 0 auto 90px; }
  .tau-students-registry__content p {
    margin-bottom: 40px; }
  .tau-students-registry__content ul:not(.tau-students-registry__list-count) {
    margin-bottom: 40px; }
    .tau-students-registry__content ul:not(.tau-students-registry__list-count) li {
      padding-left: 15px;
      position: relative; }
      .tau-students-registry__content ul:not(.tau-students-registry__list-count) li:before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #333; }
  .tau-students-registry__content-title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.5em;
    color: #333;
    margin: 10px 0 20px; }

.tau-students-registry__title {
  padding-left: 30px;
  line-height: 55px;
  color: #333;
  box-shadow: inset 5px 0 0 #EB7E7F;
  margin-bottom: 80px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em; }

.tau-students-registry__list-count {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  counter-reset: item;
  margin-bottom: 80px; }
  .tau-students-registry__list-count li {
    flex: 0 0 25%;
    height: 110px;
    position: relative; }
    .tau-students-registry__list-count li:before {
      content: counter(item);
      counter-increment: item;
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      line-height: 110px;
      font-family: Panton;
      font-size: 144px;
      font-weight: bold;
      color: rgba(103, 124, 197, 0.15); }
    .tau-students-registry__list-count li span {
      font-size: 1.125em;
      line-height: 1.9em;
      color: #333;
      padding-left: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 150px;
      text-align: center; }

.tau-students-registry__buttons {
  text-align: center; }

@media (max-width: 480px) {
  .tau-students-registry img {
    border-radius: 5px;
    overflow: hidden;
    margin: 0 20px 30px;
    width: calc(100% - 40px); }
  .tau-students-registry__content {
    margin: 0 0 40px; }
    .tau-students-registry__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-students-registry__content b {
      display: inline-block;
      margin-top: 20px; }
      .tau-students-registry__content b:first-child {
        margin: 0; }
    .tau-students-registry__content ul:not(.tau-students-registry__list-count) {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-students-registry__content-title {
      padding: 0 20px;
      font-size: 1.15em; }
  .tau-students-registry__list-count {
    margin-bottom: 0;
    padding: 0 20px;
    justify-content: space-between; }
    .tau-students-registry__list-count li {
      flex: 0 0 45%;
      margin: 30px 0;
      height: 75px; }
      .tau-students-registry__list-count li:before {
        line-height: 75px;
        font-size: 96px; }
      .tau-students-registry__list-count li span {
        font-size: .875em;
        line-height: 1.4em;
        text-align: left; }
  .tau-students-registry__title {
    padding: 0 20px;
    font-size: 1.15em;
    line-height: 30px;
    margin: 30px 0 0; }
  .tau-students-registry__buttons a {
    width: auto; } }

.tau-escort img {
  max-height: 800px;
  width: 100%;
  margin-bottom: 80px; }

.tau-escort__content {
  max-width: 920px;
  margin: 0 auto 40px; }
  .tau-escort__content p {
    margin-bottom: 40px; }

.tau-escort__title {
  padding-left: 30px;
  line-height: 55px;
  color: #333;
  margin-bottom: 45px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em; }
  .tau-escort__title:nth-child(odd) {
    box-shadow: inset 5px 0 0 #EB7E7F; }
  .tau-escort__title:nth-child(even) {
    box-shadow: inset 5px 0 0 #7186CF; }

.tau-escort__item {
  border-bottom: 1px solid #E3E3E4; }
  .tau-escort__item-title {
    font-size: 1.125em;
    font-weight: bold;
    color: #333;
    padding: 25px 45px 25px 30px;
    position: relative;
    cursor: pointer; }
    .tau-escort__item-title:after {
      content: '';
      height: 5px;
      width: 5px;
      border: 1px solid;
      border-left: none;
      border-top: none;
      position: absolute;
      right: 25px;
      top: 23px;
      transition: color .2s ease;
      transform: rotate(45deg); }
    .tau-escort__item-title.tau-tab-title_open {
      color: #333; }
      .tau-escort__item-title.tau-tab-title_open:after {
        transform: rotate(-135deg); }
    .tau-escort__item-title:after {
      right: 30px;
      top: 50%;
      margin-top: -5px; }
    .tau-escort__item-title.tau-tab-title_open:after {
      margin-top: -3px; }
  .tau-escort__item-content > div {
    margin: 15px 30px 25px; }
  .tau-escort__item-content p {
    margin-bottom: 0; }

.tau-gtab {
  margin-bottom: 50px; }
  .tau-gtab__content:nth-child(odd) .tau-escort__item-title.tau-tab-title_open {
    background: rgba(235, 126, 127, 0.1); }
  .tau-gtab__content:nth-child(even) .tau-escort__item-title.tau-tab-title_open {
    background: rgba(113, 134, 207, 0.1); }

@media (max-width: 480px) {
  .tau-escort img {
    border-radius: 5px;
    overflow: hidden;
    margin: 0 20px 25px;
    width: calc(100% - 40px); }
  .tau-escort__content {
    margin: 0 0 30px; }
    .tau-escort__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
  .tau-escort__title {
    padding: 0 20px;
    font-size: 1.15em;
    line-height: 30px;
    margin: 30px 0; }
  .tau-escort__item-title {
    font-size: 1em;
    padding: 15px 30px 15px 20px;
    line-height: normal; }
    .tau-escort__item-title:after {
      content: '';
      height: 3px;
      width: 3px;
      border: 1px solid;
      border-left: none;
      border-top: none;
      position: absolute;
      right: 25px;
      top: 23px;
      transition: color .2s ease;
      transform: rotate(45deg); }
    .tau-escort__item-title.tau-tab-title_open {
      color: #333; }
      .tau-escort__item-title.tau-tab-title_open:after {
        transform: rotate(-135deg); }
    .tau-escort__item-title:after {
      right: 20px;
      top: 50%;
      margin-top: -3px; }
    .tau-escort__item-title.tau-tab-title_open:after {
      margin-top: -1px; }
  .tau-escort__item-content > div {
    margin: 10px 20px; }
  .tau-escort__item-content p {
    margin: 0;
    padding: 0; }
  .tau-gtab {
    margin-bottom: 30px; } }

.tau-activities__content {
  max-width: 920px;
  margin: 0 auto 90px; }
  .tau-activities__content img {
    max-height: 440px;
    width: 100%;
    margin-bottom: 25px; }
  .tau-activities__content p {
    margin-bottom: 40px; }
  .tau-activities__content ul {
    margin-bottom: 40px; }
    .tau-activities__content ul li:not(.tau-docs__item) {
      padding-left: 15px;
      position: relative; }
      .tau-activities__content ul li:not(.tau-docs__item):before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #333; }

.tau-activities__title {
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em;
  color: #333;
  margin: 10px 0 20px; }

@media (max-width: 480px) {
  .tau-activities__content {
    margin: 0 0 40px; }
    .tau-activities__content img {
      border-radius: 5px;
      overflow: hidden;
      margin: 0 20px 25px;
      width: calc(100% - 40px); }
    .tau-activities__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-activities__content ul {
      margin-bottom: 20px;
      padding: 0 20px; }
      .tau-activities__content ul li p {
        padding: 0; }
  .tau-activities__title {
    padding: 0 20px;
    font-size: 1.15em; } }

.tau-brand__content {
  margin: 0 0 70px; }

.tau-brand__buttons {
  text-align: center;
  margin-bottom: 60px; }

.tau-carousel_brand {
  margin-bottom: 0; }
  .tau-carousel_brand .tau-carousel__nav-next, .tau-carousel_brand .tau-carousel__nav-prev {
    bottom: 0;
    background: #fff; }
  .tau-carousel_brand__item {
    box-sizing: border-box;
    height: 410px;
    display: flex;
    position: relative; }
    .tau-carousel_brand__item-title {
      font-size: 1em;
      font-family: Panton;
      font-weight: bold;
      color: #2F438B;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
    .tau-carousel_brand__item img {
      width: calc(100% - 40px);
      margin: auto 20px; }
  .tau-carousel_brand .slick-center .tau-carousel_brand__item img {
    margin: 0 20px 25px; }

@media (max-width: 480px) {
  .tau-brand__content {
    margin: 40px 0; }
  .tau-brand__buttons {
    margin-bottom: 40px; }
    .tau-brand__buttons a {
      width: auto; }
  .tau-carousel_brand .tau-carousel__nav-next, .tau-carousel_brand .tau-carousel__nav-prev {
    display: flex !important; }
  .tau-carousel_brand__item img {
    width: calc(100% - 20px);
    margin: 0 10px 25px !important; } }

.tau-slider__item {
  position: relative;
  display: block;
  overflow: hidden;
  max-height: 540px; }
  .tau-slider__item figcaption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    max-width: 420px;
    display: flex;
    background: #525F8B; }

.tau-slider-info {
  margin: auto 40px;
  color: #fff; }
  .tau-slider-info__date {
    font-size: 1.75em;
    text-transform: lowercase;
    display: none; }
    .tau-slider-info__date:after {
      content: '';
      display: block;
      margin: 25px 0;
      width: 115px;
      border-top: 1px solid #fff; }
  .tau-slider-info__title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.7em;
    line-height: 1.4em;
    word-break: break-word; }
  .tau-slider-info__desc {
    font-size: 1.5em;
    margin: 20px 0 0;
    line-height: 1.5em; }
  .tau-slider-info__link {
    margin: 45px 0 0; }

.tau-slider__nav-prev {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 1000; }
  .tau-slider__nav-prev span {
    display: block;
    height: 18px;
    width: 18px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: rotate(-45deg);
    margin: auto 0 auto 16px; }

.tau-slider__nav-next {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 1000; }
  .tau-slider__nav-next span {
    display: block;
    height: 18px;
    width: 18px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: rotate(135deg);
    margin: auto 16px auto 0; }

.tau-slider__dots {
  position: absolute;
  bottom: 25px;
  z-index: 1000;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0; }
  .tau-slider__dots li {
    display: inline-block; }
    .tau-slider__dots li button {
      background: rgba(255, 255, 255, 0.3);
      font-size: 0;
      height: 3px;
      width: 26px;
      margin: 0 8px;
      overflow: hidden; }
    .tau-slider__dots li.slick-active button {
      background: #fff; }

.tau-slider-about {
  padding-bottom: 85px; }
  .tau-slider-about .tau-slider__nav-prev, .tau-slider-about .tau-slider__nav-next {
    bottom: 80px; }
  .tau-slider-about__dots {
    margin-top: 80px;
    text-align: center;
    font-size: 0; }
    .tau-slider-about__dots li {
      display: inline-block; }
      .tau-slider-about__dots li button {
        background: rgba(82, 95, 139, 0.3);
        font-size: 0;
        height: 3px;
        width: 26px;
        margin: 0 8px;
        overflow: hidden; }
      .tau-slider-about__dots li.slick-active button {
        background: #7186CF; }

.tau-carousel {
  margin-bottom: 90px; }
  .tau-carousel__item {
    height: 196px;
    box-sizing: border-box; }
  .tau-carousel__nav-prev {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 65px;
    width: 40px;
    z-index: 1000; }
    .tau-carousel__nav-prev span {
      display: block;
      height: 18px;
      width: 18px;
      transform: rotate(-45deg);
      margin: auto 0 auto 16px; }
  .tau-carousel__nav-next {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 65px;
    width: 40px;
    z-index: 1000; }
    .tau-carousel__nav-next span {
      display: block;
      height: 18px;
      width: 18px;
      transform: rotate(135deg);
      margin: auto 16px auto 0; }
  .tau-carousel__nav_white span {
    border-top: 3px solid #fff;
    border-left: 3px solid #fff; }
  .tau-carousel__nav_blue span {
    border-top: 3px solid #2F438B;
    border-left: 3px solid #2F438B; }
  .tau-carousel__dots {
    margin-top: 55px;
    text-align: center;
    font-size: 0; }
    .tau-carousel__dots li {
      display: inline-block; }
      .tau-carousel__dots li button {
        background: #E3E3E4;
        font-size: 0;
        height: 3px;
        width: 26px;
        margin: 0 8px;
        overflow: hidden; }
      .tau-carousel__dots li.slick-active button {
        background: #7186CF; }
  .tau-carousel_about {
    padding: 30px 0 75px;
    margin-bottom: 60px; }
    .tau-carousel_about .tau-slick-carousel {
      margin-top: 15px; }
    .tau-carousel_about .slick-list {
      margin: 0 -12px; }
    .tau-carousel_about .slick-slide {
      margin: 0 12px; }
  .tau-carousel_partners .tau-carousel__item {
    padding: 0 20px;
    height: auto; }
    .tau-carousel_partners .tau-carousel__item img {
      width: 100%; }
  .tau-carousel_partners .tau-slick-carousel {
    padding: 0 40px;
    margin: 45px 0 0; }

@media (max-width: 480px) {
  .tau-slider__item figcaption {
    position: relative;
    bottom: 0;
    left: 0;
    height: 500px;
    box-sizing: border-box;
    max-width: none;
    width: 100%;
    background: transparent; }
  .tau-slider__item img {
    display: none; }
  .tau-slider-info {
    margin: auto 20px; }
    .tau-slider-info__title {
      font-size: 1.75em; }
    .tau-slider-info__desc {
      font-size: 1.375em; }
    .tau-slider-info__date {
      font-size: 1.375em; }
  .tau-slider__nav-next, .tau-slider__nav-prev {
    display: none !important; }
  .tau-slider__dots {
    bottom: 20px; }
  .tau-slider-about {
    display: none; }
  .tau-carousel__nav-prev, .tau-carousel__nav-next {
    display: none !important; }
  .tau-carousel__item {
    padding: 0 20px;
    height: auto; }
  .tau-carousel__dots {
    margin-top: 45px;
    width: 100%; }
    .tau-carousel__dots li {
      padding-top: 4px; }
  .tau-carousel_about {
    margin: 0;
    padding: 40px 0 45px; }
    .tau-carousel_about .slick-list, .tau-carousel_about .slick-slide {
      margin: 0; }
    .tau-carousel_about .tau-slick-carousel {
      margin-top: 40px; }
  .tau-carousel_partners {
    margin: 40px 0 45px; }
    .tau-carousel_partners .tau-slick-carousel {
      margin-top: 40px; } }

.tau-about__content {
  max-width: 920px;
  margin: 0 auto 70px; }

.tau-about__image {
  margin: 30px 0 65px;
  position: relative;
  border-radius: 5px;
  overflow: hidden; }
  .tau-about__image-desc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    color: #fff;
    box-shadow: inset 0 -80px 60px -50px rgba(47, 67, 139, 0.6);
    font-family: Panton;
    font-weight: bold;
    font-size: 3.5em; }

.tau-about-inner__content {
  max-width: 920px;
  margin: 50px auto 75px; }
  .tau-about-inner__content p {
    margin-bottom: 40px; }
  .tau-about-inner__content li {
    padding-left: 15px;
    position: relative; }
    .tau-about-inner__content li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #333; }

.tau-about__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-about__grid a {
    display: block;
    flex: 0 1 33.3%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-about__grid a:hover > div:after {
      background: #EB7E7F; }
    .tau-about__grid a:hover use {
      fill: #EB7E7F; }

.tau-about__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-about__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-about__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #7186CF;
    transition: all .3s ease; }

.tau-about__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-about__icon use {
    fill: #C7C2CD;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-about__grid {
    margin: 0; }
    .tau-about__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-about__item {
    padding: 15px 0 25px; }
    .tau-about__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-about__icon {
    margin: 5px 0 10px; } }

.tau-about__grid {
  padding: 60px 0 75px;
  justify-content: flex-start; }

.tau-color-blocks {
  line-height: 1.875em;
  margin-top: 35px; }
  .tau-color-blocks li {
    display: flex;
    padding-left: 32px;
    height: 55px;
    margin-top: 15px; }
    .tau-color-blocks li p {
      margin: auto 0; }
    .tau-color-blocks li:nth-child(odd) {
      box-shadow: inset 6px 0 0 -1px #EB7E7F; }
    .tau-color-blocks li:nth-child(even) {
      box-shadow: inset 6px 0 0 -1px #7186CF; }

@media (max-width: 480px) {
  .tau-about__content {
    margin: 0 auto 20px; }
    .tau-about__content > p {
      display: none; }
  .tau-about-inner .tau-container {
    display: flex;
    flex-wrap: wrap; }
    .tau-about-inner .tau-container > * {
      flex: 0 0 100%; }
    .tau-about-inner .tau-container .tau-title-h1 {
      order: 2; }
    .tau-about-inner .tau-container .tau-title-h5 {
      display: none; }
    .tau-about-inner .tau-container .tau-about__image {
      order: 1; }
    .tau-about-inner .tau-container .tau-about-inner__content {
      order: 4; }
  .tau-about-inner .tau-inline-logo {
    display: none; }
  .tau-about-inner__content {
    margin: 0 auto 20px; }
    .tau-about-inner__content p {
      padding: 0 20px;
      margin-bottom: 20px; }
    .tau-about-inner__content ul {
      padding: 0 20px; }
      .tau-about-inner__content ul p {
        padding: 0; }
  .tau-about__image {
    margin: 0;
    border-radius: 0;
    height: 440px; }
    .tau-about__image img {
      height: 100%;
      width: auto; }
    .tau-about__image-desc {
      box-shadow: none;
      padding: 0 20px;
      font-size: 2em;
      z-index: 1;
      top: 0;
      display: flex; }
      .tau-about__image-desc span {
        margin: auto; }
    .tau-about__image:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #525F8B;
      opacity: .7; }
  .tau-color-blocks {
    line-height: 1.875em;
    margin-top: 15px; }
    .tau-color-blocks li {
      padding: 12px 12px 12px 19px;
      height: auto;
      margin-top: 0; } }

.tau-students__content {
  padding: 35px 0 55px; }

.tau-students__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-students__grid a {
    display: block;
    flex: 0 1 25%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-students__grid a:hover > div:after {
      background: #EB7E7F; }
    .tau-students__grid a:hover use {
      fill: #EB7E7F; }

.tau-students__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-students__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-students__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #7186CF;
    transition: all .3s ease; }

.tau-students__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-students__icon use {
    fill: #C7C2CD;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-students__grid {
    margin: 0; }
    .tau-students__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-students__item {
    padding: 15px 0 25px; }
    .tau-students__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-students__icon {
    margin: 5px 0 10px; } }

.tau-students-inner__content {
  max-width: 920px;
  margin: 0 auto 45px; }

.tau-students-inner .tau-students__grid {
  padding: 60px 0;
  justify-content: flex-start; }
  .tau-students-inner .tau-students__grid a {
    flex: 0 1 33.3%; }

@media (max-width: 480px) {
  .tau-students-inner__content {
    padding: 0 20px;
    margin-bottom: 40px; }
  .tau-students-inner .tau-students__grid {
    padding: 45px 0; }
    .tau-students-inner .tau-students__grid a {
      flex: 0 1 100%; } }

.tau-partners__content {
  padding: 35px 0 75px; }

.tau-partners__grid {
  justify-content: center; }

.tau-partners__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-partners__grid a {
    display: block;
    flex: 0 1 25%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-partners__grid a:hover > div:after {
      background: #EB7E7F; }
    .tau-partners__grid a:hover use {
      fill: #EB7E7F; }

.tau-partners__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-partners__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-partners__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #7186CF;
    transition: all .3s ease; }

.tau-partners__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-partners__icon use {
    fill: #C7C2CD;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-partners__grid {
    margin: 0; }
    .tau-partners__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-partners__item {
    padding: 15px 0 25px; }
    .tau-partners__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-partners__icon {
    margin: 5px 0 10px; } }

@media (max-width: 480px) {
  .tau-partners__content {
    padding: 35px 0 45px; } }

.tau-join__content {
  max-width: 920px;
  margin: 45px auto;
  text-align: center; }

.tau-join__desc {
  margin: 30px 0 40px;
  line-height: 1.75em; }

.tau-join__buttons {
  margin-bottom: 35px; }

@media (max-width: 480px) {
  .tau-join__content {
    margin: auto;
    padding: 30px 20px 40px; }
    .tau-join__content .tau-title-h3 {
      color: #fff; }
    .tau-join__content .tau-inline-logo {
      display: none; }
  .tau-join__desc {
    margin: 15px 0 35px; }
  .tau-join__buttons {
    margin-bottom: 0;
    padding: 0 25px; } }

.tau-impaired p a {
  color: #000 !important; }

.tau-impaired .tau-container {
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 40px;
  position: relative; }

.tau-impaired .tau-bg-gray {
  background: #fff; }

.tau-impaired .tau-bg-red {
  background: #000; }

.tau-impaired .tau-flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between; }

.tau-impaired .tau-title-h1 {
  font: bold 2.5em/3em Panton;
  color: #000;
  text-align: center; }
  .tau-impaired .tau-title-h1-new {
    font: bold 1.75em/1.4em Panton;
    color: #000;
    margin-bottom: 30px; }

.tau-impaired .tau-title-h2 {
  font: bold 2.5em/3em Panton;
  color: #000;
  text-align: center; }

.tau-impaired .tau-title-h3 {
  font: bold 1.75em/2.125em Panton;
  color: #000;
  text-align: center; }

.tau-impaired .tau-title-h4 {
  font: bold 1.625em/1.5em Istok web;
  color: #000;
  margin: 0 0 35px;
  padding: 35px 0 0; }

.tau-impaired .tau-title-h5 {
  font: normal 1em/1.5em Istok web;
  color: #000;
  text-align: center;
  margin-bottom: 35px; }
  .tau-impaired .tau-title-h5-new {
    font-size: 1.25em;
    font-weight: 500;
    color: #000;
    text-transform: lowercase; }

.tau-impaired .tau-inline-logo {
  height: 75px;
  width: 100%; }
  .tau-impaired .tau-inline-logo_red {
    background-image: url(../images/inline-logo-red.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .tau-impaired .tau-inline-logo_blue {
    background-image: url(../images/inline-logo-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }

.tau-impaired .tau-inline-slogo {
  height: 35px;
  width: 100%;
  display: none; }

.tau-impaired .tau-join-button {
  position: fixed;
  bottom: 100px;
  right: 88px;
  z-index: 1000; }
  .tau-impaired .tau-join-button a {
    animation: pulse 1.8s infinite; }

.tau-impaired .tau-attention {
  padding: 55px 50px;
  text-align: center; }
  .tau-impaired .tau-attention__title {
    font-family: Panton;
    font-weight: bold;
    font-size: 2.375em;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px; }

.tau-impaired #tau-header {
  background: #fff;
  color: #000;
  padding: 25px 0; }
  .tau-impaired #tau-header a {
    display: block;
    color: #000; }

.tau-impaired #tau-footer {
  background: #000;
  padding: 30px 0; }
  .tau-impaired #tau-footer a, .tau-impaired #tau-footer span, .tau-impaired #tau-footer p {
    display: inline-block;
    color: #fff; }

.tau-impaired #tau-about {
  padding: 35px 0 25px; }

.tau-impaired #tau-news {
  padding: 0 0 75px; }

@media (max-width: 480px) {
  .tau-impaired .tau-container {
    padding: 0; }
  .tau-impaired .tau-title-h1 {
    font: bold 1.75em/1.5em Panton;
    margin: 18px 0;
    padding: 0 20px; }
    .tau-impaired .tau-title-h1-new {
      font: bold 1.15em/1.4em Panton;
      margin: 25px auto;
      padding-left: 15px;
      box-shadow: inset 5px 0 #eb7e7f; }
  .tau-impaired .tau-title-h2 {
    font: bold 1.75em/2em Panton;
    margin-bottom: 15px;
    padding: 0 20px; }
  .tau-impaired .tau-title-h4 {
    font: bold 1em/1.9em Istok web;
    margin: 15px 0;
    padding: 2px 20px; }
    .tau-impaired .tau-title-h4:nth-of-type(odd) {
      box-shadow: inset 5px 0 #000; }
    .tau-impaired .tau-title-h4:nth-of-type(even) {
      box-shadow: inset 5px 0 #000; }
  .tau-impaired .tau-title-h5 {
    color: #000;
    margin-bottom: 15px;
    padding: 0 40px; }
    .tau-impaired .tau-title-h5-new {
      font-size: 1em;
      font-weight: bold;
      margin-bottom: 20px; }
  .tau-impaired .tau-inline-logo {
    height: 35px; }
  .tau-impaired .tau-inline-slogo {
    display: block; }
  .tau-impaired .tau-join-button {
    display: none; }
  .tau-impaired .tau-attention {
    padding: 25px 60px 35px; }
    .tau-impaired .tau-attention__title {
      font-size: 1.5em;
      line-height: 1.5em;
      margin: 0 0 20px; }
  .tau-impaired #tau-header {
    padding: 11px 20px; }
  .tau-impaired #tau-slider {
    background: #000; }
  .tau-impaired #tau-about {
    padding: 40px 0 25px; }
  .tau-impaired #tau-news {
    padding: 0 0 5px; }
    .tau-impaired #tau-news .tau-carousel__dots {
      margin-top: 15px; }
  .tau-impaired #tau-join {
    background: #000; }
  .tau-impaired #tau-footer {
    padding: 50px 0 55px; } }

.tau-impaired .daterangepicker {
  left: 0 !important;
  top: 100% !important;
  border-radius: 0;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.07);
  margin-top: 1px; }
  .tau-impaired .daterangepicker:before, .tau-impaired .daterangepicker:after {
    display: none; }
  .tau-impaired .daterangepicker.show-calendar .ranges {
    margin-top: 0; }
  .tau-impaired .daterangepicker .drp-calendar {
    max-width: none;
    padding: 0 10px 12px !important; }
    .tau-impaired .daterangepicker .drp-calendar.right {
      display: none !important; }
  .tau-impaired .daterangepicker .calendar-table {
    padding: 0 !important; }
    .tau-impaired .daterangepicker .calendar-table .prev span, .tau-impaired .daterangepicker .calendar-table .next span {
      border: solid #000;
      border-width: 0 1px 1px 0; }
    .tau-impaired .daterangepicker .calendar-table thead tr {
      height: 50px; }
      .tau-impaired .daterangepicker .calendar-table thead tr:first-child th {
        font-family: Panton;
        font-weight: bold;
        font-size: 1em;
        color: #000; }
      .tau-impaired .daterangepicker .calendar-table thead tr:last-child {
        box-shadow: inset 0 1px 0 rgba(228, 228, 228, 0.2); }
        .tau-impaired .daterangepicker .calendar-table thead tr:last-child th {
          font-size: .75em;
          font-family: Panton;
          font-weight: normal;
          color: #000; }
      .tau-impaired .daterangepicker .calendar-table thead tr th.available:hover {
        background: transparent; }
    .tau-impaired .daterangepicker .calendar-table td {
      min-width: 36px;
      height: 22px;
      line-height: 20px;
      font-size: .875em;
      font-family: Panton;
      font-weight: normal; }

.tau-impaired .tau-calendar-day {
  position: relative;
  border: none !important; }
  .tau-impaired .tau-calendar-day.available {
    color: #000; }
    .tau-impaired .tau-calendar-day.available:hover {
      color: #000 !important;
      background: transparent !important; }
  .tau-impaired .tau-calendar-day.off {
    color: #000 !important; }
  .tau-impaired .tau-calendar-day.in-range {
    color: #000 !important;
    background: rgba(165, 179, 234, 0.6) !important; }
    .tau-impaired .tau-calendar-day.in-range:hover {
      background: rgba(165, 179, 234, 0.6) !important; }
  .tau-impaired .tau-calendar-day.active {
    background: #fff !important;
    border-radius: 50%;
    color: #fff !important; }
    .tau-impaired .tau-calendar-day.active:before {
      content: attr(data-content);
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      top: 0;
      left: 50%;
      margin-left: -12px;
      background: #000;
      border-radius: 50%;
      position: absolute;
      z-index: 1; }
    .tau-impaired .tau-calendar-day.active:hover {
      background: #fff !important;
      color: #fff !important; }
  .tau-impaired .tau-calendar-day.start-date:after {
    content: '';
    background: rgba(165, 179, 234, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 0; }
  .tau-impaired .tau-calendar-day.start-date.end-date:after {
    display: none; }
  .tau-impaired .tau-calendar-day.end-date:after {
    content: '';
    background: rgba(165, 179, 234, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 0; }

@media (max-width: 480px) {
  .tau-impaired .daterangepicker {
    position: fixed;
    left: 0 !important;
    top: 56px !important;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07); } }

.tau-impaired .tau-btn {
  display: inline-block;
  padding: 16px 15px 15px;
  width: 400px;
  text-align: center;
  border-radius: 5px;
  font: bold 1.25em Panton;
  cursor: pointer;
  box-sizing: border-box;
  transition: all .3s ease !important; }
  .tau-impaired .tau-btn_s {
    display: inline-block;
    padding: 6px 35px;
    border-radius: 5px;
    font: bold 1.125em Panton;
    cursor: pointer;
    transition: all .3s ease !important; }
  .tau-impaired .tau-btn_m {
    display: inline-block;
    padding: 10px 45px;
    border-radius: 5px;
    font: bold 1.125em Panton;
    cursor: pointer;
    transition: all .3s ease !important; }
  .tau-impaired .tau-btn_red {
    background: #000;
    color: #fff !important; }
    .tau-impaired .tau-btn_red:hover {
      background: #111111; }
  .tau-impaired .tau-btn_blue {
    background: #000;
    color: #fff !important; }
    .tau-impaired .tau-btn_blue:hover {
      background: #111111; }
  .tau-impaired .tau-btn_white {
    background: #fff;
    color: #000 !important; }
    .tau-impaired .tau-btn_white:hover {
      box-shadow: inset -100px 0 100px -100px rgba(0, 0, 0, 0.2); }
    .tau-impaired .tau-btn_white_s {
      background: #fff;
      color: #000 !important; }
      .tau-impaired .tau-btn_white_s:hover {
        box-shadow: inset -100px 0 100px -100px rgba(0, 0, 0, 0.2); }
  .tau-impaired .tau-btn_filter {
    border-radius: 5px;
    border: 2px solid #000;
    padding: 1px 45px 0;
    display: inline-block; }
    .tau-impaired .tau-btn_filter span {
      color: #000;
      line-height: 2.4em; }
    .tau-impaired .tau-btn_filter .tau-label-filter {
      display: inline-block;
      margin-left: 30px;
      vertical-align: sub; }
    .tau-impaired .tau-btn_filter-search {
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 0 5px 5px 0;
      font: bold 1em Panton;
      background: #000;
      color: #fff !important;
      transition: all .3s ease !important; }
      .tau-impaired .tau-btn_filter-search:hover {
        background: #111111; }

@media (max-width: 480px) {
  .tau-impaired .tau-sbtn_blue {
    padding: 9px 15px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font: bold 1.05em Panton;
    cursor: pointer;
    background: #000;
    color: #fff !important; }
    .tau-impaired .tau-sbtn_blue:hover {
      background: #000; }
  .tau-impaired .tau-sbtn_white {
    padding: 9px 15px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font: bold 1.05em Panton;
    cursor: pointer;
    background: #fff;
    color: #000 !important; }
    .tau-impaired .tau-sbtn_white:hover {
      background: #fff; }
  .tau-impaired .tau-btn_filter-search {
    padding: 9px 10px; }
  .tau-impaired .tau-btn_filter-clear {
    padding: 7px 10px;
    text-align: center;
    font: bold 1em Panton;
    border: 2px solid #000;
    color: #000 !important; } }

.tau-impaired .tau-icon {
  display: block; }

.tau-impaired .tau-search {
  height: 23px;
  width: 23px;
  cursor: pointer;
  background-image: url(../images/icons/icon-search-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-lk {
  position: absolute;
  height: 18px;
  width: 12px;
  left: 20px;
  top: 8px;
  background-image: url(../images/icons/icon-lk-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

@media (max-width: 480px) {
  .tau-impaired .tau-lk {
    position: relative;
    left: 0;
    top: 0; } }

.tau-impaired .tau-vision {
  width: 31px;
  height: 15px;
  margin: 14px auto;
  background-image: url(../images/icons/icon-vision-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-phone {
  width: 18px;
  height: 16px;
  top: 13px;
  background-image: url(../images/icons/icon-phone.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-handset {
  width: 16px;
  height: 16px;
  background-image: url(../images/icons/icon-handset-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-email {
  width: 15px;
  height: 12px;
  top: 16px;
  background-image: url(../images/icons/icon-email.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-impaired .tau-email-gray {
    width: 15px;
    height: 15px;
    background-image: url(../images/icons/icon-email-gray-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-impaired .tau-time {
  width: 16px;
  height: 16px;
  top: 14px;
  background-image: url(../images/icons/icon-time.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-review {
  width: 16px;
  height: 16px;
  top: 12px;
  background-image: url(../images/icons/icon-review.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-date {
  width: 14px;
  height: 14px;
  background-image: url(../images/icons/icon-date-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-age {
  width: 15px;
  height: 15px;
  background-image: url(../images/icons/icon-age-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-participant {
  width: 14px;
  height: 16px;
  background-image: url(../images/icons/icon-participant-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-vk {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/icon-vk.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-impaired .tau-vk-rp {
    height: 30px;
    width: 30px;
    background-image: url(../images/icons/icon-vk-rp-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }
  .tau-impaired .tau-vk-menu {
    height: 24px;
    width: 24px;
    background-image: url(../images/icons/icon-vk-menu-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-impaired .tau-fb {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/icon-fb.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-impaired .tau-fb-rp {
    height: 30px;
    width: 30px;
    background-image: url(../images/icons/icon-fb-rp-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }
  .tau-impaired .tau-fb-menu {
    height: 24px;
    width: 24px;
    background-image: url(../images/icons/icon-fb-menu-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-impaired .tau-inst {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/icon-inst.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }
  .tau-impaired .tau-inst-menu {
    width: 24px;
    height: 24px;
    background-image: url(../images/icons/icon-inst-menu-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }

.tau-impaired .tau-tel-rp {
  height: 30px;
  width: 30px;
  background-image: url(../images/icons/icon-tel-rp-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-tw-rp {
  height: 30px;
  width: 30px;
  background-image: url(../images/icons/icon-tw-rp-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-doc {
  width: 20px;
  height: 20px;
  background-image: url(../images/icons/icon-doc-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

@media (max-width: 480px) {
  .tau-impaired .tau-doc {
    width: 15px;
    height: 15px; } }

.tau-impaired .tau-close {
  width: 20px;
  height: 20px;
  background-image: url(../images/icons/icon-close-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-label {
  width: 11px;
  height: 15px;
  background-image: url(../images/icons/icon-label-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-label-filter {
  width: 14px;
  height: 17px;
  background-image: url(../images/icons/icon-filter-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-ok {
  height: 25px;
  width: 25px;
  background-image: url(../images/icons/icon-ok-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-no {
  height: 25px;
  width: 25px;
  background-image: url(../images/icons/icon-no-im.svg) ;
  background-repeat: no-repeat ;
  background-position: center ;
  background-size: contain ; }

.tau-impaired .tau-students-grid-1 {
  width: 67px; }

.tau-impaired .tau-students-grid-2 {
  width: 46px; }
  .tau-impaired .tau-students-grid-2 use {
    transform: translate(-72px, 0); }

.tau-impaired .tau-students-grid-3 {
  width: 56px; }
  .tau-impaired .tau-students-grid-3 use {
    transform: translate(-120px, 0); }

.tau-impaired .tau-students-grid-4 {
  width: 40px; }
  .tau-impaired .tau-students-grid-4 use {
    transform: translate(-177px, 0); }

.tau-impaired .tau-students-grid-5 {
  width: 50px; }
  .tau-impaired .tau-students-grid-5 use {
    transform: translate(-221px, 0); }

.tau-impaired .tau-students-grid-6 {
  width: 55px; }
  .tau-impaired .tau-students-grid-6 use {
    transform: translate(-279px, 0); }

.tau-impaired .tau-students-grid-7 {
  width: 56px; }
  .tau-impaired .tau-students-grid-7 use {
    transform: translate(-338px, 0); }

.tau-impaired .tau-students-grid-8 {
  width: 69px; }
  .tau-impaired .tau-students-grid-8 use {
    transform: translate(-401px, 0); }

.tau-impaired .tau-partners-grid-1 {
  width: 69px; }
  .tau-impaired .tau-partners-grid-1 use {
    transform: translate(0, -60px); }

.tau-impaired .tau-partners-grid-2 {
  width: 53px; }
  .tau-impaired .tau-partners-grid-2 use {
    transform: translate(-75px, -60px); }

.tau-impaired .tau-about-grid-1 {
  width: 50px; }
  .tau-impaired .tau-about-grid-1 use {
    transform: translate(0, -120px); }

.tau-impaired .tau-about-grid-2 {
  width: 64px; }
  .tau-impaired .tau-about-grid-2 use {
    transform: translate(-54px, -120px); }

.tau-impaired .tau-about-grid-3 {
  width: 61px; }
  .tau-impaired .tau-about-grid-3 use {
    transform: translate(-121px, -120px); }

.tau-impaired .tau-about-grid-4 {
  width: 51px; }
  .tau-impaired .tau-about-grid-4 use {
    transform: translate(-188px, -120px); }

.tau-impaired .tau-about-grid-5 {
  width: 50px; }
  .tau-impaired .tau-about-grid-5 use {
    transform: translate(-246px, -120px); }

.tau-impaired .tau-about-grid-6 {
  width: 65px; }
  .tau-impaired .tau-about-grid-6 use {
    transform: translate(-300px, -120px); }

.tau-impaired .tau-about-grid-7 {
  width: 41px; }
  .tau-impaired .tau-about-grid-7 use {
    transform: translate(-370px, -120px); }

.tau-impaired .tau-about-grid-8 {
  width: 56px; }
  .tau-impaired .tau-about-grid-8 use {
    transform: translate(-414px, -120px); }

.tau-impaired .tau-about-grid-9 {
  width: 48px; }
  .tau-impaired .tau-about-grid-9 use {
    transform: translate(-474px, -120px); }

.tau-impaired .tau-about-grid-10 {
  width: 59px; }
  .tau-impaired .tau-about-grid-10 use {
    transform: translate(-530px, -120px); }

.tau-impaired .tau-about-grid-11 {
  width: 50px; }
  .tau-impaired .tau-about-grid-11 use {
    transform: translate(0, -120px); }

.tau-impaired .tau-services-grid-1 {
  width: 55px; }
  .tau-impaired .tau-services-grid-1 use {
    transform: translate(0, -180px); }

.tau-impaired .tau-services-grid-2 {
  width: 50px; }
  .tau-impaired .tau-services-grid-2 use {
    transform: translate(-63px, -180px); }

.tau-impaired .tau-services-grid-3 {
  width: 50px; }
  .tau-impaired .tau-services-grid-3 use {
    transform: translate(-123px, -180px); }

.tau-impaired .tau-services-grid-4 {
  width: 50px; }
  .tau-impaired .tau-services-grid-4 use {
    transform: translate(-179px, -180px); }

.tau-impaired .tau-services-grid-5 {
  width: 55px; }
  .tau-impaired .tau-services-grid-5 use {
    transform: translate(-238px, -180px); }

.tau-impaired .tau-services-grid-6 {
  width: 38px; }
  .tau-impaired .tau-services-grid-6 use {
    transform: translate(-298px, -180px); }

.tau-impaired .tau-services-grid-7 {
  width: 64px; }
  .tau-impaired .tau-services-grid-7 use {
    transform: translate(-345px, -180px); }

.tau-impaired .tau-services-grid-8 {
  width: 67px; }
  .tau-impaired .tau-services-grid-8 use {
    transform: translate(-416px, -180px); }

.tau-impaired .tau-services-grid-9 {
  width: 69px; }
  .tau-impaired .tau-services-grid-9 use {
    transform: translate(-487px, -180px); }

.tau-impaired .tau-form-wrap form {
  padding: 10px 0;
  text-align: left; }

.tau-impaired .tau-form-label {
  display: inline-block;
  margin-bottom: 12px; }
  .tau-impaired .tau-form-label-file {
    font-size: 0.875em;
    color: #000;
    text-align: center;
    line-height: 36px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: 2px solid #000;
    border-radius: 20px;
    width: 60%;
    max-width: 230px;
    user-select: none;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.tau-impaired .tau-form__field {
  margin-bottom: 30px;
  position: relative; }
  .tau-impaired .tau-form__field-desc {
    color: #000;
    margin: 10px 0 60px;
    position: relative;
    padding-left: 12px; }
    .tau-impaired .tau-form__field-desc:before {
      content: '*';
      position: absolute;
      left: 0;
      top: 0; }

.tau-impaired .tau-form__buttons {
  text-align: center; }

.tau-impaired .tau-form-success {
  text-align: center;
  color: #338e37; }

.tau-impaired .tau-form-error {
  text-align: center;
  color: #000; }

.tau-impaired .tau-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  transition: border .2s ease; }
  .tau-impaired .tau-input_text {
    padding: 8px 15px 7px;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.05);
    position: relative; }
    .tau-impaired .tau-input_text:focus {
      border: 1px solid #000; }
    .tau-impaired .tau-input_text:disabled {
      background: #fff;
      color: #000; }
    .tau-impaired .tau-input_text-phone {
      padding-left: 35px; }
  .tau-impaired .tau-input_file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .tau-impaired .tau-input_select {
    padding: 8px 15px 7px;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.05);
    position: relative; }
    .tau-impaired .tau-input_select:focus {
      border: 1px solid #000; }
  .tau-impaired .tau-input_radio {
    margin: 0;
    position: absolute;
    height: 0;
    left: -15px;
    visibility: hidden; }
    .tau-impaired .tau-input_radio + label {
      cursor: pointer; }
      .tau-impaired .tau-input_radio + label:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        height: 28px;
        width: 28px;
        background: #fff;
        border-radius: 50%;
        border: 2px solid #fff;
        box-shadow: inset 0 0 0 5px #fff; }
    .tau-impaired .tau-input_radio:checked + label:before {
      background: #000; }
  .tau-impaired .tau-input-desc {
    display: inline-block;
    color: #000;
    margin-left: 5px;
    font-size: .875em;
    user-select: none;
    max-width: 200px;
    width: 36%;
    vertical-align: middle; }

.tau-impaired .tau-textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 15px 7px;
  border: 1px solid #cecece;
  border-radius: 5px;
  box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.05);
  transition: border .2s ease; }
  .tau-impaired .tau-textarea:focus {
    border: 1px solid #000; }
  .tau-impaired .tau-textarea:disabled {
    background: #fff;
    color: #000; }

.tau-impaired .tau-error {
  border: 1px solid #000; }

.tau-impaired .tau-phone-prefix {
  color: #000;
  font: normal .875em/1.47em Istok Web;
  position: absolute;
  bottom: 9px;
  left: 16px; }

@media (max-width: 480px) {
  .tau-impaired .tau-form-label {
    margin-bottom: 15px; }
    .tau-impaired .tau-form-label-file {
      margin: 15px 0;
      font-family: Panton;
      font-weight: bold;
      font-size: 1em;
      color: #000;
      border-radius: 5px; }
  .tau-impaired .tau-form__field {
    margin-bottom: 15px; }
    .tau-impaired .tau-form__field-desc {
      margin-bottom: 40px; }
  .tau-impaired .tau-input-desc {
    font-size: .715em; } }

.tau-impaired .tau-header__logo {
  height: 75px;
  width: 75px;
  display: inline-block;
  vertical-align: top;
  background-image: url(../images/logo-im.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.tau-impaired .tau-header__logo-text {
  display: inline-block;
  position: relative;
  margin-left: 6px; }
  .tau-impaired .tau-header__logo-text-tau {
    height: 75px;
    background-image: url(../images/logo-text-im.svg);
    background-repeat: no-repeat;
    background-position: 0 22px;
    background-size: auto 40px; }
  .tau-impaired .tau-header__logo-text-other {
    color: #000;
    display: block;
    margin-top: -12px;
    font-family: Panton;
    font-weight: bold;
    font-size: .875em; }

.tau-impaired .tau-header__menu {
  padding-top: 5px; }
  .tau-impaired .tau-header__menu .tau-header__lk, .tau-impaired .tau-header__menu .tau-social {
    display: none; }
  .tau-impaired .tau-header__menu-item {
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .tau-impaired .tau-header__menu-item > a {
      font-family: Panton;
      font-weight: bold;
      font-size: 1.125em;
      padding: 20px;
      position: relative; }
    .tau-impaired .tau-header__menu-item:hover > a {
      background: #fff;
      box-shadow: inset 0 5px 0 #000, 0px -22px 28px rgba(0, 0, 0, 0.07);
      z-index: 6; }
    .tau-impaired .tau-header__menu-item:hover .tau-header__submenu {
      display: block; }
    .tau-impaired .tau-header__menu-item_active > a {
      box-shadow: inset 0 5px 0 #000; }
    .tau-impaired .tau-header__menu-item_with_submenu > a:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 5px;
      width: 5px;
      border: 2px solid #000;
      border-left: none;
      border-top: none;
      transform: rotate(45deg);
      margin: -3px 0 0 5px; }

.tau-impaired .tau-header__submenu {
  display: none;
  position: absolute;
  background: #fff;
  width: 410px;
  padding: 20px 0;
  box-shadow: 0 12px 42px rgba(0, 0, 0, 0.15);
  z-index: 5; }
  .tau-impaired .tau-header__submenu li {
    display: block; }
    .tau-impaired .tau-header__submenu li a {
      font-family: Istok Web;
      font-weight: normal;
      font-size: 1em;
      padding: 10px 20px;
      transition: background .2s ease; }
      .tau-impaired .tau-header__submenu li a:hover {
        background: #fff;
        box-shadow: none; }

.tau-impaired .tau-header__right-wrapper {
  height: 75px; }

.tau-impaired .tau-header__search {
  margin: auto 15px auto 0;
  position: relative; }

.tau-impaired .tau-header__lk {
  margin: auto;
  position: relative;
  font-family: Panton;
  font-weight: bold;
  line-height: 2.375em;
  padding-left: 40px;
  border-left: 1px solid #cecece; }

.tau-impaired .tau-header__hamburger {
  display: none;
  margin: auto;
  padding-left: 30px; }
  .tau-impaired .tau-header__hamburger a {
    display: block;
    height: 2px;
    width: 20px;
    background: #000;
    position: relative; }
    .tau-impaired .tau-header__hamburger a:before {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #000;
      position: absolute;
      top: -7px;
      left: 0;
      transition: all .2s ease; }
    .tau-impaired .tau-header__hamburger a:after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background: #000;
      position: absolute;
      bottom: -7px;
      left: 0;
      transition: all .2s ease; }

.tau-impaired .tau-header__vision {
  position: absolute;
  top: -25px;
  right: 40px;
  width: 45px;
  height: 45px;
  background: #000; }

@media (max-width: 480px) {
  .tau-impaired .tau-header__logo {
    height: 37px;
    width: 37px; }
    .tau-impaired .tau-header__logo-text {
      margin-left: 0; }
      .tau-impaired .tau-header__logo-text-tau {
        height: 40px;
        width: 45px;
        background-position: 0;
        background-size: contain; }
      .tau-impaired .tau-header__logo-text-other {
        display: none; }
  .tau-impaired .tau-header__right-wrapper {
    height: auto; }
  .tau-impaired .tau-header__left-wrapper a {
    height: 37px; }
  .tau-impaired .tau-header__search {
    margin: auto 0; }
  .tau-impaired .tau-header__lk {
    border: none;
    padding-left: 25px; }
    .tau-impaired .tau-header__lk-ex a span {
      display: none; }
  .tau-impaired .tau-header__hamburger {
    display: block; }
  .tau-impaired .tau-header__vision {
    display: none; }
  .tau-impaired .tau-header__menu {
    display: none;
    position: fixed;
    top: 59px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 35px;
    z-index: 100;
    background: #fff; }
    .tau-impaired .tau-header__menu ul {
      padding: 0 20px; }
      .tau-impaired .tau-header__menu ul:before, .tau-impaired .tau-header__menu ul:after {
        content: '';
        display: block;
        height: 1px;
        width: 115px;
        background: #000; }
    .tau-impaired .tau-header__menu-item {
      display: block; }
      .tau-impaired .tau-header__menu-item:hover .tau-header__submenu {
        display: none; }
      .tau-impaired .tau-header__menu-item > a {
        font-size: 1.25em;
        padding: 12px 0; }
        .tau-impaired .tau-header__menu-item > a:hover {
          box-shadow: none; }
      .tau-impaired .tau-header__menu-item_active a {
        box-shadow: none; }
      .tau-impaired .tau-header__menu-item:first-child a {
        padding: 24px 0 12px; }
      .tau-impaired .tau-header__menu-item:last-child a {
        padding: 12px 0 30px; }
      .tau-impaired .tau-header__menu-item_with_submenu > a:after {
        display: none; }
    .tau-impaired .tau-header__menu .tau-header__lk {
      display: block;
      margin-bottom: 25px; }
      .tau-impaired .tau-header__menu .tau-header__lk i {
        display: inline-block;
        vertical-align: middle; }
      .tau-impaired .tau-header__menu .tau-header__lk span {
        vertical-align: middle;
        font-weight: normal;
        margin-left: 6px; }
    .tau-impaired .tau-header__menu .tau-social {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0; }
      .tau-impaired .tau-header__menu .tau-social__item {
        display: inline-block !important; }
  .tau-impaired .tau-open-menu .tau-header__search, .tau-impaired .tau-open-menu .tau-header__lk {
    display: none; }
  .tau-impaired .tau-open-menu .tau-header__menu {
    display: block; }
    .tau-impaired .tau-open-menu .tau-header__menu .tau-header__lk, .tau-impaired .tau-open-menu .tau-header__menu .tau-social {
      display: block; }
  .tau-impaired .tau-open-menu .tau-header__hamburger a {
    height: 0; }
    .tau-impaired .tau-open-menu .tau-header__hamburger a:before {
      top: -1px;
      background: #000;
      transform: rotate(45deg); }
    .tau-impaired .tau-open-menu .tau-header__hamburger a:after {
      bottom: -1px;
      background: #000;
      transform: rotate(-45deg); } }

.tau-impaired .tau-footer__logo {
  height: 50px;
  background-image: url(../images/logo-white.svg);
  background-repeat: no-repeat;
  background-size: contain; }

.tau-impaired .tau-footer__info {
  font-size: .875em;
  margin-top: 25px; }

.tau-impaired .tau-footer__grid {
  flex-wrap: wrap; }

.tau-impaired .tau-footer__item {
  flex: 0 1 25%;
  text-align: center; }
  .tau-impaired .tau-footer__item-with-logo {
    display: flex;
    text-align: left; }
    .tau-impaired .tau-footer__item-with-logo > div {
      margin: auto 0; }

.tau-impaired .tau-footer__menu {
  display: inline-block;
  text-align: left; }
  .tau-impaired .tau-footer__menu li a, .tau-impaired .tau-footer__menu li span {
    font-family: Panton;
    font-weight: bold;
    padding: 8px 0 7px; }
  .tau-impaired .tau-footer__menu_with_icons li {
    padding-left: 25px;
    position: relative; }
    .tau-impaired .tau-footer__menu_with_icons li a, .tau-impaired .tau-footer__menu_with_icons li span {
      padding: 8px 0 7px;
      vertical-align: middle; }
    .tau-impaired .tau-footer__menu_with_icons li .tau-icon {
      position: absolute;
      left: 0; }

.tau-impaired .tau-social {
  text-align: center; }
  .tau-impaired .tau-social__item {
    display: inline-block;
    margin: 0 9px; }

@media (max-width: 480px) {
  .tau-impaired .tau-footer__grid {
    padding: 0 20px; }
  .tau-impaired .tau-footer__info {
    margin: 0; }
  .tau-impaired .tau-footer__item {
    flex: 0 1 100%;
    text-align: left;
    margin-bottom: 35px; }
    .tau-impaired .tau-footer__item:nth-child(1) {
      order: 3; }
    .tau-impaired .tau-footer__item:nth-child(2) {
      order: 1; }
    .tau-impaired .tau-footer__item:nth-child(3) {
      order: 2; }
    .tau-impaired .tau-footer__item:nth-child(4) {
      order: 4;
      margin-bottom: 0;
      margin-top: 15px; }
  .tau-impaired .tau-footer__logo {
    display: none; }
  .tau-impaired .tau-footer__menu li a, .tau-impaired .tau-footer__menu li span {
    padding: 8px 0 18px; }
  .tau-impaired .tau-footer__menu_with_icons li:last-child {
    margin-top: -18px; } }

.tau-impaired .tau-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: -17px;
  display: none;
  z-index: 10000;
  overflow-x: auto;
  background: rgba(51, 51, 51, 0.3);
  padding: 40px 0; }
  .tau-impaired .tau-modal_open {
    display: flex;
    animation: show-modal .2s ease; }
    .tau-impaired .tau-modal_open .tau-modal__wrap {
      display: block;
      animation: show-modal-wrap .4s ease; }
  .tau-impaired .tau-modal_close {
    animation: hide-modal .3s ease;
    opacity: 0; }
    .tau-impaired .tau-modal_close .tau-modal__wrap {
      animation: hide-modal-wrap .2s ease;
      opacity: 0; }
  .tau-impaired .tau-modal__wrap {
    background: #fff;
    border-radius: 4px;
    padding: 55px;
    flex-basis: 20%;
    box-sizing: border-box;
    position: relative;
    margin: auto;
    z-index: 1;
    display: none;
    transition: all .8s ease; }
  .tau-impaired .tau-modal__title {
    font-family: Panton;
    font-weight: bold;
    font-size: 2.125em;
    color: #000;
    margin-bottom: 45px;
    text-align: center; }
  .tau-impaired .tau-modal__close {
    position: absolute;
    right: 35px;
    top: 35px;
    cursor: pointer; }

.tau-impaired .tau-form-feedback {
  width: 400px;
  padding: 0 60px;
  box-sizing: border-box; }

.tau-impaired .tau-form-vacancy {
  width: 620px;
  padding: 0 80px;
  box-sizing: border-box; }

.tau-impaired .tau-form-auth {
  width: 350px;
  padding: 0 80px;
  box-sizing: border-box; }

.tau-impaired .tau-form-business {
  width: 400px;
  padding: 0 60px;
  box-sizing: border-box; }

@media (max-width: 480px) {
  .tau-impaired .tau-modal {
    padding: 0;
    right: -9px; }
    .tau-impaired .tau-modal__wrap {
      border-radius: 0;
      flex-basis: 100%;
      min-height: 100%;
      padding: 50px 29px 50px 20px; }
    .tau-impaired .tau-modal__close {
      top: 25px;
      right: 29px; }
    .tau-impaired .tau-modal__title {
      margin-bottom: 35px; }
  .tau-impaired .tau-form-feedback, .tau-impaired .tau-form-vacancy, .tau-impaired .tau-form-auth, .tau-impaired .tau-form-business {
    padding: 0 5px;
    width: auto; } }

.tau-impaired .tau-tab-title_open + .tau-tab-content {
  height: auto; }

.tau-impaired .tau-tab-content {
  height: 0;
  overflow: hidden; }

.tau-impaired .tau-gtab__titles {
  display: flex; }

.tau-impaired .tau-gtab__title {
  flex: 0 0 50%;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  box-sizing: border-box; }
  .tau-impaired .tau-gtab__title_open {
    color: #fff !important;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); }
  .tau-impaired .tau-gtab__title:nth-child(odd).tau-gtab__title_open {
    background: #000; }
  .tau-impaired .tau-gtab__title:nth-child(even).tau-gtab__title_open {
    background: #000; }

.tau-impaired .tau-gtab__content {
  display: none; }
  .tau-impaired .tau-gtab__content_open {
    display: block !important; }
  .tau-impaired .tau-gtab__content:nth-child(odd).tau-gtab__content_open {
    box-shadow: 0 -1px 0 #000; }
  .tau-impaired .tau-gtab__content:nth-child(even).tau-gtab__content_open {
    box-shadow: 0 -1px 0 #000; }

.tau-impaired .tau-table {
  width: 100%;
  min-width: 550px;
  display: table;
  margin: 40px 0 50px;
  border: 1px solid #000; }
  .tau-impaired .tau-table__header {
    display: flex;
    width: 100%;
    background: #fff;
    text-align: center; }
    .tau-impaired .tau-table__header > div {
      display: table-cell;
      padding: 25px 20px;
      font-weight: bold;
      line-height: 1.5em;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000; }
      .tau-impaired .tau-table__header > div:nth-child(1) {
        width: 6%; }
      .tau-impaired .tau-table__header > div:nth-child(2) {
        width: 35%; }
      .tau-impaired .tau-table__header > div:nth-child(3) {
        width: 29%; }
      .tau-impaired .tau-table__header > div:nth-child(4) {
        width: 30%; }
      .tau-impaired .tau-table__header > div:last-child {
        border-right: none; }
  .tau-impaired .tau-table__body {
    width: 100%;
    display: table; }
  .tau-impaired .tau-table__item {
    display: table-row; }
    .tau-impaired .tau-table__item:last-child > div {
      border-bottom: none; }
    .tau-impaired .tau-table__item > div {
      display: table-cell;
      padding: 25px 20px;
      width: 20%;
      font-size: .875em;
      text-align: center;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000; }
      .tau-impaired .tau-table__item > div:nth-child(1) {
        width: 6%; }
      .tau-impaired .tau-table__item > div:nth-child(2) {
        width: 35%;
        text-align: left; }
      .tau-impaired .tau-table__item > div:nth-child(3) {
        width: 29%; }
      .tau-impaired .tau-table__item > div:nth-child(4) {
        width: 30%; }
      .tau-impaired .tau-table__item > div:last-child {
        font-size: 1.125em;
        font-weight: bold;
        border-right: none; }
  .tau-impaired .tau-table_big {
    width: 100%;
    min-width: 550px;
    display: table;
    margin: 0 0 90px;
    position: relative; }
    .tau-impaired .tau-table_big__wrap {
      margin-top: 50px; }
    .tau-impaired .tau-table_big__header {
      display: flex;
      width: 100%; }
      .tau-impaired .tau-table_big__header > div {
        display: block;
        width: 20%;
        padding: 20px 0;
        font-weight: bold;
        line-height: 1.5em;
        color: #000;
        border: 1px solid #000;
        border-left: none; }
        .tau-impaired .tau-table_big__header > div:first-child {
          border-left: 1px solid #000; }
        .tau-impaired .tau-table_big__header > div span {
          display: block;
          text-align: center; }
        .tau-impaired .tau-table_big__header > div.tau-table_big__header-item_light {
          background: rgba(113, 134, 207, 0.1); }
        .tau-impaired .tau-table_big__header > div.tau-table_big__header-item_blue {
          background: #111111;
          color: #fff;
          border: 1px solid #000;
          border-left: none; }
        .tau-impaired .tau-table_big__header > div.tau-table_big__col_active:before {
          content: '';
          position: absolute;
          top: 0;
          width: 20%;
          height: 100%;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
          transition: all .2s ease; }
      .tau-impaired .tau-table_big__header_additional {
        font-size: 1.25em;
        font-weight: bold;
        color: #fff;
        background: #000;
        text-align: center;
        padding: 15px;
        display: block;
        width: 60%;
        float: right;
        border-radius: 5px 5px 0 0;
        margin-top: 30px; }
      .tau-impaired .tau-table_big__header_simple {
        display: flex; }
        .tau-impaired .tau-table_big__header_simple > div {
          background: #000;
          border-bottom: none;
          display: table-cell;
          padding: 20px 0;
          width: 20%;
          font-weight: bold;
          line-height: 1.5em;
          color: #fff;
          transition: all .2s ease; }
          .tau-impaired .tau-table_big__header_simple > div span {
            display: block;
            text-align: center; }
          .tau-impaired .tau-table_big__header_simple > div:first-child {
            border-radius: 5px 0 0 0; }
          .tau-impaired .tau-table_big__header_simple > div:last-child {
            border-radius: 0 5px 0 0; }
          .tau-impaired .tau-table_big__header_simple > div.tau-table_big__col_active {
            background: #111111; }
            .tau-impaired .tau-table_big__header_simple > div.tau-table_big__col_active:before {
              content: '';
              position: absolute;
              top: 0;
              width: 20%;
              height: 100%;
              box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
              transition: all .2s ease; }
    .tau-impaired .tau-table_big__body {
      width: 100%;
      display: table; }
    .tau-impaired .tau-table_big__item {
      display: table-row; }
      .tau-impaired .tau-table_big__item.tau-table_big__item_blue {
        color: #000; }
      .tau-impaired .tau-table_big__item .tau-icon {
        margin: auto; }
      .tau-impaired .tau-table_big__item.tau-table_big__item_finally > div {
        height: 360px; }
      .tau-impaired .tau-table_big__item > div {
        display: table-cell;
        padding: 20px 25px;
        width: 20%;
        font-size: .875em;
        position: relative;
        vertical-align: top;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000; }
        .tau-impaired .tau-table_big__item > div:nth-child(2) {
          text-align: left; }
        .tau-impaired .tau-table_big__item > div:first-child {
          border-left: 1px solid #000; }
        .tau-impaired .tau-table_big__item > div p {
          margin-bottom: 20px; }
    .tau-impaired .tau-table_big__price {
      font-family: Panton;
      font-weight: bold;
      font-size: 1.125em;
      color: #000;
      text-transform: uppercase;
      margin-bottom: 25px; }
      .tau-impaired .tau-table_big__price-additional {
        font-size: .875em; }
    .tau-impaired .tau-table_big__btn {
      position: absolute;
      bottom: 50px;
      left: 50%;
      margin-left: -87px; }

@media (max-width: 480px) {
  .tau-impaired .tau-table {
    margin: 0; }
    .tau-impaired .tau-table__wrap {
      margin: 20px 20px 40px;
      overflow-y: scroll; }
    .tau-impaired .tau-table__header > div {
      padding: 10px;
      font-weight: normal; }
    .tau-impaired .tau-table__item > div {
      padding: 10px;
      font-size: 1em; }
      .tau-impaired .tau-table__item > div:nth-child(2) {
        text-align: left; }
      .tau-impaired .tau-table__item > div:last-child {
        font-size: 1em;
        font-weight: normal; }
    .tau-impaired .tau-table_big {
      margin: 0; }
      .tau-impaired .tau-table_big__wrap {
        margin: 20px 20px 40px;
        overflow-y: scroll; }
      .tau-impaired .tau-table_big__btn {
        width: auto;
        margin-left: -49px; }
      .tau-impaired .tau-table_big__header_additional {
        display: none; } }

.tau-impaired .tau-search-form {
  overflow: hidden;
  position: absolute;
  right: -15px;
  top: -7px;
  background: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  z-index: 10; }
  .tau-impaired .tau-search-form form {
    width: 0;
    transition: all .3s ease; }
  .tau-impaired .tau-search-form_open {
    width: 400px !important; }
  .tau-impaired .tau-search-form-input input {
    line-height: 2.4em;
    padding: 0 50px 0 15px;
    width: 100%;
    box-sizing: border-box; }
    .tau-impaired .tau-search-form-input input:focus {
      border: none;
      outline: none; }
  .tau-impaired .tau-search-form-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    padding: 7px 15px 0 0; }

.tau-impaired .tau-search__content {
  max-width: 920px;
  margin: 0 auto 70px; }

.tau-impaired .tau-search__list {
  margin-bottom: 40px; }

.tau-impaired .tau-search__item {
  padding: 30px 0;
  border-top: 1px solid #fff; }
  .tau-impaired .tau-search__item:first-child {
    border: none; }
  .tau-impaired .tau-search__item-title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.15em;
    color: #000 !important;
    margin-bottom: 7px;
    display: inline-block; }
  .tau-impaired .tau-search__item-path {
    margin-top: 5px; }
    .tau-impaired .tau-search__item-path a {
      font-size: .875em; }

@media (max-width: 480px) {
  .tau-impaired .tau-search__content {
    margin: 0 0 40px;
    padding: 0 20px; }
  .tau-impaired .tau-search__list {
    margin-bottom: 20px; }
  .tau-impaired .tau-search__item {
    padding: 20px 0; }
    .tau-impaired .tau-search__item:first-child {
      padding-top: 0; }
  .tau-impaired .tau-search-form form {
    height: 0;
    width: 100% !important;
    position: fixed;
    top: 59px;
    left: 0;
    overflow: hidden;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1); }
  .tau-impaired .tau-search-form_open {
    height: auto !important; } }

.tau-impaired .tau-loader, .tau-impaired .tau-loader:before, .tau-impaired .tau-loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation-fill-mode: both;
  animation: loading 1.8s infinite ease-in-out; }

.tau-impaired .tau-loader {
  color: #111111;
  font-size: 10px;
  margin: 0 auto 3em;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s; }
  .tau-impaired .tau-loader:before, .tau-impaired .tau-loader:after {
    content: '';
    position: absolute;
    top: 0; }
  .tau-impaired .tau-loader:before {
    left: -3em;
    animation-delay: -0.32s; }
  .tau-impaired .tau-loader:after {
    left: 3em; }

.tau-impaired .tau-breadcrumbs__item {
  display: inline-block; }
  .tau-impaired .tau-breadcrumbs__item a, .tau-impaired .tau-breadcrumbs__item span {
    font-size: .75em; }
  .tau-impaired .tau-breadcrumbs__item a {
    color: #000; }
    .tau-impaired .tau-breadcrumbs__item a:after {
      content: '/';
      display: inline-block;
      color: #000;
      margin-left: 6px; }
  .tau-impaired .tau-breadcrumbs__item span {
    color: #000;
    margin-left: 6px; }

@media (max-width: 480px) {
  .tau-impaired .tau-breadcrumbs {
    display: none; } }

.tau-impaired .tau-pagination {
  text-align: center; }
  .tau-impaired .tau-pagination__item {
    display: inline-block;
    margin: 0 8px; }
    .tau-impaired .tau-pagination__item a, .tau-impaired .tau-pagination__item span {
      font-size: 1.125em;
      color: #000; }
    .tau-impaired .tau-pagination__item_active a, .tau-impaired .tau-pagination__item_active span {
      color: #000;
      font-weight: bold; }

.tau-impaired .tau-dropdown {
  padding: 13px 25px;
  position: relative;
  box-shadow: inset -1px 0 #fff; }
  .tau-impaired .tau-dropdown__wrap {
    background: #fff;
    z-index: 2;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    width: inherit; }
  .tau-impaired .tau-dropdown_open {
    box-shadow: 0 0 0 1px #fff;
    background: #fff; }
    .tau-impaired .tau-dropdown_open .tau-dropdown__value:after {
      transform: rotate(-135deg);
      top: 23px; }
    .tau-impaired .tau-dropdown_open .tau-dropdown__wrap {
      box-shadow: 0 0 0 1px #fff, 0 2px 4px rgba(0, 0, 0, 0.07); }
    .tau-impaired .tau-dropdown_open .tau-dropdown__list {
      display: block; }
  .tau-impaired .tau-dropdown_calendar .tau-dropdown__value:after {
    content: '';
    height: 18px;
    width: 18px;
    right: 24px;
    position: absolute;
    top: 15px;
    border: none;
    transform: none;
    background-image: url(../images/icons/icon-calendar-im.svg) ;
    background-repeat: no-repeat ;
    background-position: center ;
    background-size: contain ; }
  .tau-impaired .tau-dropdown__value {
    cursor: pointer;
    color: #000; }
    .tau-impaired .tau-dropdown__value span {
      font-size: 1em;
      user-select: none;
      position: relative;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 20px; }
    .tau-impaired .tau-dropdown__value:after {
      content: '';
      height: 6px;
      width: 6px;
      right: 27px;
      position: absolute;
      top: 18px;
      border: 1px solid #000;
      border-left: none;
      border-top: none;
      transform: rotate(45deg); }
    .tau-impaired .tau-dropdown__value_blue {
      color: #000; }
    .tau-impaired .tau-dropdown__value-count {
      color: #000;
      display: inline !important;
      padding-left: 8px; }
    .tau-impaired .tau-dropdown__value-sub {
      position: absolute !important;
      font-size: .875em !important;
      color: #000;
      top: -15px; }
  .tau-impaired .tau-dropdown__list {
    display: none; }
    .tau-impaired .tau-dropdown__list li {
      display: block;
      padding: 9px 13px 9px 40px;
      cursor: pointer;
      position: relative;
      transition: all .2s ease; }
      .tau-impaired .tau-dropdown__list li:first-child {
        margin-top: 6px; }
      .tau-impaired .tau-dropdown__list li:last-child {
        margin-bottom: 6px; }
      .tau-impaired .tau-dropdown__list li:before {
        content: '';
        height: 18px;
        width: 18px;
        border-radius: 50%;
        position: absolute;
        border: 1px solid #000;
        left: 13px;
        top: 11px; }
      .tau-impaired .tau-dropdown__list li:after {
        content: '';
        display: none;
        position: absolute;
        width: 3px;
        height: 6px;
        left: 21px;
        top: 16px;
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg); }
  .tau-impaired .tau-dropdown__item_active:before {
    background: #000; }
  .tau-impaired .tau-dropdown__item_active:after {
    display: block !important; }

@media (max-width: 480px) {
  .tau-impaired .tau-dropdown {
    width: 100%;
    box-shadow: none;
    padding: 0;
    border-bottom: 1px solid #fff; }
    .tau-impaired .tau-dropdown_open .tau-dropdown__wrap {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07); }
    .tau-impaired .tau-dropdown__wrap {
      position: fixed;
      top: 56px;
      padding: 8px; }
    .tau-impaired .tau-dropdown__list li {
      line-height: 1.7em;
      padding: 7px 12px 7px 40px; }
      .tau-impaired .tau-dropdown__list li:last-child {
        margin-bottom: 4px; }
      .tau-impaired .tau-dropdown__list li:before {
        top: 8px; }
      .tau-impaired .tau-dropdown__list li:after {
        top: 13px; }
    .tau-impaired .tau-dropdown__value {
      padding: 15px 0; }
      .tau-impaired .tau-dropdown__value:after {
        display: none; }
      .tau-impaired .tau-dropdown__value span {
        font-size: 1em;
        font-weight: bold;
        font-family: Panton;
        line-height: 1.7em;
        display: inline-flex;
        padding: 0; }
      .tau-impaired .tau-dropdown__value-count {
        margin-left: 5px; } }

.tau-impaired .tau-docs__item {
  padding-left: 25px;
  margin-bottom: 15px; }
  .tau-impaired .tau-docs__item .tau-icon {
    float: left; }
  .tau-impaired .tau-docs__item a {
    margin-left: 15px;
    color: #000; }

.tau-impaired .tau-docs__list-title {
  font-weight: bold;
  margin: 40px 0 20px; }
  .tau-impaired .tau-docs__list-title + ul li {
    padding-left: 0  !important; }

@media (max-width: 480px) {
  .tau-impaired .tau-docs__item {
    padding: 0 20px;
    margin-bottom: 10px; }
    .tau-impaired .tau-docs__item .tau-icon {
      margin-top: 3px; }
    .tau-impaired .tau-docs__item a {
      margin: 0;
      display: block;
      padding-left: 20px;
      font-size: .85em; }
  .tau-impaired .tau-docs__list-title {
    margin: 20px 0;
    padding: 0 20px; } }

.tau-impaired .tau-filter {
  text-align: center; }
  .tau-impaired .tau-filter__grid {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07); }
    .tau-impaired .tau-filter__grid-full {
      width: 100%; }
      .tau-impaired .tau-filter__grid-full .tau-filter__item {
        min-width: auto; }
  .tau-impaired .tau-filter__title {
    display: none;
    font-family: Panton;
    font-size: 1.4em;
    font-weight: bold;
    color: #000;
    padding: 16px 0;
    border-bottom: 1px solid rgba(113, 134, 207, 0.2); }
  .tau-impaired .tau-filter__close {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px; }
  .tau-impaired .tau-filter__return {
    display: none;
    position: absolute;
    top: 7px;
    left: 10px;
    padding: 15px; }
    .tau-impaired .tau-filter__return span {
      height: 12px;
      width: 12px;
      display: block;
      border: 1px solid #41688c;
      border-bottom: none;
      border-right: none;
      transform: rotate(-45deg); }
  .tau-impaired .tau-filter__item {
    flex: 1 0 16%;
    min-width: 215px;
    box-sizing: border-box;
    text-align: left; }
    .tau-impaired .tau-filter__item:last-child .tau-dropdown:not(.tau-dropdown_open) {
      box-shadow: none; }
    .tau-impaired .tau-filter__item .tau-dropdown__clear {
      display: none; }
  .tau-impaired .tau-filter__info {
    margin: 40px 0 25px; }
    .tau-impaired .tau-filter__info > * {
      padding: 0 10px; }
    .tau-impaired .tau-filter__info a {
      color: #000; }
  .tau-impaired .tau-filter__input {
    padding: 13px 25px;
    width: 100%;
    box-sizing: border-box; }
    .tau-impaired .tau-filter__input::placeholder {
      color: #000;
      font-size: 1em; }
  .tau-impaired .tau-filter-button {
    display: none;
    text-align: center; }

@media (max-width: 480px) {
  .tau-impaired .tau-filter {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    display: none; }
    .tau-impaired .tau-filter_open {
      display: block; }
    .tau-impaired .tau-filter__grid {
      box-shadow: none; }
    .tau-impaired .tau-filter__title, .tau-impaired .tau-filter__close {
      display: block; }
    .tau-impaired .tau-filter__item {
      flex: 0 1 100%;
      padding: 0 20px; }
      .tau-impaired .tau-filter__item a {
        display: inline-block !important; }
      .tau-impaired .tau-filter__item:last-child {
        text-align: center;
        margin-top: 10px; }
        .tau-impaired .tau-filter__item:last-child a {
          border-radius: 5px;
          width: 105px;
          margin: 0 5px; }
    .tau-impaired .tau-filter__info {
      display: none; }
    .tau-impaired .tau-filter__input {
      width: auto;
      padding: 15px 0;
      font-size: 1em; }
      .tau-impaired .tau-filter__input::placeholder {
        font-family: Panton;
        font-weight: bold; }
    .tau-impaired .tau-filter-button {
      display: block; } }

.tau-impaired .tau-news__content .tau-pagination {
  margin-bottom: 85px; }

.tau-impaired .tau-news__grid {
  margin: 0 -20px 60px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-impaired .tau-news__item {
  flex: 0 1 25%;
  margin: 25px 0; }
  .tau-impaired .tau-news__item figure {
    padding: 0 20px; }

.tau-impaired .tau-news__photo {
  height: 270px;
  border-radius: 5px;
  background-position: center;
  background-size: cover; }

.tau-impaired .tau-news-info {
  margin-top: 20px; }
  .tau-impaired .tau-news-info__date {
    color: #000;
    margin-bottom: 12px;
    text-transform: lowercase; }
  .tau-impaired .tau-news-info__desc {
    color: #000;
    line-height: 1.6em;
    word-break: break-word; }

.tau-impaired .tau-news__buttons {
  text-align: center; }
  .tau-impaired .tau-news__buttons .tau-btn {
    margin: 0 18px;
    box-sizing: border-box; }

.tau-impaired .tau-new-single__header {
  max-width: 920px;
  margin: 40px auto 0; }

.tau-impaired .tau-new-single__content {
  max-width: 920px;
  margin: 30px auto 50px;
  line-height: 1.9em; }
  .tau-impaired .tau-new-single__content-title {
    font-weight: bold;
    margin-bottom: 30px; }
  .tau-impaired .tau-new-single__content p {
    margin-bottom: 40px;
    line-height: 1.9em; }
  .tau-impaired .tau-new-single__content ul li {
    padding-left: 15px;
    position: relative;
    line-height: 1.9em; }
    .tau-impaired .tau-new-single__content ul li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #000; }
  .tau-impaired .tau-new-single__content img {
    margin: 30px 0 0;
    position: relative;
    border-radius: 5px;
    overflow: hidden; }

.tau-impaired .tau-new-repost {
  margin-bottom: 50px; }

@media (max-width: 480px) {
  .tau-impaired .tau-news__content .tau-pagination {
    margin-bottom: 30px; }
  .tau-impaired .tau-news__item {
    flex: 0 1 100%;
    max-width: 100%;
    margin: 10px 0 20px; }
    .tau-impaired .tau-news__item figure {
      padding: 0 20px; }
  .tau-impaired .tau-news__grid {
    margin: 0 0 40px; }
  .tau-impaired .tau-news-info {
    margin-top: 15px; }
    .tau-impaired .tau-news-info__date {
      margin-bottom: 7px; }
  .tau-impaired .tau-news__buttons {
    text-align: left;
    padding: 0 45px; }
    .tau-impaired .tau-news__buttons .tau-btn {
      margin: 0 0 35px;
      width: 100%; }
  .tau-impaired .tau-news-inner .tau-news__grid {
    margin: 0 0 10px; }
  .tau-impaired .tau-new-single__header {
    padding: 0 20px;
    margin: 0 auto; }
  .tau-impaired .tau-new-single__content {
    padding: 0 20px;
    margin: 0 0 40px; }
    .tau-impaired .tau-new-single__content-title, .tau-impaired .tau-new-single__content p {
      margin-bottom: 20px; }
    .tau-impaired .tau-new-single__content img {
      margin: 40px 0 0; }
    .tau-impaired .tau-new-single__content ul li {
      padding-left: 12px; }
  .tau-impaired .tau-new-repost {
    margin-bottom: 40px; } }

.tau-impaired .tau-management__content {
  max-width: 920px;
  margin: 0 auto 30px; }

.tau-impaired .tau-management__list-title {
  flex-basis: 100% !important;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #000;
  margin-bottom: 18px; }
  .tau-impaired .tau-management__list-title:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 35px;
    width: 5px;
    height: 55px;
    background: #000; }

.tau-impaired .tau-management__item {
  padding: 50px 20px 50px 35px;
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #fff; }
  .tau-impaired .tau-management__item div {
    flex: 0 0 50%; }
  .tau-impaired .tau-management__item:first-child {
    border: none; }

.tau-impaired .tau-management__name {
  font-size: 1.125em;
  font-weight: bold; }

.tau-impaired .tau-management__desc {
  line-height: 1.9em; }

@media (max-width: 480px) {
  .tau-impaired .tau-management__item {
    padding: 0 20px 25px;
    border: none; }
    .tau-impaired .tau-management__item div {
      flex: 0 0 100%; }
  .tau-impaired .tau-management__list-title {
    font-size: 1.15em;
    margin-bottom: 5px;
    line-height: 35px; }
    .tau-impaired .tau-management__list-title:before {
      height: 35px;
      top: 0;
      background: #000; }
  .tau-impaired .tau-management__name {
    font-size: 1.15em; } }

.tau-impaired .tau-expert__content {
  max-width: 920px;
  margin: 0 auto 30px; }

.tau-impaired .tau-expert__list-title {
  flex-basis: 100% !important;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #000;
  margin-bottom: 18px; }
  .tau-impaired .tau-expert__list-title:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 35px;
    width: 5px;
    height: 55px;
    background: #000; }

.tau-impaired .tau-expert__item {
  padding: 50px 20px 50px 35px;
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #fff; }
  .tau-impaired .tau-expert__item div {
    flex: 0 0 50%; }
  .tau-impaired .tau-expert__item:first-child {
    border: none; }

.tau-impaired .tau-expert__name {
  font-size: 1.125em;
  font-weight: bold; }

.tau-impaired .tau-expert__desc {
  line-height: 1.9em; }

@media (max-width: 480px) {
  .tau-impaired .tau-expert__item {
    padding: 0 20px 25px;
    border: none; }
    .tau-impaired .tau-expert__item div {
      flex: 0 0 100%; }
  .tau-impaired .tau-expert__list-title {
    font-size: 1.15em;
    margin-bottom: 5px;
    line-height: 35px; }
    .tau-impaired .tau-expert__list-title:before {
      height: 35px;
      top: 0;
      background: #000; }
  .tau-impaired .tau-expert__name {
    font-size: 1.15em; } }

.tau-impaired .tau-team__content {
  max-width: 920px;
  margin: 0 auto 60px; }

.tau-impaired .tau-team__item {
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #fff; }
  .tau-impaired .tau-team__item:last-child {
    border-bottom: 1px solid #fff; }
  .tau-impaired .tau-team__item > div {
    flex: 0 0 50%; }

.tau-impaired .tau-team__list {
  padding-top: 35px;
  margin-bottom: 60px; }

.tau-impaired .tau-team__title {
  position: relative;
  padding-left: 35px;
  margin: 30px 0;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 55px;
  user-select: none;
  color: #000;
  box-shadow: inset 5px 0 0 #000;
  cursor: pointer;
  transition: color .2s ease; }
  .tau-impaired .tau-team__title:hover {
    color: #000; }
  .tau-impaired .tau-team__title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 3px solid;
    border-left: none;
    border-top: none;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: color .2s ease;
    transform: rotate(45deg); }
  .tau-impaired .tau-team__title.tau-tab-title_open {
    color: #000; }
    .tau-impaired .tau-team__title.tau-tab-title_open:after {
      transform: rotate(-135deg); }

.tau-impaired .tau-team-person__list {
  flex-wrap: wrap; }

.tau-impaired .tau-team-person__item {
  flex: 1 0 50%;
  padding: 40px 0 10px; }
  .tau-impaired .tau-team-person__item figure {
    display: flex; }
  .tau-impaired .tau-team-person__item figcaption {
    padding-left: 15px; }
  .tau-impaired .tau-team-person__item span, .tau-impaired .tau-team-person__item a {
    display: block; }
  .tau-impaired .tau-team-person__item:last-child {
    padding: 40px 0 80px; }

.tau-impaired .tau-team-person__image {
  height: 75px;
  width: 75px;
  min-width: 75px;
  border-radius: 50%;
  overflow: hidden; }
  .tau-impaired .tau-team-person__image img {
    height: 100%;
    width: auto !important; }

.tau-impaired .tau-team-person__name {
  margin-bottom: 8px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.25em;
  color: #000 !important;
  cursor: pointer;
  transition: all .2s ease; }
  .tau-impaired .tau-team-person__name:hover {
    color: #000; }
  .tau-impaired .tau-team-person__name_mobile {
    display: none !important; }

.tau-impaired .tau-team-person__post {
  font-family: Panton;
  font-weight: bold;
  color: #bdbdbd; }

.tau-impaired .tau-team-person-about {
  margin: 0 30px;
  width: 800px; }
  .tau-impaired .tau-team-person-about > div {
    flex: 0 0 50%; }
  .tau-impaired .tau-team-person-about__view {
    text-align: right;
    margin-bottom: 30px; }
  .tau-impaired .tau-team-person-about__info {
    padding: 30px 0 0 0;
    margin-bottom: 30px; }
  .tau-impaired .tau-team-person-about__name {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.5em;
    color: #000;
    padding-left: 15px;
    margin-bottom: 65px;
    box-shadow: inset 5px 0 #000; }
  .tau-impaired .tau-team-person-about__list {
    padding-left: 15px; }
    .tau-impaired .tau-team-person-about__list li {
      margin-top: 30px; }
      .tau-impaired .tau-team-person-about__list li span, .tau-impaired .tau-team-person-about__list li p {
        font-size: 1.125em;
        color: #000;
        display: block; }
        .tau-impaired .tau-team-person-about__list li span:first-child, .tau-impaired .tau-team-person-about__list li p:first-child {
          font-weight: bold; }
  .tau-impaired .tau-team-person-about__image {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block; }
  .tau-impaired .tau-team-person-about__text {
    font-style: italic;
    line-height: 2em;
    color: #000;
    text-align: left;
    padding: 35px 0 0 40px;
    position: relative; }
    .tau-impaired .tau-team-person-about__text:before, .tau-impaired .tau-team-person-about__text:after {
      font-weight: bold;
      font-style: normal;
      font-size: 3em;
      color: #000; }
    .tau-impaired .tau-team-person-about__text:before {
      content: open-quote;
      position: absolute;
      left: -5px;
      top: 32px; }
    .tau-impaired .tau-team-person-about__text:after {
      content: close-quote;
      display: inline-block;
      vertical-align: text-top;
      margin-left: 7px; }

@media (max-width: 480px) {
  .tau-impaired .tau-team__content {
    margin: 0 auto 35px; }
  .tau-impaired .tau-team__list {
    padding-top: 10px;
    margin-bottom: 35px; }
  .tau-impaired .tau-team__item {
    margin: 0 20px; }
  .tau-impaired .tau-team__title {
    line-height: 1.5em;
    font-size: 1.15em;
    padding: 0 20px;
    margin: 25px 0 25px -20px;
    height: 40px;
    display: flex;
    box-shadow: inset 5px 0 0 #000; }
    .tau-impaired .tau-team__title:after {
      height: 5px;
      width: 5px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      right: 3px;
      top: 50%;
      margin-top: -5px; }
    .tau-impaired .tau-team__title span {
      margin: auto 0; }
  .tau-impaired .tau-team-person__item {
    flex: 0 0 100%;
    padding: 20px 0; }
    .tau-impaired .tau-team-person__item figcaption {
      padding-left: 20px;
      width: calc(100% - 100px);
      margin: auto 0; }
    .tau-impaired .tau-team-person__item a {
      margin-bottom: 6px; }
    .tau-impaired .tau-team-person__item:last-child {
      padding: 20px 0 50px; }
  .tau-impaired .tau-team-person__name {
    font-size: 1em;
    line-height: 1.2em;
    margin-bottom: 4px; }
    .tau-impaired .tau-team-person__name_desktop {
      display: none !important; }
    .tau-impaired .tau-team-person__name_mobile {
      display: block !important; }
  .tau-impaired .tau-team-person__post {
    color: #000;
    line-height: 1.2em;
    font-family: Istok Web;
    font-weight: normal; }
  .tau-impaired .tau-team-person-about {
    margin: 0;
    width: auto;
    flex-wrap: wrap; }
    .tau-impaired .tau-team-person-about > div {
      flex: 0 0 100%; }
    .tau-impaired .tau-team-person-about__info {
      margin-bottom: 40px;
      padding: 0; }
    .tau-impaired .tau-team-person-about__name {
      font-size: 1.3em;
      color: #000;
      margin-bottom: 20px; }
    .tau-impaired .tau-team-person-about__list {
      padding: 0; }
      .tau-impaired .tau-team-person-about__list li {
        margin-top: 12px; }
        .tau-impaired .tau-team-person-about__list li span, .tau-impaired .tau-team-person-about__list li p {
          color: #000; }
    .tau-impaired .tau-team-person-about__image {
      display: none; }
    .tau-impaired .tau-team-person-about__text {
      color: #000;
      font-size: .925em;
      padding: 0; }
      .tau-impaired .tau-team-person-about__text:before {
        top: -27px; }
      .tau-impaired .tau-team-person-about__text:after {
        margin: 0;
        position: absolute;
        right: 0;
        bottom: -8px; } }

.tau-impaired .tau-official__content {
  max-width: 920px;
  margin: 0 auto; }

.tau-impaired .tau-official__list {
  padding: 10px 0 25px; }

.tau-impaired .tau-official-tab__list .tau-docs__list {
  padding: 10px 0 0 10px; }

.tau-impaired .tau-official__item {
  margin-bottom: 40px; }
  .tau-impaired .tau-official__item:nth-child(odd) .tau-official__title {
    box-shadow: inset 5px 0 #000; }
  .tau-impaired .tau-official__item:nth-child(even) .tau-official__title {
    box-shadow: inset 5px 0 #000; }

.tau-impaired .tau-official__title {
  font-weight: bold;
  font-size: 1.25em;
  line-height: 55px;
  padding-left: 35px; }
  .tau-impaired .tau-official__title.tau-tab-title {
    cursor: pointer; }
    .tau-impaired .tau-official__title.tau-tab-title:after {
      content: '';
      height: 7px;
      width: 7px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      display: inline-block;
      position: relative;
      margin-left: 12px;
      top: -3px;
      transform: rotate(45deg); }
  .tau-impaired .tau-official__title.tau-tab-title_open:after {
    transform: rotate(-135deg);
    top: -1px; }

.tau-impaired .tau-official-data__item {
  padding: 35px;
  border-bottom: 1px solid #fff;
  line-height: 3em;
  flex-wrap: wrap; }
  .tau-impaired .tau-official-data__item > div {
    flex: 0 0 50%; }

.tau-impaired .tau-official-data__title {
  font-weight: bold;
  font-size: 1.125em; }

.tau-impaired .tau-official-data__desc {
  padding-top: 3px; }
  .tau-impaired .tau-official-data__desc_middle {
    margin: auto; }
  .tau-impaired .tau-official-data__desc a {
    color: #000; }

@media (max-width: 480px) {
  .tau-impaired .tau-official__title {
    line-height: normal;
    height: 35px;
    padding: 0 20px;
    color: #000;
    vertical-align: middle;
    display: table-cell;
    font-size: 1.15em;
    font-family: Panton; }
    .tau-impaired .tau-official__title.tau-tab-title_open {
      color: #000; }
      .tau-impaired .tau-official__title.tau-tab-title_open:after {
        top: 16px !important; }
    .tau-impaired .tau-official__title.tau-tab-title:after {
      height: 4px;
      width: 4px;
      top: 14px;
      position: absolute;
      right: 22px; }
  .tau-impaired .tau-official__item {
    margin-bottom: 25px;
    padding-bottom: 25px;
    position: relative; }
    .tau-impaired .tau-official__item:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      border-bottom: 1px solid #fff; }
    .tau-impaired .tau-official__item:nth-child(odd) .tau-official__title {
      box-shadow: inset 5px 0 #000; }
    .tau-impaired .tau-official__item:first-child {
      padding-bottom: 40px; }
      .tau-impaired .tau-official__item:first-child .tau-official__title {
        box-shadow: none;
        line-height: normal;
        color: #000;
        font-family: Istok Web;
        font-weight: normal; }
  .tau-impaired .tau-official-tab__list .tau-docs__list {
    padding: 15px 0 0; }
  .tau-impaired .tau-official-data__item {
    padding: 20px 20px 0;
    line-height: 1.9em;
    border: none; }
    .tau-impaired .tau-official-data__item > div {
      flex: 0 0 100%; }
  .tau-impaired .tau-official-data__title {
    font-size: 1em; } }

.tau-impaired .tau-gallery__content {
  margin: 0 -20px 90px; }

.tau-impaired .tau-gallery__grid {
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-impaired .tau-gallery__item {
  flex: 0 1 33.3%;
  margin-bottom: 90px;
  padding: 0 20px;
  box-sizing: border-box; }
  .tau-impaired .tau-gallery__item a {
    display: block;
    height: 350px;
    position: relative;
    overflow: hidden; }
    .tau-impaired .tau-gallery__item a:hover .tau-album__desc {
      background: #111111; }
  .tau-impaired .tau-gallery__item-photo {
    flex: 0 1 33.3%;
    margin: 0 0 40px !important;
    padding: 0 20px !important;
    box-sizing: border-box; }
    .tau-impaired .tau-gallery__item-photo img {
      position: relative !important; }

.tau-impaired .tau-gallery__more {
  margin-top: 50px;
  text-align: center; }
  .tau-impaired .tau-gallery__more a {
    color: #000;
    font-weight: bold;
    font-size: 1.125em; }

.tau-impaired .tau-album__desc {
  position: absolute;
  height: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  box-sizing: border-box;
  padding: 10px 15px;
  box-shadow: 0 0 50px #2f438b;
  transition: background .2s ease; }

.tau-impaired .tau-album__photo-count {
  position: absolute;
  font-family: Panton;
  font-size: 1.125em;
  right: 15px;
  top: 10px; }

.tau-impaired .tau-album__title {
  font-family: Panton;
  font-weight: bold;
  font-size: 1.125em;
  margin-bottom: 4px;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.tau-impaired .tau-album__date {
  font-size: .875em;
  text-transform: lowercase; }

@media (max-width: 480px) {
  .tau-impaired .tau-gallery__content {
    margin: 0 0 30px; }
  .tau-impaired .tau-gallery__grid {
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .tau-impaired .tau-gallery__item {
    flex: 0 1 100%;
    margin-bottom: 30px; }
    .tau-impaired .tau-gallery__item a {
      height: auto; }
    .tau-impaired .tau-gallery__item-photo {
      flex: 0 1 100%;
      margin-bottom: 30px; }
  .tau-impaired .tau-gallery__more {
    margin-top: 0; }
  .tau-impaired .tau-album__title {
    font-size: 1em;
    margin-bottom: 2px; }
  .tau-impaired .tau-album__date {
    font-size: .875em; }
  .tau-impaired .tau-album__desc {
    padding: 6px 12px;
    height: 50px; }
  .tau-impaired .tau-album__photo-count {
    font-size: .875em;
    right: 12px;
    top: 6px; } }

.tau-impaired .tau-vacancy__content {
  max-width: 920px;
  margin: 0 auto 60px; }
  .tau-impaired .tau-vacancy__content-title, .tau-impaired .tau-vacancy__content-list {
    padding-left: 35px; }
  .tau-impaired .tau-vacancy__content-title {
    font-size: 1.375em;
    font-weight: bold;
    margin-bottom: 12px; }
  .tau-impaired .tau-vacancy__content-list {
    padding-bottom: 30px; }
    .tau-impaired .tau-vacancy__content-list li {
      line-height: 2.4em; }
      .tau-impaired .tau-vacancy__content-list li:before {
        content: '';
        display: inline-block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 10px;
        background: #000; }
  .tau-impaired .tau-vacancy__content-text {
    padding: 0 0 30px 35px; }

.tau-impaired .tau-vacancy__item {
  position: relative;
  border-top: 1px solid #fff; }
  .tau-impaired .tau-vacancy__item:last-child {
    border-bottom: 1px solid #fff; }
  .tau-impaired .tau-vacancy__item:nth-child(odd) .tau-vacancy__title {
    box-shadow: inset 5px 0 0 #000; }
  .tau-impaired .tau-vacancy__item:nth-child(even) .tau-vacancy__title {
    box-shadow: inset 5px 0 0 #000; }

.tau-impaired .tau-vacancy__list {
  padding-top: 35px;
  margin-bottom: 60px; }

.tau-impaired .tau-vacancy__title {
  position: relative;
  padding-left: 35px;
  margin: 30px 0;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 55px;
  user-select: none;
  color: #000;
  cursor: pointer;
  transition: color .2s ease; }
  .tau-impaired .tau-vacancy__title:hover {
    color: #000; }
  .tau-impaired .tau-vacancy__title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 3px solid;
    border-left: none;
    border-top: none;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: color .2s ease;
    transform: rotate(45deg); }
  .tau-impaired .tau-vacancy__title.tau-tab-title_open {
    color: #000; }
    .tau-impaired .tau-vacancy__title.tau-tab-title_open:after {
      transform: rotate(-135deg); }

.tau-impaired .tau-vacancy__desc {
  margin-bottom: 60px; }

@media (max-width: 480px) {
  .tau-impaired .tau-vacancy__list {
    padding-top: 25px;
    margin-bottom: 50px; }
  .tau-impaired .tau-vacancy__title {
    padding-left: 20px;
    margin: 20px 0;
    line-height: 35px;
    font-size: 1.15em; }
    .tau-impaired .tau-vacancy__title:after {
      height: 4px;
      width: 4px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      top: 14px; }
    .tau-impaired .tau-vacancy__title.tau-tab-title_open:after {
      top: 16px !important; }
  .tau-impaired .tau-vacancy__content-title {
    font-size: 1em;
    margin-bottom: 5px;
    padding: 0 20px; }
  .tau-impaired .tau-vacancy__content-list {
    padding: 0 20px 30px; }
    .tau-impaired .tau-vacancy__content-list li {
      line-height: 2.2em; }
  .tau-impaired .tau-vacancy__content-text {
    padding: 0 20px 30px; }
  .tau-impaired .tau-vacancy__desc {
    margin-bottom: 45px; }
  .tau-impaired .tau-vacancy__buttons {
    text-align: center; }
    .tau-impaired .tau-vacancy__buttons a {
      width: auto; }
  .tau-impaired .tau-vacancy__item {
    border: none; }
    .tau-impaired .tau-vacancy__item:last-child {
      border: none; }
      .tau-impaired .tau-vacancy__item:last-child:after {
        content: '';
        display: block;
        border-top: 1px solid #fff;
        margin: 0 20px; }
    .tau-impaired .tau-vacancy__item:before {
      content: '';
      display: block;
      border-top: 1px solid #fff;
      margin: 0 20px; } }

.tau-impaired .tau-services__content {
  max-width: 920px;
  margin: 0 auto 45px; }

.tau-impaired .tau-services__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-impaired .tau-services__grid a {
    display: block;
    flex: 0 1 33.3%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-impaired .tau-services__grid a:hover > div:after {
      background: #000; }
    .tau-impaired .tau-services__grid a:hover use {
      fill: #000; }

.tau-impaired .tau-services__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-impaired .tau-services__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-impaired .tau-services__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    transition: all .3s ease; }

.tau-impaired .tau-services__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-impaired .tau-services__icon use {
    fill: #000;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-impaired .tau-services__grid {
    margin: 0; }
    .tau-impaired .tau-services__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-impaired .tau-services__item {
    padding: 15px 0 25px; }
    .tau-impaired .tau-services__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-impaired .tau-services__icon {
    margin: 5px 0 10px; } }

.tau-impaired .tau-services__grid {
  padding: 60px 0;
  justify-content: flex-start; }

@media (max-width: 480px) {
  .tau-impaired .tau-services__content {
    padding: 0 20px;
    margin-bottom: 40px; }
  .tau-impaired .tau-services__grid {
    padding: 45px 0; } }

.tau-impaired .tau-methodic__content {
  max-width: 920px;
  margin: 0 auto 50px; }

.tau-impaired .tau-methodic__list {
  margin-bottom: 50px; }
  .tau-impaired .tau-methodic__list:nth-child(odd) .tau-methodic__title {
    box-shadow: inset 5px 0 0 #000; }
  .tau-impaired .tau-methodic__list:nth-child(even) .tau-methodic__title {
    box-shadow: inset 5px 0 0 #000; }

.tau-impaired .tau-methodic__title {
  color: #000;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  line-height: 55px;
  padding-left: 30px; }

.tau-impaired .tau-methodic__item-title {
  font-family: Istok Web;
  font-weight: bold;
  font-size: 1.25em;
  padding: 15px 30px;
  color: #000;
  cursor: pointer; }
  .tau-impaired .tau-methodic__item-title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 2px solid;
    border-left: none;
    border-top: none;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: -3px;
    transform: rotate(45deg); }
  .tau-impaired .tau-methodic__item-title.tau-tab-title_open {
    color: #000; }
    .tau-impaired .tau-methodic__item-title.tau-tab-title_open:after {
      transform: rotate(-135deg);
      top: -1px; }

.tau-impaired .tau-methodic__item .tau-docs__item {
  padding-left: 30px; }

@media (max-width: 480px) {
  .tau-impaired .tau-methodic__content {
    margin: 0 0 30px; }
  .tau-impaired .tau-methodic__list {
    margin-bottom: 20px; }
  .tau-impaired .tau-methodic__title {
    font-size: 1.15em;
    line-height: 35px;
    padding-left: 20px;
    margin-bottom: 5px;
    box-shadow: inset 5px 0 0 #000 !important; }
  .tau-impaired .tau-methodic__item-title {
    font-size: 1em;
    padding: 10px 20px;
    position: relative; }
    .tau-impaired .tau-methodic__item-title:after {
      height: 4px;
      width: 4px;
      position: absolute;
      top: 18px;
      right: 22px; }
    .tau-impaired .tau-methodic__item-title.tau-tab-title_open:after {
      top: 20px; }
  .tau-impaired .tau-methodic__item .tau-docs__item {
    padding-left: 20px; } }

.tau-impaired .tau-mice__content {
  max-width: 920px;
  margin: 0 auto 90px; }

.tau-impaired .tau-mice__title {
  color: #000;
  line-height: 55px;
  padding-left: 30px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.625em;
  box-shadow: inset 5px 0 0 #000; }

.tau-impaired .tau-mice__buttons {
  text-align: center;
  margin-bottom: 50px; }

.tau-impaired .tau-mice-inner .tau-docs__list li {
  padding-left: 0; }

@media (max-width: 480px) {
  .tau-impaired .tau-mice__content {
    margin: 0 0 40px; }
  .tau-impaired .tau-mice__title {
    padding-left: 20px;
    font-size: 1.15em;
    line-height: 35px; }
  .tau-impaired .tau-mice__buttons {
    margin-bottom: 40px; }
    .tau-impaired .tau-mice__buttons a {
      width: auto; }
  .tau-impaired .tau-mice-inner .tau-docs__list li {
    padding-left: 20px; } }

.tau-impaired .tau-events__content .tau-pagination {
  margin-bottom: 45px; }

.tau-impaired .tau-events__grid {
  margin: 0 -20px 40px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-impaired .tau-events__item {
  flex: 0 1 25%;
  margin: 25px 0; }
  .tau-impaired .tau-events__item figure {
    padding: 0 20px; }

.tau-impaired .tau-events__photo {
  height: 215px;
  border-radius: 5px;
  background-position: center;
  background-size: cover; }

.tau-impaired .tau-events__desc {
  max-width: 920px;
  margin: 0 auto 20px;
  line-height: 1.9em;
  text-align: justify; }

.tau-impaired .tau-events-info {
  margin-top: 17px; }
  .tau-impaired .tau-events-info__date {
    color: #fff;
    margin-left: 20px;
    padding: 3px 10px 0;
    background: #000;
    display: inline-block;
    font: bold 1.375em/1.8em Istok Web; }
  .tau-impaired .tau-events-info__title {
    color: #000;
    word-break: break-word;
    font: bold 1.25em/1.4em Panton; }
  .tau-impaired .tau-events-info__place {
    position: relative; }
    .tau-impaired .tau-events-info__place .tau-label {
      position: absolute;
      left: 0;
      top: 1px; }
    .tau-impaired .tau-events-info__place span {
      padding: 0 18px;
      line-height: 1.4em;
      margin-bottom: 12px;
      display: block;
      height: 40px; }

.tau-impaired .tau-events-more {
  display: none;
  text-align: center;
  margin: 35px 0 45px; }
  .tau-impaired .tau-events-more a {
    display: inline-block;
    margin: 7px 0 6px 0;
    color: #000;
    font-weight: 700;
    font-size: 1.125em; }

.tau-impaired .tau-event-single__header {
  max-width: 920px;
  margin: 40px auto; }

.tau-impaired .tau-event-single__content {
  max-width: 920px;
  margin: 0 auto 90px; }

.tau-impaired .tau-event-single__grid {
  flex-wrap: wrap;
  justify-content: flex-start; }

.tau-impaired .tau-event-single__item {
  flex: 0 1 33.3%;
  margin: 0 0 40px; }
  .tau-impaired .tau-event-single__item_w100 {
    flex: 0 1 100%;
    margin: 0 0 40px; }
  .tau-impaired .tau-event-single__item .tau-date {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px; }
  .tau-impaired .tau-event-single__item .tau-age {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0; }
  .tau-impaired .tau-event-single__item .tau-participant {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 2px 0 0; }
  .tau-impaired .tau-event-single__item .tau-label {
    display: inline-block;
    vertical-align: middle;
    margin: -4px 1px 0 0; }

.tau-impaired .tau-event-single__image {
  margin: 0 0 40px;
  max-height: 500px;
  border-radius: 5px;
  overflow: hidden; }

.tau-impaired .tau-event-single__title {
  font-weight: bold;
  margin-bottom: 8px; }

.tau-impaired .tau-event-single__date span {
  color: #000;
  display: inline-block;
  margin-left: 2px; }

.tau-impaired .tau-event-single__direction {
  border-radius: 45px;
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  padding: 2px 40px 3px;
  margin-top: 15px; }

.tau-impaired .tau-event-single__desc, .tau-impaired .tau-event-single__rewards, .tau-impaired .tau-event-single__org {
  margin-bottom: 45px; }

.tau-impaired .tau-event-single__desc p a, .tau-impaired .tau-event-single__desc p a:hover {
  color: #000; }

.tau-impaired .tau-event-single__docs {
  margin-bottom: 40px; }

.tau-impaired .tau-event-single__buttons {
  text-align: center; }

.tau-impaired .tau-event-single .tau-docs__item {
  padding: 0; }

.tau-impaired .tau-event-single .tau-docs__list {
  margin-top: 22px; }

.tau-impaired .tau-event-single__text {
  padding-right: 20px; }
  .tau-impaired .tau-event-single__text > div {
    display: inline-block;
    vertical-align: top; }
  .tau-impaired .tau-event-single__text .tau-email-gray, .tau-impaired .tau-event-single__text .tau-handset {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px; }
  .tau-impaired .tau-event-single__text-info {
    width: 340px;
    padding-right: 20px; }
    .tau-impaired .tau-event-single__text-info div {
      margin-top: 5px; }
  .tau-impaired .tau-event-single__text-data div {
    margin-top: 5px; }

@media (max-width: 480px) {
  .tau-impaired .tau-events__grid {
    margin: 0 0 20px; }
  .tau-impaired .tau-events__item {
    flex: 0 1 100%;
    margin: 25px 0 5px; }
  .tau-impaired .tau-events__content .tau-pagination {
    display: none; }
  .tau-impaired .tau-events__desc {
    padding: 0 20px;
    margin: 0; }
  .tau-impaired .tau-events-info {
    margin-top: 12px; }
    .tau-impaired .tau-events-info__date {
      padding: 1px 10px 0;
      font: bold 1.1em/2.3em Istok Web; }
    .tau-impaired .tau-events-info__place {
      color: #000; }
      .tau-impaired .tau-events-info__place span {
        height: auto;
        margin-bottom: 8px; }
    .tau-impaired .tau-events-info__title {
      font: bold 1em/1.6em Istok Web; }
  .tau-impaired .tau-events-more {
    display: block; }
  .tau-impaired .tau-event-single__header {
    padding: 0 20px;
    margin: 0 auto; }
  .tau-impaired .tau-event-single__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 35px; }
    .tau-impaired .tau-event-single__content > div {
      order: 1; }
  .tau-impaired .tau-event-single__image {
    order: 2 !important;
    margin: 30px 20px 35px; }
  .tau-impaired .tau-event-single__item {
    flex: 0 1 100%;
    margin: 0 0 25px; }
    .tau-impaired .tau-event-single__item_w100 {
      margin: 0 0 25px; }
  .tau-impaired .tau-event-single__desc, .tau-impaired .tau-event-single__rewards, .tau-impaired .tau-event-single__org {
    margin-bottom: 25px; }
  .tau-impaired .tau-event-single__docs {
    margin: 0; }
  .tau-impaired .tau-event-single__buttons {
    width: 100%;
    padding: 0 20px;
    order: 2 !important; }
  .tau-impaired .tau-event-single__grid, .tau-impaired .tau-event-single__desc, .tau-impaired .tau-event-single__rewards, .tau-impaired .tau-event-single__org, .tau-impaired .tau-event-single__docs {
    padding: 0 20px; }
  .tau-impaired .tau-event-single__direction {
    margin-top: 5px; }
  .tau-impaired .tau-event-single .tau-docs__list {
    margin-top: 10px; }
  .tau-impaired .tau-event-single__text-info {
    width: auto;
    padding: 0; } }

.tau-impaired .tau-contacts__content {
  max-width: 920px;
  margin: 0 auto; }
  .tau-impaired .tau-contacts__content .tau-inline-logo {
    display: none; }

.tau-impaired .tau-contacts__info {
  margin-bottom: 55px;
  flex-wrap: wrap; }
  .tau-impaired .tau-contacts__info-item {
    margin-bottom: 35px; }
    .tau-impaired .tau-contacts__info-item:nth-child(odd) .tau-contacts__title {
      box-shadow: inset 5px 0 0 #000; }
    .tau-impaired .tau-contacts__info-item:nth-child(even) .tau-contacts__title {
      box-shadow: inset 5px 0 0 #000; }

.tau-impaired .tau-contacts__item {
  flex: 0 0 50%; }

.tau-impaired .tau-contacts__title {
  font-family: Panton;
  font-weight: bold;
  font-size: 1.625em;
  color: #000;
  line-height: 55px;
  padding-left: 30px; }

.tau-impaired .tau-contacts__value {
  font-size: 1.125em;
  padding-left: 30px; }

@media (max-width: 480px) {
  .tau-impaired .tau-contacts__content {
    margin-bottom: 35px; }
    .tau-impaired .tau-contacts__content .tau-inline-logo {
      display: block; }
  .tau-impaired .tau-contacts__info {
    margin-bottom: 30px; }
    .tau-impaired .tau-contacts__info-item {
      margin-bottom: 25px; }
  .tau-impaired .tau-contacts__title {
    font-size: 1.15em;
    padding: 0 20px;
    line-height: 35px;
    margin-bottom: 5px; }
  .tau-impaired .tau-contacts__value {
    font-size: 1em;
    padding-left: 20px; }
  .tau-impaired .tau-contacts__item {
    flex: 0 0 100%; }
    .tau-impaired .tau-contacts__item:last-child {
      padding: 0 20px; } }

.tau-impaired .tau-business__content {
  max-width: 920px;
  margin: 0 auto 80px; }
  .tau-impaired .tau-business__content p {
    margin-bottom: 40px;
    padding: 0 30px; }

.tau-impaired .tau-business__title {
  padding-left: 30px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  color: #000;
  line-height: 55px;
  margin: 60px 0 20px;
  box-shadow: inset 5px 0 0 #000; }
  .tau-impaired .tau-business__title:first-child {
    margin-top: 0; }

.tau-impaired .tau-business__buttons {
  text-align: center;
  margin-bottom: 90px; }

@media (max-width: 480px) {
  .tau-impaired .tau-business__content {
    margin: 0 0 35px; }
    .tau-impaired .tau-business__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
  .tau-impaired .tau-business__title {
    padding-left: 20px;
    font-size: 1.15em;
    line-height: 35px;
    margin: 40px 0 20px; }
    .tau-impaired .tau-business__title:first-child {
      margin-top: 0; }
  .tau-impaired .tau-business__buttons {
    margin-bottom: 45px; }
    .tau-impaired .tau-business__buttons a {
      width: auto; } }

.tau-impaired .tau-teachers__content {
  max-width: 920px;
  margin: 0 auto 45px; }
  .tau-impaired .tau-teachers__content p {
    margin-bottom: 40px; }
  .tau-impaired .tau-teachers__content li {
    padding-left: 15px;
    position: relative; }
    .tau-impaired .tau-teachers__content li:not(.tau-docs__item):before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #333; }

.tau-impaired .tau-teachers__grid {
  max-width: 920px;
  margin: 25px auto 75px;
  flex-wrap: wrap; }

.tau-impaired .tau-teachers__title {
  color: #000;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  padding: 0 25px;
  line-height: 55px;
  box-shadow: inset 5px 0 0 #000; }

.tau-impaired .tau-teachers__item {
  flex: 0 0 50%; }
  .tau-impaired .tau-teachers__item li {
    padding: 12px 0 12px 25px; }
    .tau-impaired .tau-teachers__item li a {
      color: #000;
      font-family: Panton;
      font-weight: bold;
      font-size: 1.125em; }

.tau-impaired .tau-teachers__buttons {
  text-align: center;
  margin-bottom: 70px; }

.tau-impaired .tau-teachers .tau-partners__grid {
  padding: 60px 0; }
  .tau-impaired .tau-teachers .tau-partners__grid a {
    flex: 0 1 33.3%; }

@media (max-width: 480px) {
  .tau-impaired .tau-teachers__grid {
    margin: 0 0 30px; }
  .tau-impaired .tau-teachers__title {
    font-size: 1.15em;
    line-height: 35px;
    padding: 0 20px;
    margin-bottom: 4px; }
  .tau-impaired .tau-teachers__item {
    flex: 0 0 100%;
    margin: 20px 0; }
    .tau-impaired .tau-teachers__item li {
      padding: 8px 20px; }
      .tau-impaired .tau-teachers__item li a {
        font-family: Istok Web;
        font-size: 1em;
        font-weight: normal; }
  .tau-impaired .tau-teachers__content {
    margin: 0 0 30px; }
    .tau-impaired .tau-teachers__content p {
      padding: 0 20px;
      margin-bottom: 20px; }
    .tau-impaired .tau-teachers__content ul {
      padding: 0 20px; }
      .tau-impaired .tau-teachers__content ul p {
        padding: 0; }
  .tau-impaired .tau-teachers__buttons {
    margin: 35px 0 45px; }
    .tau-impaired .tau-teachers__buttons a {
      width: auto; }
  .tau-impaired .tau-teachers .tau-partners__grid {
    padding: 45px 0; }
    .tau-impaired .tau-teachers .tau-partners__grid a {
      flex: 0 1 100%; } }

.tau-impaired .tau-diagnostics__content {
  max-width: 920px;
  margin: 0 auto 90px; }
  .tau-impaired .tau-diagnostics__content img {
    max-height: 440px;
    width: 100%;
    margin-bottom: 25px; }
  .tau-impaired .tau-diagnostics__content p {
    margin-bottom: 40px; }
  .tau-impaired .tau-diagnostics__content strong {
    margin-bottom: 20px;
    display: inline-block; }
  .tau-impaired .tau-diagnostics__content ul {
    margin-bottom: 40px; }
    .tau-impaired .tau-diagnostics__content ul li {
      padding-left: 15px;
      position: relative; }
      .tau-impaired .tau-diagnostics__content ul li:before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #000; }
  .tau-impaired .tau-diagnostics__content-title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.5em;
    color: #000;
    margin: 10px 0 20px; }

.tau-impaired .tau-diagnostics__table_min {
  margin-bottom: 60px !important; }
  .tau-impaired .tau-diagnostics__table_min-header {
    font-family: Panton;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    background: #000;
    border-radius: 5px 5px 0 0;
    padding: 11px 0 12px 120px; }
  .tau-impaired .tau-diagnostics__table_min-item {
    padding: 20px 25px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #000; }
    .tau-impaired .tau-diagnostics__table_min-item div {
      flex: 0 0 50%;
      font-size: .875em; }
      .tau-impaired .tau-diagnostics__table_min-item div:first-child {
        font-weight: bold;
        font-size: 1em; }
    .tau-impaired .tau-diagnostics__table_min-item:before {
      display: none; }

.tau-impaired .tau-diagnostics__title {
  padding-left: 30px;
  line-height: 55px;
  color: #000;
  box-shadow: inset 5px 0 0 #000;
  margin-bottom: 45px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em; }

.tau-impaired .tau-diagnostics__buttons {
  text-align: center; }

@media (max-width: 480px) {
  .tau-impaired .tau-diagnostics__content {
    margin: 0 0 40px; }
    .tau-impaired .tau-diagnostics__content img {
      border-radius: 5px;
      overflow: hidden;
      margin: 0 20px 25px;
      width: calc(100% - 40px); }
    .tau-impaired .tau-diagnostics__content p, .tau-impaired .tau-diagnostics__content strong {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-impaired .tau-diagnostics__content ul {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-impaired .tau-diagnostics__content-title {
      padding: 0 20px;
      font-size: 1.15em; }
  .tau-impaired .tau-diagnostics__table_min {
    margin-bottom: 0 !important;
    padding: 0 !important; }
    .tau-impaired .tau-diagnostics__table_min-header {
      border-radius: 0;
      padding: 11px 20px 12px;
      text-align: center; }
    .tau-impaired .tau-diagnostics__table_min-item {
      padding: 20px; }
      .tau-impaired .tau-diagnostics__table_min-item div {
        flex: 0 0 100%; }
        .tau-impaired .tau-diagnostics__table_min-item div:first-child {
          margin-bottom: 10px; }
  .tau-impaired .tau-diagnostics__title {
    padding: 0 20px;
    font-size: 1.15em;
    line-height: 30px;
    margin: 40px 0; }
  .tau-impaired .tau-diagnostics__buttons a {
    width: auto; } }

.tau-impaired .tau-structure__content {
  max-width: 920px;
  margin: 0 auto 50px; }
  .tau-impaired .tau-structure__content p {
    margin-bottom: 5px; }
  .tau-impaired .tau-structure__content-list {
    padding-left: 30px; }
    .tau-impaired .tau-structure__content-list-item {
      margin: 7px 0 12px; }

.tau-impaired .tau-structure__item {
  padding: 30px 0 15px;
  border-bottom: 1px solid #fff; }
  .tau-impaired .tau-structure__item:first-child {
    border-top: 1px solid #fff; }

.tau-impaired .tau-structure__title {
  position: relative;
  padding-left: 30px;
  margin: 0 0 15px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.375em;
  line-height: 55px;
  color: #000;
  box-shadow: inset 5px 0 0 #000;
  cursor: pointer; }
  .tau-impaired .tau-structure__title:after {
    content: '';
    height: 7px;
    width: 7px;
    border: 3px solid;
    border-left: none;
    border-top: none;
    position: absolute;
    right: 25px;
    top: 23px;
    transition: color .2s ease;
    transform: rotate(45deg); }
  .tau-impaired .tau-structure__title.tau-tab-title_open {
    color: #000; }
    .tau-impaired .tau-structure__title.tau-tab-title_open:after {
      transform: rotate(-135deg); }

.tau-impaired .tau-structure-tab__title {
  font-family: Istok Web;
  font-weight: normal;
  font-size: 1em;
  color: #000;
  cursor: pointer; }
  .tau-impaired .tau-structure-tab__title:after {
    content: '';
    height: 4px;
    width: 4px;
    border: 2px solid;
    border-left: none;
    border-top: none;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: -3px;
    transform: rotate(45deg); }
  .tau-impaired .tau-structure-tab__title.tau-tab-title_open {
    color: #000; }
    .tau-impaired .tau-structure-tab__title.tau-tab-title_open:after {
      transform: rotate(-135deg);
      top: -1px; }

.tau-impaired .tau-structure-tab__content {
  padding-left: 20px;
  margin-top: 5px; }

.tau-impaired .tau-structure-tab__desc {
  font-size: .875em;
  margin-bottom: 10px; }
  .tau-impaired .tau-structure-tab__desc ul li {
    padding-left: 15px;
    position: relative; }
    .tau-impaired .tau-structure-tab__desc ul li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #000; }

@media (max-width: 480px) {
  .tau-impaired .tau-structure__content {
    margin: 0 0 30px; }
    .tau-impaired .tau-structure__content-list {
      padding: 0; }
  .tau-impaired .tau-structure__item {
    padding: 25px 0 15px;
    margin: 0 20px; }
  .tau-impaired .tau-structure__title {
    font-size: 1.15em;
    line-height: 1.5em;
    padding: 0 20px;
    margin: 0 0 10px -20px;
    box-shadow: inset 5px 0 0 #000; }
    .tau-impaired .tau-structure__title:after {
      height: 5px;
      width: 5px;
      border: 2px solid;
      border-left: none;
      border-top: none;
      right: 3px;
      top: 50%;
      margin-top: -5px; }
  .tau-impaired .tau-structure-tab__desc {
    font-size: 1em; }
  .tau-impaired .tau-structure-tab__title {
    position: relative;
    padding-right: 15px; }
    .tau-impaired .tau-structure-tab__title:after {
      border: 1px solid;
      border-left: none;
      border-top: none;
      position: absolute;
      right: 4px;
      top: 50%;
      margin-top: -5px; }
    .tau-impaired .tau-structure-tab__title.tau-tab-title_open {
      color: #000; }
      .tau-impaired .tau-structure-tab__title.tau-tab-title_open:after {
        transform: rotate(-135deg);
        top: 50%;
        margin-top: -3px; } }

.tau-impaired .tau-students-registry img {
  width: 100%;
  margin-bottom: 60px; }

.tau-impaired .tau-students-registry__content {
  max-width: 920px;
  margin: 0 auto 90px; }
  .tau-impaired .tau-students-registry__content p {
    margin-bottom: 40px; }
  .tau-impaired .tau-students-registry__content ul:not(.tau-students-registry__list-count) {
    margin-bottom: 40px; }
    .tau-impaired .tau-students-registry__content ul:not(.tau-students-registry__list-count) li {
      padding-left: 15px;
      position: relative; }
      .tau-impaired .tau-students-registry__content ul:not(.tau-students-registry__list-count) li:before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #000; }
  .tau-impaired .tau-students-registry__content-title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.5em;
    color: #000;
    margin: 10px 0 20px; }

.tau-impaired .tau-students-registry__title {
  padding-left: 30px;
  line-height: 55px;
  color: #000;
  box-shadow: inset 5px 0 0 #000;
  margin-bottom: 80px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em; }

.tau-impaired .tau-students-registry__list-count {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  counter-reset: item;
  margin-bottom: 80px; }
  .tau-impaired .tau-students-registry__list-count li {
    flex: 0 0 25%;
    height: 110px;
    position: relative; }
    .tau-impaired .tau-students-registry__list-count li:before {
      content: counter(item);
      counter-increment: item;
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      line-height: 110px;
      font-family: Panton;
      font-size: 144px;
      font-weight: bold;
      color: rgba(103, 124, 197, 0.15); }
    .tau-impaired .tau-students-registry__list-count li span {
      font-size: 1.125em;
      line-height: 1.9em;
      color: #000;
      padding-left: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 150px;
      text-align: center; }

.tau-impaired .tau-students-registry__buttons {
  text-align: center; }

@media (max-width: 480px) {
  .tau-impaired .tau-students-registry img {
    border-radius: 5px;
    overflow: hidden;
    margin: 0 20px 30px;
    width: calc(100% - 40px); }
  .tau-impaired .tau-students-registry__content {
    margin: 0 0 40px; }
    .tau-impaired .tau-students-registry__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-impaired .tau-students-registry__content b {
      display: inline-block;
      margin-top: 20px; }
      .tau-impaired .tau-students-registry__content b:first-child {
        margin: 0; }
    .tau-impaired .tau-students-registry__content ul:not(.tau-students-registry__list-count) {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-impaired .tau-students-registry__content-title {
      padding: 0 20px;
      font-size: 1.15em; }
  .tau-impaired .tau-students-registry__list-count {
    margin-bottom: 0;
    padding: 0 20px;
    justify-content: space-between; }
    .tau-impaired .tau-students-registry__list-count li {
      flex: 0 0 45%;
      margin: 30px 0;
      height: 75px; }
      .tau-impaired .tau-students-registry__list-count li:before {
        line-height: 75px;
        font-size: 96px; }
      .tau-impaired .tau-students-registry__list-count li span {
        font-size: .875em;
        line-height: 1.4em;
        text-align: left; }
  .tau-impaired .tau-students-registry__title {
    padding: 0 20px;
    font-size: 1.15em;
    line-height: 30px;
    margin: 30px 0 0; }
  .tau-impaired .tau-students-registry__buttons a {
    width: auto; } }

.tau-impaired .tau-escort img {
  max-height: 800px;
  width: 100%;
  margin-bottom: 80px; }

.tau-impaired .tau-escort__content {
  max-width: 920px;
  margin: 0 auto 40px; }
  .tau-impaired .tau-escort__content p {
    margin-bottom: 40px; }

.tau-impaired .tau-escort__title {
  padding-left: 30px;
  line-height: 55px;
  color: #000;
  margin-bottom: 45px;
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em; }
  .tau-impaired .tau-escort__title:nth-child(odd) {
    box-shadow: inset 5px 0 0 #000; }
  .tau-impaired .tau-escort__title:nth-child(even) {
    box-shadow: inset 5px 0 0 #000; }

.tau-impaired .tau-escort__item {
  border-bottom: 1px solid #000; }
  .tau-impaired .tau-escort__item-title {
    font-size: 1.125em;
    font-weight: bold;
    color: #000;
    padding: 25px 45px 25px 30px;
    position: relative;
    cursor: pointer; }
    .tau-impaired .tau-escort__item-title:after {
      content: '';
      height: 5px;
      width: 5px;
      border: 1px solid;
      border-left: none;
      border-top: none;
      position: absolute;
      right: 25px;
      top: 23px;
      transition: color .2s ease;
      transform: rotate(45deg); }
    .tau-impaired .tau-escort__item-title.tau-tab-title_open {
      color: #000; }
      .tau-impaired .tau-escort__item-title.tau-tab-title_open:after {
        transform: rotate(-135deg); }
    .tau-impaired .tau-escort__item-title:after {
      right: 30px;
      top: 50%;
      margin-top: -5px; }
    .tau-impaired .tau-escort__item-title.tau-tab-title_open:after {
      margin-top: -3px; }
  .tau-impaired .tau-escort__item-content > div {
    margin: 15px 30px 25px; }
  .tau-impaired .tau-escort__item-content p {
    margin-bottom: 0; }

.tau-impaired .tau-gtab {
  margin-bottom: 50px; }
  .tau-impaired .tau-gtab__content:nth-child(odd) .tau-escort__item-title.tau-tab-title_open {
    background: rgba(235, 126, 127, 0.1); }
  .tau-impaired .tau-gtab__content:nth-child(even) .tau-escort__item-title.tau-tab-title_open {
    background: rgba(113, 134, 207, 0.1); }

@media (max-width: 480px) {
  .tau-impaired .tau-escort img {
    border-radius: 5px;
    overflow: hidden;
    margin: 0 20px 25px;
    width: calc(100% - 40px); }
  .tau-impaired .tau-escort__content {
    margin: 0 0 30px; }
    .tau-impaired .tau-escort__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
  .tau-impaired .tau-escort__title {
    padding: 0 20px;
    font-size: 1.15em;
    line-height: 30px;
    margin: 30px 0; }
  .tau-impaired .tau-escort__item-title {
    font-size: 1em;
    padding: 15px 30px 15px 20px;
    line-height: normal; }
    .tau-impaired .tau-escort__item-title:after {
      content: '';
      height: 3px;
      width: 3px;
      border: 1px solid;
      border-left: none;
      border-top: none;
      position: absolute;
      right: 25px;
      top: 23px;
      transition: color .2s ease;
      transform: rotate(45deg); }
    .tau-impaired .tau-escort__item-title.tau-tab-title_open {
      color: #000; }
      .tau-impaired .tau-escort__item-title.tau-tab-title_open:after {
        transform: rotate(-135deg); }
    .tau-impaired .tau-escort__item-title:after {
      right: 20px;
      top: 50%;
      margin-top: -3px; }
    .tau-impaired .tau-escort__item-title.tau-tab-title_open:after {
      margin-top: -1px; }
  .tau-impaired .tau-escort__item-content > div {
    margin: 10px 20px; }
  .tau-impaired .tau-escort__item-content p {
    margin: 0;
    padding: 0; }
  .tau-impaired .tau-gtab {
    margin-bottom: 30px; } }

.tau-impaired .tau-activities__content {
  max-width: 920px;
  margin: 0 auto 90px; }
  .tau-impaired .tau-activities__content img {
    max-height: 440px;
    width: 100%;
    margin-bottom: 25px; }
  .tau-impaired .tau-activities__content p {
    margin-bottom: 40px; }
  .tau-impaired .tau-activities__content ul {
    margin-bottom: 40px; }
    .tau-impaired .tau-activities__content ul li:not(.tau-docs__item) {
      padding-left: 15px;
      position: relative; }
      .tau-impaired .tau-activities__content ul li:not(.tau-docs__item):before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #000; }

.tau-impaired .tau-activities__title {
  font-family: Panton;
  font-weight: bold;
  font-size: 1.5em;
  color: #000;
  margin: 10px 0 20px; }

@media (max-width: 480px) {
  .tau-impaired .tau-activities__content {
    margin: 0 0 40px; }
    .tau-impaired .tau-activities__content img {
      border-radius: 5px;
      overflow: hidden;
      margin: 0 20px 25px;
      width: calc(100% - 40px); }
    .tau-impaired .tau-activities__content p {
      margin-bottom: 20px;
      padding: 0 20px; }
    .tau-impaired .tau-activities__content ul {
      margin-bottom: 20px;
      padding: 0 20px; }
      .tau-impaired .tau-activities__content ul li p {
        padding: 0; }
  .tau-impaired .tau-activities__title {
    padding: 0 20px;
    font-size: 1.15em; } }

.tau-impaired .tau-brand__content {
  margin: 0 0 70px; }

.tau-impaired .tau-brand__buttons {
  text-align: center;
  margin-bottom: 60px; }

.tau-impaired .tau-carousel_brand {
  margin-bottom: 0; }
  .tau-impaired .tau-carousel_brand .tau-carousel__nav-next, .tau-impaired .tau-carousel_brand .tau-carousel__nav-prev {
    bottom: 0;
    background: #fff; }
  .tau-impaired .tau-carousel_brand__item {
    box-sizing: border-box;
    height: 410px;
    display: flex;
    position: relative; }
    .tau-impaired .tau-carousel_brand__item-title {
      font-size: 1em;
      font-family: Panton;
      font-weight: bold;
      color: #000;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
    .tau-impaired .tau-carousel_brand__item img {
      width: calc(100% - 40px);
      margin: auto 20px; }
  .tau-impaired .tau-carousel_brand .slick-center .tau-carousel_brand__item img {
    margin: 0 20px 25px; }

@media (max-width: 480px) {
  .tau-impaired .tau-brand__content {
    margin: 40px 0; }
  .tau-impaired .tau-brand__buttons {
    margin-bottom: 40px; }
    .tau-impaired .tau-brand__buttons a {
      width: auto; }
  .tau-impaired .tau-carousel_brand .tau-carousel__nav-next, .tau-impaired .tau-carousel_brand .tau-carousel__nav-prev {
    display: flex !important; }
  .tau-impaired .tau-carousel_brand__item img {
    width: calc(100% - 20px);
    margin: 0 10px 25px !important; } }

.tau-impaired .tau-slider__item {
  position: relative;
  display: block;
  overflow: hidden;
  max-height: 540px; }
  .tau-impaired .tau-slider__item figcaption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    max-width: 420px;
    display: flex;
    background: #000; }

.tau-impaired .tau-slider-info {
  margin: auto 40px;
  color: #fff; }
  .tau-impaired .tau-slider-info__date {
    font-size: 1.75em;
    text-transform: lowercase;
    display: none; }
    .tau-impaired .tau-slider-info__date:after {
      content: '';
      display: block;
      margin: 25px 0;
      width: 115px;
      border-top: 1px solid #fff; }
  .tau-impaired .tau-slider-info__title {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.7em;
    line-height: 1.4em;
    word-break: break-word; }
  .tau-impaired .tau-slider-info__desc {
    font-size: 1.5em;
    margin: 20px 0 0;
    line-height: 1.5em; }
  .tau-impaired .tau-slider-info__link {
    margin: 45px 0 0; }

.tau-impaired .tau-slider__nav-prev {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 1000; }
  .tau-impaired .tau-slider__nav-prev span {
    display: block;
    height: 18px;
    width: 18px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: rotate(-45deg);
    margin: auto 0 auto 16px; }

.tau-impaired .tau-slider__nav-next {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 1000; }
  .tau-impaired .tau-slider__nav-next span {
    display: block;
    height: 18px;
    width: 18px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: rotate(135deg);
    margin: auto 16px auto 0; }

.tau-impaired .tau-slider__dots {
  position: absolute;
  bottom: 25px;
  z-index: 1000;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0; }
  .tau-impaired .tau-slider__dots li {
    display: inline-block; }
    .tau-impaired .tau-slider__dots li button {
      background: rgba(255, 255, 255, 0.3);
      font-size: 0;
      height: 3px;
      width: 26px;
      margin: 0 8px;
      overflow: hidden; }
    .tau-impaired .tau-slider__dots li.slick-active button {
      background: #fff; }

.tau-impaired .tau-slider-about {
  padding-bottom: 85px; }
  .tau-impaired .tau-slider-about .tau-slider__nav-prev, .tau-impaired .tau-slider-about .tau-slider__nav-next {
    bottom: 80px; }
  .tau-impaired .tau-slider-about__dots {
    margin-top: 80px;
    text-align: center;
    font-size: 0; }
    .tau-impaired .tau-slider-about__dots li {
      display: inline-block; }
      .tau-impaired .tau-slider-about__dots li button {
        background: rgba(82, 95, 139, 0.3);
        font-size: 0;
        height: 3px;
        width: 26px;
        margin: 0 8px;
        overflow: hidden; }
      .tau-impaired .tau-slider-about__dots li.slick-active button {
        background: #000; }

.tau-impaired .tau-carousel {
  margin-bottom: 90px; }
  .tau-impaired .tau-carousel__item {
    height: 196px;
    box-sizing: border-box; }
  .tau-impaired .tau-carousel__nav-prev {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 65px;
    width: 40px;
    z-index: 1000; }
    .tau-impaired .tau-carousel__nav-prev span {
      display: block;
      height: 18px;
      width: 18px;
      transform: rotate(-45deg);
      margin: auto 0 auto 16px; }
  .tau-impaired .tau-carousel__nav-next {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 65px;
    width: 40px;
    z-index: 1000; }
    .tau-impaired .tau-carousel__nav-next span {
      display: block;
      height: 18px;
      width: 18px;
      transform: rotate(135deg);
      margin: auto 16px auto 0; }
  .tau-impaired .tau-carousel__nav_white span {
    border-top: 3px solid #fff;
    border-left: 3px solid #fff; }
  .tau-impaired .tau-carousel__nav_blue span {
    border-top: 3px solid #000;
    border-left: 3px solid #000; }
  .tau-impaired .tau-carousel__dots {
    margin-top: 55px;
    text-align: center;
    font-size: 0; }
    .tau-impaired .tau-carousel__dots li {
      display: inline-block; }
      .tau-impaired .tau-carousel__dots li button {
        background: #000;
        font-size: 0;
        height: 3px;
        width: 26px;
        margin: 0 8px;
        overflow: hidden; }
      .tau-impaired .tau-carousel__dots li.slick-active button {
        background: #000; }
  .tau-impaired .tau-carousel_about {
    padding: 30px 0 75px;
    margin-bottom: 60px; }
    .tau-impaired .tau-carousel_about .tau-slick-carousel {
      margin-top: 15px; }
    .tau-impaired .tau-carousel_about .slick-list {
      margin: 0 -12px; }
    .tau-impaired .tau-carousel_about .slick-slide {
      margin: 0 12px; }
  .tau-impaired .tau-carousel_partners .tau-carousel__item {
    padding: 0 20px;
    height: auto; }
    .tau-impaired .tau-carousel_partners .tau-carousel__item img {
      width: 100%; }
  .tau-impaired .tau-carousel_partners .tau-slick-carousel {
    padding: 0 40px;
    margin: 45px 0 0; }

@media (max-width: 480px) {
  .tau-impaired .tau-slider__item figcaption {
    position: relative;
    bottom: 0;
    left: 0;
    height: 500px;
    box-sizing: border-box;
    max-width: none;
    width: 100%;
    background: transparent; }
  .tau-impaired .tau-slider__item img {
    display: none; }
  .tau-impaired .tau-slider-info {
    margin: auto 20px; }
    .tau-impaired .tau-slider-info__title {
      font-size: 1.75em; }
    .tau-impaired .tau-slider-info__desc {
      font-size: 1.375em; }
    .tau-impaired .tau-slider-info__date {
      font-size: 1.375em; }
  .tau-impaired .tau-slider__nav-next, .tau-impaired .tau-slider__nav-prev {
    display: none !important; }
  .tau-impaired .tau-slider__dots {
    bottom: 20px; }
  .tau-impaired .tau-slider-about {
    display: none; }
  .tau-impaired .tau-carousel__nav-prev, .tau-impaired .tau-carousel__nav-next {
    display: none !important; }
  .tau-impaired .tau-carousel__item {
    padding: 0 20px;
    height: auto; }
  .tau-impaired .tau-carousel__dots {
    margin-top: 45px;
    width: 100%; }
    .tau-impaired .tau-carousel__dots li {
      padding-top: 4px; }
  .tau-impaired .tau-carousel_about {
    margin: 0;
    padding: 40px 0 45px; }
    .tau-impaired .tau-carousel_about .slick-list, .tau-impaired .tau-carousel_about .slick-slide {
      margin: 0; }
    .tau-impaired .tau-carousel_about .tau-slick-carousel {
      margin-top: 40px; }
  .tau-impaired .tau-carousel_partners {
    margin: 40px 0 45px; }
    .tau-impaired .tau-carousel_partners .tau-slick-carousel {
      margin-top: 40px; } }

.tau-impaired .tau-about__content {
  max-width: 920px;
  margin: 0 auto 70px; }

.tau-impaired .tau-about__image {
  margin: 30px 0 65px;
  position: relative;
  border-radius: 5px;
  overflow: hidden; }
  .tau-impaired .tau-about__image-desc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    color: #fff;
    box-shadow: inset 0 -80px 60px -50px rgba(47, 67, 139, 0.6);
    font-family: Panton;
    font-weight: bold;
    font-size: 3.5em; }

.tau-impaired .tau-about-inner__content {
  max-width: 920px;
  margin: 50px auto 75px; }
  .tau-impaired .tau-about-inner__content p {
    margin-bottom: 40px; }
  .tau-impaired .tau-about-inner__content li {
    padding-left: 15px;
    position: relative; }
    .tau-impaired .tau-about-inner__content li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #000; }

.tau-impaired .tau-about__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-impaired .tau-about__grid a {
    display: block;
    flex: 0 1 33.3%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-impaired .tau-about__grid a:hover > div:after {
      background: #000; }
    .tau-impaired .tau-about__grid a:hover use {
      fill: #000; }

.tau-impaired .tau-about__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-impaired .tau-about__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-impaired .tau-about__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    transition: all .3s ease; }

.tau-impaired .tau-about__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-impaired .tau-about__icon use {
    fill: #000;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-impaired .tau-about__grid {
    margin: 0; }
    .tau-impaired .tau-about__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-impaired .tau-about__item {
    padding: 15px 0 25px; }
    .tau-impaired .tau-about__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-impaired .tau-about__icon {
    margin: 5px 0 10px; } }

.tau-impaired .tau-about__grid {
  padding: 60px 0 75px;
  justify-content: flex-start; }

.tau-impaired .tau-color-blocks {
  line-height: 1.875em;
  margin-top: 35px; }
  .tau-impaired .tau-color-blocks li {
    display: flex;
    padding-left: 32px;
    height: 55px;
    margin-top: 15px; }
    .tau-impaired .tau-color-blocks li p {
      margin: auto 0; }
    .tau-impaired .tau-color-blocks li:nth-child(odd) {
      box-shadow: inset 6px 0 0 -1px #000; }
    .tau-impaired .tau-color-blocks li:nth-child(even) {
      box-shadow: inset 6px 0 0 -1px #000; }

@media (max-width: 480px) {
  .tau-impaired .tau-about__content {
    margin: 0 auto 20px; }
    .tau-impaired .tau-about__content > p {
      display: none; }
  .tau-impaired .tau-about-inner .tau-container {
    display: flex;
    flex-wrap: wrap; }
    .tau-impaired .tau-about-inner .tau-container > * {
      flex: 0 0 100%; }
    .tau-impaired .tau-about-inner .tau-container .tau-title-h1 {
      order: 2; }
    .tau-impaired .tau-about-inner .tau-container .tau-title-h5 {
      display: none; }
    .tau-impaired .tau-about-inner .tau-container .tau-about__image {
      order: 1; }
    .tau-impaired .tau-about-inner .tau-container .tau-about-inner__content {
      order: 4; }
  .tau-impaired .tau-about-inner .tau-inline-logo {
    display: none; }
  .tau-impaired .tau-about-inner__content {
    margin: 0 auto 20px; }
    .tau-impaired .tau-about-inner__content p {
      padding: 0 20px;
      margin-bottom: 20px; }
    .tau-impaired .tau-about-inner__content ul {
      padding: 0 20px; }
      .tau-impaired .tau-about-inner__content ul p {
        padding: 0; }
  .tau-impaired .tau-about__image {
    margin: 0;
    border-radius: 0;
    height: 440px; }
    .tau-impaired .tau-about__image img {
      height: 100%;
      width: auto; }
    .tau-impaired .tau-about__image-desc {
      box-shadow: none;
      padding: 0 20px;
      font-size: 2em;
      z-index: 1;
      top: 0;
      display: flex; }
      .tau-impaired .tau-about__image-desc span {
        margin: auto; }
    .tau-impaired .tau-about__image:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: .7; }
  .tau-impaired .tau-color-blocks {
    line-height: 1.875em;
    margin-top: 15px; }
    .tau-impaired .tau-color-blocks li {
      padding: 12px 12px 12px 19px;
      height: auto;
      margin-top: 0; } }

.tau-impaired .tau-students__content {
  padding: 35px 0 55px; }

.tau-impaired .tau-students__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-impaired .tau-students__grid a {
    display: block;
    flex: 0 1 25%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-impaired .tau-students__grid a:hover > div:after {
      background: #000; }
    .tau-impaired .tau-students__grid a:hover use {
      fill: #000; }

.tau-impaired .tau-students__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-impaired .tau-students__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-impaired .tau-students__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    transition: all .3s ease; }

.tau-impaired .tau-students__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-impaired .tau-students__icon use {
    fill: #000;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-impaired .tau-students__grid {
    margin: 0; }
    .tau-impaired .tau-students__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-impaired .tau-students__item {
    padding: 15px 0 25px; }
    .tau-impaired .tau-students__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-impaired .tau-students__icon {
    margin: 5px 0 10px; } }

.tau-impaired .tau-students-inner__content {
  max-width: 920px;
  margin: 0 auto 45px; }

.tau-impaired .tau-students-inner .tau-students__grid {
  padding: 60px 0;
  justify-content: flex-start; }
  .tau-impaired .tau-students-inner .tau-students__grid a {
    flex: 0 1 33.3%; }

@media (max-width: 480px) {
  .tau-impaired .tau-students-inner__content {
    padding: 0 20px;
    margin-bottom: 40px; }
  .tau-impaired .tau-students-inner .tau-students__grid {
    padding: 45px 0; }
    .tau-impaired .tau-students-inner .tau-students__grid a {
      flex: 0 1 100%; } }

.tau-impaired .tau-partners__content {
  padding: 35px 0 75px; }

.tau-impaired .tau-partners__grid {
  justify-content: center; }

.tau-impaired .tau-partners__grid {
  flex-wrap: wrap;
  margin: 0 -20px; }
  .tau-impaired .tau-partners__grid a {
    display: block;
    flex: 0 1 25%;
    padding: 25px 20px;
    box-sizing: border-box; }
    .tau-impaired .tau-partners__grid a:hover > div:after {
      background: #000; }
    .tau-impaired .tau-partners__grid a:hover use {
      fill: #000; }

.tau-impaired .tau-partners__item {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 30px 0 25px;
  position: relative;
  overflow: hidden; }
  .tau-impaired .tau-partners__item span {
    font-family: Panton;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    display: block;
    height: 55px; }
  .tau-impaired .tau-partners__item:after {
    content: '';
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    transition: all .3s ease; }

.tau-impaired .tau-partners__icon {
  height: 55px;
  margin: 10px 0 25px; }
  .tau-impaired .tau-partners__icon use {
    fill: #000;
    transition: all .2s ease; }

@media (max-width: 480px) {
  .tau-impaired .tau-partners__grid {
    margin: 0; }
    .tau-impaired .tau-partners__grid a {
      flex: 0 1 100%;
      padding: 15px 20px; }
  .tau-impaired .tau-partners__item {
    padding: 15px 0 25px; }
    .tau-impaired .tau-partners__item span {
      font-family: Panton;
      font-weight: normal;
      font-size: 1.15em;
      line-height: 1.4em;
      display: inline-flex;
      align-items: flex-end;
      height: 40px; }
  .tau-impaired .tau-partners__icon {
    margin: 5px 0 10px; } }

@media (max-width: 480px) {
  .tau-impaired .tau-partners__content {
    padding: 35px 0 45px; } }

.tau-impaired .tau-join__content {
  max-width: 920px;
  margin: 45px auto;
  text-align: center; }

.tau-impaired .tau-join__desc {
  margin: 30px 0 40px;
  line-height: 1.75em; }

.tau-impaired .tau-join__buttons {
  margin-bottom: 35px; }

@media (max-width: 480px) {
  .tau-impaired .tau-join__content {
    margin: auto;
    padding: 30px 20px 40px; }
    .tau-impaired .tau-join__content .tau-title-h3 {
      color: #fff; }
    .tau-impaired .tau-join__content .tau-inline-logo {
      display: none; }
  .tau-impaired .tau-join__desc {
    margin: 15px 0 35px; }
  .tau-impaired .tau-join__buttons {
    margin-bottom: 0;
    padding: 0 25px; } }

.tau-impaired .tau-inline-logo_blue, .tau-impaired .tau-inline-logo_red, .tau-impaired .tau-news__photo, .tau-impaired .tau-carousel, .tau-impaired .tau-team-person__image, .tau-impaired .tau-slider-about {
  display: none !important; }

.tau-impaired .tau-news-info__date {
  font-weight: bold; }

.tau-impaired .tau-album__desc {
  box-shadow: 0 0 50px #000; }

.tau-impaired .tau-events__photo {
  background-color: rgba(0, 0, 0, 0.1);
  background-image: none !important; }

.tau-impaired .tau-calendar-day.in-range, .tau-impaired .tau-calendar-day.in-range:hover, .tau-impaired .tau-calendar-day.start-date:after, .tau-impaired .tau-calendar-day.end-date:after {
  background: rgba(0, 0, 0, 0.2) !important; }

.tau-impaired .tau-calendar-day.active {
  background: #fff !important; }
  .tau-impaired .tau-calendar-day.active.in-range {
    background: transparent !important; }

.tau-impaired .tau-escort__item-title.tau-tab-title_open {
  background: transparent !important; }

.tau-impaired .tau-table_big__header > div.tau-table_big__header-item_light {
  background: transparent !important; }

.tau-impaired .tau-students-registry__list-count li:before {
  color: rgba(0, 0, 0, 0.1); }

.tau-impaired .tau-team-person__post {
  color: #000 !important; }

@media (max-width: 480px) {
  .tau-impaired .tau-join__desc {
    color: #fff; }
  .tau-impaired .tau-header__menu-item_active > a {
    box-shadow: none !important; } }

.tau-impaired #tau-slider, .tau-impaired img {
  display: none !important; }
