@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(235, 126, 127, 0.3); }
	100% {
		box-shadow: 0 0 0 20px rgba(235, 126, 127, 0); } }

.#{$prefix}-impaired {
	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3); }
		100% {
			box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); } } }

@keyframes show-modal {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes show-modal-wrap {
	0% {
		top: -100px;
		opacity: 0; }
	100% {
		top: 0;
		opacity: 1; } }

@keyframes hide-modal {
	0% {
		opacity: 1; }
	100% {
		opacity: 0; } }

@keyframes hide-modal-wrap {
	0% {
		top: 0;
		opacity: 1; }
	100% {
		top: -100px;
		opacity: 0; } }


@keyframes loading {
	0%,
	80%,
	100% {
		box-shadow: 0 1.5em 0 -1.3em; }
	40% {
		box-shadow: 0 1.5em 0 0; } }
