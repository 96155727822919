&-expert {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 30px; }
	&__list {
		&-title {
			flex-basis: 100% !important;
			font: {
				family: Panton;
				weight: bold;
				size: 1.25em; }
			color: $font_blue;
			margin-bottom: 18px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 35px;
				width: 5px;
				height: 55px;
				background: $light_blue; } } }
	&__item {
		padding: 50px 20px 50px 35px;
		flex-wrap: wrap;
		position: relative;
		border-top: 1px solid $border_gray;
		div {
			flex: 0 0 50%; }
		&:first-child {
			border: none; } }
	&__name {
		font-size: 1.125em;
		font-weight: bold; }
	&__desc {
		line-height: 1.9em; } }

@include media($mobile) {
	&-expert {
		&__item {
			padding: 0 20px 25px;
			border: none;
			div {
				flex: 0 0 100%; } }
		&__list {
			&-title {
				font-size: 1.15em;
				margin-bottom: 5px;
				line-height: 35px;
				&:before {
					height: 35px;
					top: 0;
					background: $light_blue; } } }
		&__name {
			font-size: 1.15em; } } }
