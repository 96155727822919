&-team {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 60px; }
	&__item {
		flex-wrap: wrap;
		position: relative;
		border-top: 1px solid $border_gray;
		&:last-child {
			border-bottom: 1px solid $border_gray; }
		& > div {
			flex: 0 0 50%; } }
	&__list {
		padding-top: 35px;
		margin-bottom: 60px; }
	&__title {
		position: relative;
		padding-left: 35px;
		margin: 30px 0;
		font: {
			family: Panton;
			weight: bold;
			size: 1.5em; }
		line-height: 55px;
		user-select: none;
		color: $font_blue;
		box-shadow: inset 5px 0 0 $light_red;
		cursor: pointer;
		transition: color .2s ease;
		&:hover {
			color: $light_blue; }
		@include str-dropdown-tab(7, 3); }

	&-person {
		&__list {
			flex-wrap: wrap; }
		&__item {
			flex: 1 0 50%;
			padding: 40px 0 10px;
			figure {
				display: flex; }
			figcaption {
				padding-left: 15px; }
			span, a {
				display: block; }
			&:last-child {
				padding: 40px 0 80px; } }
		&__image {
			height: 75px;
			width: 75px;
			min-width: 75px;
			border-radius: 50%;
			overflow: hidden;
			img {
				height: 100%;
				width: auto !important; } }
		&__name {
			margin-bottom: 8px;
			font: {
				family: Panton;
				weight: bold;
				size: 1.25em; }
			color: $font_blue !important;
			cursor: pointer;
			transition: all .2s ease;
			&:hover {
				color: $light_blue; }
			&_mobile {
				display: none !important; } }
		&__post {
			font: {
				family: Panton;
				weight: bold; }
			color: #bdbdbd; }
		&-about {
			margin: 0 30px;
			width: 800px;
			& > div {
				flex: 0 0 50%; }
			&__view {
				text-align: right;
				margin-bottom: 30px; }
			&__info {
				padding: 30px 0 0 0;
				margin-bottom: 30px; }
			&__name {
				font: {
					family: Panton;
					weight: bold;
					size: 1.5em; }
				color: $font_grayer;
				padding-left: 15px;
				margin-bottom: 65px;
				box-shadow: inset 5px 0 $light_blue; }
			&__list {
				padding-left: 15px;
				li {
					margin-top: 30px;
					span, p {
						font-size: 1.125em;
						color: $font_grayer;
						display: block;
						&:first-child {
							font-weight: bold; } } } }
			&__image {
				height: 150px;
				width: 150px;
				border-radius: 50%;
				overflow: hidden;
				display: inline-block; }
			&__text {
				font-style: italic;
				line-height: 2em;
				color: $gray;
				text-align: left;
				padding: 35px 0 0 40px;
				position: relative;
				&:before, &:after {
					font: {
						weight: bold;
						style: normal;
						size: 3em; }
					color: $font_light_blue; }
				&:before {
					content: open-quote;
					position: absolute;
					left: -5px;
					top: 32px; }
				&:after {
					content: close-quote;
					display: inline-block;
					vertical-align: text-top;
					margin-left: 7px; } } } } }

@include media($mobile) {
	&-team {
		&__content {
			margin: 0 auto 35px; }
		&__list {
			padding-top: 10px;
			margin-bottom: 35px; }
		&__item {
			margin: 0 20px; }
		&__title {
			line-height: 1.5em;
			font-size: 1.15em;
			padding: 0 20px;
			margin: 25px 0 25px -20px;
			height: 40px;
			display: flex;
			box-shadow: inset 5px 0 0 $light_blue;
			&:after {
				height: 5px;
				width: 5px;
				border: 2px solid;
				border-left: none;
				border-top: none;
				right: 3px;
				top: 50%;
				margin-top: -5px; }
			span {
				margin: auto 0; } }
		&-person {
			&__item {
				flex: 0 0 100%;
				padding: 20px 0;
				figcaption {
					padding-left: 20px;
					width: calc(100% - 100px);
					margin: auto 0; }
				a {
					margin-bottom: 6px; }
				&:last-child {
					padding: 20px 0 50px; } }
			&__name {
				font-size: 1em;
				line-height: 1.2em;
				margin-bottom: 4px;
				&_desktop {
					display: none !important; }
				&_mobile {
					display: block !important; } }
			&__post {
				color: $font_dark_gray;
				line-height: 1.2em;
				font: {
					family: Istok Web;
					weight: normal; } }
			&-about {
				margin: 0;
				width: auto;
				flex-wrap: wrap;
				& > div {
					flex: 0 0 100%; }
				&__info {
					margin-bottom: 40px;
					padding: 0; }
				&__name {
					font-size: 1.3em;
					color: $font_dark_gray;
					margin-bottom: 20px; }
				&__list {
					padding: 0;
					li {
						margin-top: 12px;
						span, p {
							color: $font_dark_gray; } } }
				&__image {
					display: none; }
				&__text {
					color: $font_gray;
					font-size: .925em;
					padding: 0;
					&:before {
						top: -27px; }
					&:after {
						margin: 0;
						position: absolute;
						right: 0;
						bottom: -8px; } } } } } }
