&-news {
	&__content {
		.#{$prefix}-pagination {
			margin-bottom: 85px; } }
	&__grid {
		margin: 0 -20px 60px;
		flex-wrap: wrap;
		justify-content: flex-start; }
	&__item {
		flex: 0 1 25%;
		margin: 25px 0;
		figure {
			padding: 0 20px; } }
	&__photo {
		height: 270px;
		border-radius: 5px;
		background-position: center;
		background-size: cover; }
	&-info {
		margin-top: 20px;
		&__date {
			color: $font_grayer;
			margin-bottom: 12px;
			text-transform: lowercase; }
		&__desc {
			color: $font_blue;
			line-height: 1.6em;
			word-break: break-word; } }
	&__buttons {
		text-align: center;
		.#{$prefix}-btn {
			margin: 0 18px;
			box-sizing: border-box; } } }

&-new {
	&-single {
		&__header {
			max-width: $mid_content_width;
			margin: 40px auto 0; }
		&__content {
			max-width: $mid_content_width;
			margin: 30px auto 50px;
			line-height: 1.9em;
			&-title {
				font-weight: bold;
				margin-bottom: 30px; }
			p {
				margin-bottom: 40px;
				line-height: 1.9em; }
			ul {
				li {
					padding-left: 15px;
					position: relative;
					line-height: 1.9em;
					&:before {
						content: '';
						position: absolute;
						top: 11px;
						left: 0;
						height: 4px;
						width: 4px;
						border-radius: 50%;
						background: $font_dark_gray; } } }
			img {
				margin: 30px 0 0;
				position: relative;
				border-radius: 5px;
				overflow: hidden; } } }

	&-repost {
		margin-bottom: 50px; } }

@include media($mobile) {
	&-news {
		&__content {
			.#{$prefix}-pagination {
				margin-bottom: 30px; } }
		&__item {
			flex: 0 1 100%;
			max-width: 100%;
			margin: 10px 0 20px;
			figure {
				padding: 0 20px; } }
		&__grid {
			margin: 0 0 40px; }
		&-info {
			margin-top: 15px;
			&__date {
				margin-bottom: 7px; } }
		&__buttons {
			text-align: left;
			padding: 0 45px;
			.#{$prefix}-btn {
				margin: 0 0 35px;
				width: 100%; } }
		&-inner {
			.#{$prefix}-news {
				&__grid {
					margin: 0 0 10px; } } } }
	&-new {
		&-single {
			&__header {
				padding: 0 20px;
				margin: 0 auto; }
			&__content {
				padding: 0 20px;
				margin: 0 0 40px;
				&-title, p {
					margin-bottom: 20px; }
				img {
					margin: 40px 0 0; }
				ul {
					li {
						padding-left: 12px; } } } }
		&-repost {
			margin-bottom: 40px; } } }
