&-slider {
	&__item {
		position: relative;
		display: block;
		overflow: hidden;
		max-height: 540px;
		figcaption {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 40px;
			max-width: 420px;
			display: flex;
			background: $blue; } }
	&-info {
		margin: auto 40px;
		color: $font_white;
		&__date {
			font-size: 1.75em;
			text-transform: lowercase;
			display: none;
			&:after {
				content: '';
				display: block;
				margin: 25px 0;
				width: 115px;
				border-top: 1px solid $white; } }
		&__title {
			font: {
				family: Panton;
				weight: bold;
				size: 1.7em; }
			line-height: 1.4em;
			word-break: break-word; }
		&__desc {
			//font-size: 1.75em
			font-size: 1.5em;
			margin: 20px 0 0;
			line-height: 1.5em; }
		&__link {
			margin: 45px 0 0; } }
	&__nav {
		&-prev {
			display: flex;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 40px;
			z-index: 1000;
			span {
				display: block;
				height: 18px;
				width: 18px;
				border-top: 3px solid $white;
				border-left: 3px solid $white;
				transform: rotate(-45deg);
				margin: auto 0 auto 16px; } }
		&-next {
			display: flex;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 40px;
			z-index: 1000;
			span {
				display: block;
				height: 18px;
				width: 18px;
				border-top: 3px solid $white;
				border-left: 3px solid $white;
				transform: rotate(135deg);
				margin: auto 16px auto 0; } } }
	&__dots {
		position: absolute;
		bottom: 25px;
		z-index: 1000;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 0;
		li {
			display: inline-block;
			button {
				background: rgba(255, 255, 255, .3);
				font-size: 0;
				height: 3px;
				width: 26px;
				margin: 0 8px;
				overflow: hidden; }
			&.slick-active {
				button {
					background: $white; } } } }
	&-about {
		padding-bottom: 85px;
		.#{$prefix}-slider__nav-prev, .#{$prefix}-slider__nav-next {
			bottom: 80px; }
		&__dots {
			margin-top: 80px;
			text-align: center;
			font-size: 0;
			li {
				display: inline-block;
				button {
					background: rgba(82, 95, 139, 0.3);
					font-size: 0;
					height: 3px;
					width: 26px;
					margin: 0 8px;
					overflow: hidden; }
				&.slick-active {
					button {
						background: $light_blue; } } } } } }

&-carousel {
	margin-bottom: 90px;
	&__item {
		height: 196px;
		box-sizing: border-box; }
	&__nav {
		&-prev {
			display: flex;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 65px;
			width: 40px;
			z-index: 1000;
			span {
				display: block;
				height: 18px;
				width: 18px;
				transform: rotate(-45deg);
				margin: auto 0 auto 16px; } }
		&-next {
			display: flex;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 65px;
			width: 40px;
			z-index: 1000;
			span {
				display: block;
				height: 18px;
				width: 18px;
				transform: rotate(135deg);
				margin: auto 16px auto 0; } }
		&_white {
			span {
				border-top: 3px solid $font_white;
				border-left: 3px solid $font_white; } }
		&_blue {
			span {
				border-top: 3px solid $font_blue;
				border-left: 3px solid $font_blue; } } }
	&__dots {
		margin-top: 55px;
		text-align: center;
		font-size: 0;
		li {
			display: inline-block;
			button {
				background: $light_gray;
				font-size: 0;
				height: 3px;
				width: 26px;
				margin: 0 8px;
				overflow: hidden; }
			&.slick-active {
				button {
					background: $light_blue; } } } }
	&_about {
		padding: 30px 0 75px;
		margin-bottom: 60px;
		.#{$prefix}-slick-carousel {
			margin-top: 15px; }
		.slick {
			&-list {
				margin: 0 -12px; }
			&-slide {
				margin: 0 12px; } } }
	&_partners {
		.#{$prefix}-carousel__item {
			padding: 0 20px;
			height: auto;
			img {
				width: 100%; } }
		.#{$prefix}-slick-carousel {
			padding: 0 40px;
			margin: 45px 0 0; } } }

@include media($mobile) {
	&-slider {
		&__item {
			figcaption {
				position: relative;
				bottom: 0;
				left: 0;
				height: 500px;
				box-sizing: border-box;
				max-width: none;
				width: 100%;
				background: transparent; }
			img {
				display: none; } }
		&-info {
			margin: auto 20px;
			&__title {
				font-size: 1.75em; }
			&__desc {
				font-size: 1.375em; }
			&__date {
				font-size: 1.375em; } }
		&__nav {
			&-next, &-prev {
				display: none !important; } }
		&__dots {
			bottom: 20px; }
		&-about {
			display: none; } }
	&-carousel {
		&__nav {
			&-prev, &-next {
				display: none !important; } }
		&__item {
			padding: 0 20px;
			height: auto; }
		&__dots {
			margin-top: 45px;
			width: 100%;
			li {
				padding-top: 4px; } }
		&_about {
			margin: 0;
			padding: 40px 0 45px;
			.slick {
				&-list, &-slide {
					margin: 0; } }
			.#{$prefix}-slick-carousel {
				margin-top: 40px; } }
		&_partners {
			margin: 40px 0 45px;
			.#{$prefix}-slick-carousel {
				margin-top: 40px; } } } }
