&-structure {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 50px;
		p {
			margin-bottom: 5px; }
		&-list {
			padding-left: 30px;
			&-item {
				margin: 7px 0 12px; } } }
	&__item {
		padding: 30px 0 15px;
		border-bottom: 1px solid $border_gray;
		&:first-child {
			border-top: 1px solid $border_gray; } }
	&__title {
		position: relative;
		padding-left: 30px;
		margin: 0 0 15px;
		font: {
			family: Panton;
			weight: bold;
			size: 1.375em; }
		line-height: 55px;
		color: $font_blue;
		box-shadow: inset 5px 0 0 $light_red;
		cursor: pointer;
		@include str-dropdown-tab(7, 3); }
	&-tab {
		&__title {
			font: {
				family: Istok Web;
				weight: normal;
				size: 1em; }
			color: $font_blue;
			cursor: pointer;
			&:after {
				content: '';
				height: 4px;
				width: 4px;
				border: 2px solid;
				border-left: none;
				border-top: none;
				display: inline-block;
				position: relative;
				margin-left: 12px;
				top: -3px;
				transform: rotate(45deg); }
			&.#{$prefix}-tab-title_open {
				color: $font_dark_gray;
				&:after {
					transform: rotate(-135deg);
					top: -1px; } } }
		&__content {
			padding-left: 20px;
			margin-top: 5px; }
		&__desc {
			font-size: .875em;
			margin-bottom: 10px;
			ul {
				li {
					padding-left: 15px;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						top: 11px;
						left: 0;
						height: 4px;
						width: 4px;
						border-radius: 50%;
						background: $font_dark_gray; } } } } } }

@include media($mobile) {
	&-structure {
		&__content {
			margin: 0 0 30px;
			&-list {
				padding: 0; } }
		&__item {
			padding: 25px 0 15px;
			margin: 0 20px; }
		&__title {
			font-size: 1.15em;
			line-height: 1.5em;
			padding: 0 20px;
			margin: 0 0 10px -20px;
			box-shadow: inset 5px 0 0 $light_blue;
			&:after {
				height: 5px;
				width: 5px;
				border: 2px solid;
				border-left: none;
				border-top: none;
				right: 3px;
				top: 50%;
				margin-top: -5px; } }
		&-tab {
			&__desc {
				font-size: 1em; }
			&__title {
				position: relative;
				padding-right: 15px;
				&:after {
					border: 1px solid;
					border-left: none;
					border-top: none;
					position: absolute;
					right: 4px;
					top: 50%;
					margin-top: -5px; }
				&.#{$prefix}-tab-title_open {
					color: $font_dark_gray;
					&:after {
						transform: rotate(-135deg);
						top: 50%;
						margin-top: -3px; } } } } } }
