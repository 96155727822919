&-business {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 80px;
		p {
			margin-bottom: 40px;
			padding: 0 30px; } }
	&__title {
		padding-left: 30px;
		font: {
			family: Panton;
			weight: bold;
			size: 1.375em; }
		color: $font_dark_gray;
		line-height: 55px;
		margin: 60px 0 20px;
		box-shadow: inset 5px 0 0 $light_red;
		&:first-child {
			margin-top: 0; } }
	&__buttons {
		text-align: center;
		margin-bottom: 90px; } }

@include media($mobile) {
	&-business {
		&__content {
			margin: 0 0 35px;
			p {
				margin-bottom: 20px;
				padding: 0 20px; } }
		&__title {
			padding-left: 20px;
			font-size: 1.15em;
			line-height: 35px;
			margin: 40px 0 20px;
			&:first-child {
				margin-top: 0; } }
		&__buttons {
			margin-bottom: 45px;
			a {
				width: auto; } } } }
