&-loader, &-loader:before, &-loader:after {
	border-radius: 50%;
	width: 1.5em;
	height: 1.5em;
	animation-fill-mode: both;
	animation: loading 1.8s infinite ease-in-out; }

&-loader {
	color: $light_blue_hover;
	font-size: 10px;
	margin: 0 auto 3em;
	position: relative;
	text-indent: -9999em;
	transform: translateZ(0);
	animation-delay: -0.16s;

	&:before, &:after {
		content: '';
		position: absolute;
		top: 0; }

	&:before {
		left: -3em;
		animation-delay: -0.32s; }

	&:after {
		left: 3em; } }
