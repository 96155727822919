&-search {
	&-form {
		overflow: hidden;
		position: absolute;
		right: -15px;
		top: -7px;
		background: $white;
		box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
		z-index: 10;
		form {
			width: 0;
			transition: all .3s ease; }
		&_open {
			width: 400px !important; }
		&-input {
			input {
				line-height: 2.4em;
				padding: 0 50px 0 15px;
				width: 100%;
				box-sizing: border-box;
				&:focus {
					border: none;
					outline: none; } } }
		&-button {
			position: absolute;
			top: 0;
			right: 0;
			background: transparent;
			padding: 7px 15px 0 0; } }

	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 70px; }
	&__list {
		margin-bottom: 40px; }
	&__item {
		padding: 30px 0;
		border-top: 1px solid $border_gray;
		&:first-child {
			border: none; }
		&-title {
			font: {
				family: Panton;
				weight: bold;
				size: 1.15em; }
			color: $font_blue !important;
			margin-bottom: 7px;
			display: inline-block; }
		&-path {
			margin-top: 5px;
			a {
				font-size: .875em; } } } }

@include media($mobile) {
	&-search {
		&__content {
			margin: 0 0 40px;
			padding: 0 20px; }
		&__list {
			margin-bottom: 20px; }
		&__item {
			padding: 20px 0;
			&:first-child {
				padding-top: 0; } }
		&-form {
			form {
				height: 0;
				width: 100% !important;
				position: fixed;
				top: 59px;
				left: 0;
				overflow: hidden;
				box-shadow: 0 5px 7px rgba(0,0,0,.1); }
			&_open {
				height: auto !important; } } } }
