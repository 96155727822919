&-mice {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 90px; }
	&__title {
		color: $font_dark_gray;
		line-height: 55px;
		padding-left: 30px;
		font: {
			family: Panton;
			weight: bold;
			size: 1.625em; }
		box-shadow: inset 5px 0 0 $light_red; }
	&__buttons {
		text-align: center;
		margin-bottom: 50px; }
	&-inner {
		.#{$prefix}-docs__list {
			li {
				padding-left: 0; } } } }

@include media($mobile) {
	&-mice {
		&__content {
			margin: 0 0 40px; }
		&__title {
			padding-left: 20px;
			font-size: 1.15em;
			line-height: 35px; }
		&__buttons {
			margin-bottom: 40px;
			a {
				width: auto; } }
		&-inner {
			.#{$prefix}-docs__list {
				li {
					padding-left: 20px; } } } } }
