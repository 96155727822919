&-activities {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 90px;
		img {
			max-height: 440px;
			width: 100%;
			margin-bottom: 25px; }
		p {
			margin-bottom: 40px; }
		ul {
			margin-bottom: 40px;
			li:not(.#{$prefix}-docs__item) {
				padding-left: 15px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 11px;
					left: 0;
					height: 4px;
					width: 4px;
					border-radius: 50%;
					background: $font_dark_gray; } } } }
	&__title {
		font: {
			family: Panton;
			weight: bold;
			size: 1.5em; }
		color: $font_dark_gray;
		margin: 10px 0 20px; } }

@include media($mobile) {
	&-activities {
		&__content {
			margin: 0 0 40px;
			img {
				border-radius: 5px;
				overflow: hidden;
				margin: 0 20px 25px;
				width: calc(100% - 40px); }
			p {
				margin-bottom: 20px;
				padding: 0 20px; }
			ul {
				margin-bottom: 20px;
				padding: 0 20px;
				li {
					p {
						padding: 0; } } } }
		&__title {
			padding: 0 20px;
			font-size: 1.15em; } } }
