&-filter {
	text-align: center;
	&__grid {
		display: inline-flex;
		justify-content: center;
		flex-wrap: wrap;
		border-radius: 5px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .07);
		&-full {
			width: 100%;
			.#{$prefix}-filter__item {
				min-width: auto; } } }
	&__title {
		display: none;
		font: {
			family: Panton;
			size: 1.4em;
			weight: bold; }
		color: $font_dark_gray;
		padding: 16px 0;
		border-bottom: 1px solid rgba(113, 134, 207, .2); }
	&__close {
		display: none;
		position: absolute;
		top: 20px;
		right: 20px; }
	&__return {
		display: none;
		position: absolute;
		top: 7px;
		left: 10px;
		padding: 15px;
		span {
			height: 12px;
			width: 12px;
			display: block;
			border: 1px solid rgb(65, 104, 140);
			border-bottom: none;
			border-right: none;
			transform: rotate(-45deg); } }
	&__item {
		flex: 1 0 16%;
		min-width: 215px;
		box-sizing: border-box;
		text-align: left;
		&:last-child {
			.#{$prefix}-dropdown:not(.#{$prefix}-dropdown_open) {
				box-shadow: none; } }
		.#{$prefix}-dropdown__clear {
			display: none; } }
	&__info {
		margin: 40px 0 25px;
		& > * {
			padding: 0 10px; }
		a {
			color: $font_blue; } }
	&__input {
		padding: 13px 25px;
		width: 100%;
		box-sizing: border-box;
		&::placeholder {
			color: $font_dark_gray;
			font-size: 1em; } }
	&-button {
		display: none;
		text-align: center; } }

@include media($mobile) {
	&-filter {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		background: $white;
		padding-bottom: 20px;
		box-shadow: 0 2px 4px rgba(0,0,0,.07);
		display: none;
		&_open {
			display: block; }
		&__grid {
			box-shadow: none; }
		&__title, &__close {
			display: block; }
		&__item {
			flex: 0 1 100%;
			padding: 0 20px;
			a {
				display: inline-block !important; }
			&:last-child {
				text-align: center;
				margin-top: 10px;
				a {
					border-radius: 5px;
					width: 105px;
					margin: 0 5px; } } }
		&__info {
			display: none; }
		&__input {
			width: auto;
			padding: 15px 0;
			font-size: 1em;
			&::placeholder {
				font: {
					family: Panton;
					weight: bold; } } }
		&-button {
			display: block; } } }
