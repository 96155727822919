&-icon {
	display: block; }

&-search {
	height: 23px;
	width: 23px;
	cursor: pointer;
	@include icon-background('search#{$suffix}'); }

&-lk {
	position: absolute;
	height: 18px;
	width: 12px;
	left: 20px;
	top: 8px;
	@include icon-background('lk#{$suffix}'); }

@include media($mobile) {
	&-lk {
		position: relative;
		left: 0;
		top: 0; } }

&-vision {
	width: 31px;
	height: 15px;
	margin: 14px auto;
	@include icon-background('vision#{$suffix}'); }

&-phone {
	width: 18px;
	height: 16px;
	top: 13px;
	@include icon-background(phone); }

&-handset {
	width: 16px;
	height: 16px;
	@include icon-background('handset#{$suffix}'); }

&-email {
	width: 15px;
	height: 12px;
	top: 16px;
	@include icon-background(email);
	&-gray {
		width: 15px;
		height: 15px;
		@include icon-background('email-gray#{$suffix}'); } }

&-time {
	width: 16px;
	height: 16px;
	top: 14px;
	@include icon-background('time'); }

&-review {
	width: 16px;
	height: 16px;
	top: 12px;
	@include icon-background(review); }

&-date {
	width: 14px;
	height: 14px;
	@include icon-background('date#{$suffix}'); }

&-age {
	width: 15px;
	height: 15px;
	@include icon-background('age#{$suffix}'); }

&-participant {
	width: 14px;
	height: 16px;
	@include icon-background('participant#{$suffix}'); }

&-vk {
	width: 24px;
	height: 24px;
	@include icon-background(vk);
	&-rp {
		height: 30px;
		width: 30px;
		@include icon-background('vk-rp#{$suffix}'); }
	&-menu {
		height: 24px;
		width: 24px;
		@include icon-background('vk-menu#{$suffix}'); } }

&-fb {
	width: 24px;
	height: 24px;
	@include icon-background(fb);
	&-rp {
		height: 30px;
		width: 30px;
		@include icon-background('fb-rp#{$suffix}'); }
	&-menu {
		height: 24px;
		width: 24px;
		@include icon-background('fb-menu#{$suffix}'); } }

&-inst {
	width: 24px;
	height: 24px;
	@include icon-background(inst);
	&-menu {
		width: 24px;
		height: 24px;
		@include icon-background('inst-menu#{$suffix}'); } }

&-tel {
	&-rp {
		height: 30px;
		width: 30px;
		@include icon-background('tel-rp#{$suffix}'); } }

&-tw {
	&-rp {
		height: 30px;
		width: 30px;
		@include icon-background('tw-rp#{$suffix}'); } }

&-doc {
	width: 20px;
	height: 20px;
	@include icon-background('doc#{$suffix}'); }

@include media($mobile) {
	&-doc {
		width: 15px;
		height: 15px; } }

&-close {
	width: 20px;
	height: 20px;
	@include icon-background('close#{$suffix}'); }

&-label {
	width: 11px;
	height: 15px;
	@include icon-background('label#{$suffix}'); }

&-label-filter {
	width: 14px;
	height: 17px;
	@include icon-background('filter#{$suffix}'); }

&-ok {
	height: 25px;
	width: 25px;
	@include icon-background('ok#{$suffix}'); }

&-no {
	height: 25px;
	width: 25px;
	@include icon-background('no#{$suffix}'); }

&-students {
	&-grid {
		&-1 {
			width: 67px; }
		&-2 {
			width: 46px;
			use {
				transform: translate(-72px, 0); } }
		&-3 {
			width: 56px;
			use {
				transform: translate(-120px, 0); } }
		&-4 {
			width: 40px;
			use {
				transform: translate(-177px, 0); } }
		&-5 {
			width: 50px;
			use {
				transform: translate(-221px, 0); } }
		&-6 {
			width: 55px;
			use {
				transform: translate(-279px, 0); } }
		&-7 {
			width: 56px;
			use {
				transform: translate(-338px, 0); } }
		&-8 {
			width: 69px;
			use {
				transform: translate(-401px, 0); } } } }

&-partners {
	&-grid {
		&-1 {
			width: 69px;
			use {
				transform: translate(0, -60px); } }
		&-2 {
			width: 53px;
			use {
				transform: translate(-75px, -60px); } } } }

&-about {
	&-grid {
		&-1 {
			width: 50px;
			use {
				transform: translate(0, -120px); } }
		&-2 {
			width: 64px;
			use {
				transform: translate(-54px, -120px); } }
		&-3 {
			width: 61px;
			use {
				transform: translate(-121px, -120px); } }
		&-4 {
			width: 51px;
			use {
				transform: translate(-188px, -120px); } }
		&-5 {
			width: 50px;
			use {
				transform: translate(-246px, -120px); } }
		&-6 {
			width: 65px;
			use {
				transform: translate(-300px, -120px); } }
		&-7 {
			width: 41px;
			use {
				transform: translate(-370px, -120px); } }
		&-8 {
			width: 56px;
			use {
				transform: translate(-414px, -120px); } }
		&-9 {
			width: 48px;
			use {
				transform: translate(-474px, -120px); } }
		&-10 {
			width: 59px;
			use {
				transform: translate(-530px, -120px); } }
		&-11 {
			width: 50px;
			use {
				transform: translate(0, -120px); } } } }

&-services {
	&-grid {
		&-1 {
			width: 55px;
			use {
				transform: translate(0, -180px); } }
		&-2 {
			width: 50px;
			use {
				transform: translate(-63px, -180px); } }
		&-3 {
			width: 50px;
			use {
				transform: translate(-123px, -180px); } }
		&-4 {
			width: 50px;
			use {
				transform: translate(-179px, -180px); } }
		&-5 {
			width: 55px;
			use {
				transform: translate(-238px, -180px); } }
		&-6 {
			width: 38px;
			use {
				transform: translate(-298px, -180px); } }
		&-7 {
			width: 64px;
			use {
				transform: translate(-345px, -180px); } }
		&-8 {
			width: 67px;
			use {
				transform: translate(-416px, -180px); } }
		&-9 {
			width: 69px;
			use {
				transform: translate(-487px, -180px); } } } }
