&-about {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 70px; }
	&__image {
		margin: 30px 0 65px;
		position: relative;
		border-radius: 5px;
		overflow: hidden;
		&-desc {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 40px;
			color: $font_white;
			box-shadow: inset 0 -80px 60px -50px rgba(47, 67, 139, .6);
			font: {
				family: Panton;
				weight: bold;
				size: 3.5em; } } }
	&-inner {
		&__content {
			max-width: $mid_content_width;
			margin: 50px auto 75px;
			p {
				margin-bottom: 40px; }
			li {
				padding-left: 15px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 11px;
					left: 0;
					height: 4px;
					width: 4px;
					border-radius: 50%;
					background: $font_dark_gray; } } } }
	@include white-grid(33.3%);
	&__grid {
		padding: 60px 0 75px;
		justify-content: flex-start; } }

&-color {
	&-blocks {
		line-height: 1.875em;
		margin-top: 35px;
		li {
			display: flex;
			padding-left: 32px;
			height: 55px;
			margin-top: 15px;
			p {
				margin: auto 0; }

			&:nth-child(odd) {
				box-shadow: inset 6px 0 0 -1px $light_red; }

			&:nth-child(even) {
				box-shadow: inset 6px 0 0 -1px $light_blue; } } } }

@include media($mobile) {
	&-about {
		&__content {
			margin: 0 auto 20px;
			& > p {
				display: none; } }
		&-inner {
			.#{$prefix}-container {
				display: flex;
				flex-wrap: wrap;
				& > * {
					flex: 0 0 100%; }
				.#{$prefix}-title-h1 {
					order: 2; }
				.#{$prefix}-title-h5 {
					display: none; }
				.#{$prefix}-about__image {
					order: 1; }
				.#{$prefix}-about-inner__content {
					order: 4; } }
			.#{$prefix}-inline-logo {
				display: none; }
			&__content {
				margin: 0 auto 20px;
				p {
					padding: 0 20px;
					margin-bottom: 20px; }
				ul {
					padding: 0 20px;
					p {
						padding: 0; } } } }
		&__image {
			margin: 0;
			border-radius: 0;
			height: 440px;
			img {
				height: 100%;
				width: auto; }
			&-desc {
				box-shadow: none;
				padding: 0 20px;
				font-size: 2em;
				z-index: 1;
				top: 0;
				display: flex;
				span {
					margin: auto; } }
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: $blue;
				opacity: .7; } } }
	&-color {
		&-blocks {
			line-height: 1.875em;
			margin-top: 15px;
			li {
				padding: 12px 12px 12px 19px;
				height: auto;
				margin-top: 0; } } } }
