&-services {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 45px; }
	@include white-grid(33.3%);
	&__grid {
		padding: 60px 0;
		justify-content: flex-start; } }

@include media($mobile) {
	&-services {
		&__content {
			padding: 0 20px;
			margin-bottom: 40px; }
		&__grid {
			padding: 45px 0; } } }
