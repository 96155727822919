&-methodic {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 50px; }
	&__list {
		margin-bottom: 50px;
		&:nth-child(odd) {
			.#{$prefix}-methodic__title {
				box-shadow: inset 5px 0 0 $light_blue; } }
		&:nth-child(even) {
			.#{$prefix}-methodic__title {
				box-shadow: inset 5px 0 0 $light_red; } } }
	&__title {
		color: $font_dark_gray;
		font: {
			family: Panton;
			weight: bold;
			size: 1.375em; }
		line-height: 55px;
		padding-left: 30px; }
	&__item {
		&-title {
			font: {
				family: Istok Web;
				weight: bold;
				size: 1.25em; }
			padding: 15px 30px;
			color: $font_blue;
			cursor: pointer;
			&:after {
				content: '';
				height: 7px;
				width: 7px;
				border: 2px solid;
				border-left: none;
				border-top: none;
				display: inline-block;
				position: relative;
				margin-left: 12px;
				top: -3px;
				transform: rotate(45deg); }
			&.#{$prefix}-tab-title_open {
				color: $font_dark_gray;
				&:after {
					transform: rotate(-135deg);
					top: -1px; } } }
		.#{$prefix}-docs__item {
			padding-left: 30px; } } }

@include media($mobile) {
	&-methodic {
		&__content {
			margin: 0 0 30px; }
		&__list {
			margin-bottom: 20px; }
		&__title {
			font-size: 1.15em;
			line-height: 35px;
			padding-left: 20px;
			margin-bottom: 5px;
			box-shadow: inset 5px 0 0 $light_red !important; }
		&__item {
			&-title {
				font-size: 1em;
				padding: 10px 20px;
				position: relative;
				&:after {
					height: 4px;
					width: 4px;
					position: absolute;
					top: 18px;
					right: 22px; }
				&.#{$prefix}-tab-title_open {
					&:after {
						top: 20px; } } }
			.#{$prefix}-docs__item {
				padding-left: 20px; } } } }
