// vars
@import "base/vars";
@import "base/colors";
@import "base/mixins";
@import "base/media";
@import "base/animation";

// base
@import "base/fonts";
@import "base/general";

// blocks
@import "blocks/general";
@import "blocks/main/slick-slider";
@import "blocks/date-range-picker";

// yandex share icons
.ya-share2__icon {
	height: 30px !important;
	width: 30px !important;
	&:active {
		box-shadow: none !important; }
	.ya-share2__item_service_vkontakte & {
		@include icon-background(vk-rp, !important); }
	.ya-share2__item_service_facebook & {
		@include icon-background(fb-rp, !important); }
	.ya-share2__item_service_twitter & {
		@include icon-background(tw-rp, !important); }
	.ya-share2__item_service_telegram & {
		@include icon-background(tel-rp, !important); } }

.ya-share2__badge {
	background: transparent !important; }

.ya-share2__list_direction_horizontal > .ya-share2__item {
	margin: 0 12px !important; }

.#{$prefix} {
	// base
	@import "base/buttons";
	@import "base/icons";
	@import "base/forms";

	// blocks
	@import "blocks/header";
	@import "blocks/footer";
	@import "blocks/modal";
	@import "blocks/tabs";
	@import "blocks/tables";
	@import "blocks/search";
	@import "blocks/loader";
	@import "blocks/breadcrumbs";
	@import "blocks/pagination";
	@import "blocks/dropdown";
	@import "blocks/docs";
	@import "blocks/filter";
	@import "blocks/pages/news";
	@import "blocks/pages/management";
	@import "blocks/pages/expert";
	@import "blocks/pages/team";
	@import "blocks/pages/official-info";
	@import "blocks/pages/gallery";
	@import "blocks/pages/vacancy";
	@import "blocks/pages/services";
	@import "blocks/pages/methodical-material";
	@import "blocks/pages/mice";
	@import "blocks/pages/events";
	@import "blocks/pages/contacts";
	@import "blocks/pages/business";
	@import "blocks/pages/teachers";
	@import "blocks/pages/diagnostics";
	@import "blocks/pages/infrastructure";
	@import "blocks/pages/registry-of-students";
	@import "blocks/pages/escort";
	@import "blocks/pages/project-activities";
	@import "blocks/pages/brand";
	@import "blocks/main/slider";
	@import "blocks/main/about";
	@import "blocks/main/students";
	@import "blocks/main/partners";
	@import "blocks/main/join"; }

.#{$prefix}-impaired {
	$suffix: '-im';
	@import "base/color-im";
	@import "base/mixins";

	p {
		a {
			color: $font_dark_gray !important; } }

	// blocks
	@import "blocks/general";
	@import "blocks/date-range-picker";
	.#{$prefix} {
		// base
		@import "base/buttons";
		@import "base/icons";
		@import "base/forms";

		// blocks
		@import "blocks/header";
		@import "blocks/footer";
		@import "blocks/modal";
		@import "blocks/tabs";
		@import "blocks/tables";
		@import "blocks/search";
		@import "blocks/loader";
		@import "blocks/breadcrumbs";
		@import "blocks/pagination";
		@import "blocks/dropdown";
		@import "blocks/docs";
		@import "blocks/filter";
		@import "blocks/pages/news";
		@import "blocks/pages/management";
		@import "blocks/pages/expert";
		@import "blocks/pages/team";
		@import "blocks/pages/official-info";
		@import "blocks/pages/gallery";
		@import "blocks/pages/vacancy";
		@import "blocks/pages/services";
		@import "blocks/pages/methodical-material";
		@import "blocks/pages/mice";
		@import "blocks/pages/events";
		@import "blocks/pages/contacts";
		@import "blocks/pages/business";
		@import "blocks/pages/teachers";
		@import "blocks/pages/diagnostics";
		@import "blocks/pages/infrastructure";
		@import "blocks/pages/registry-of-students";
		@import "blocks/pages/escort";
		@import "blocks/pages/project-activities";
		@import "blocks/pages/brand";
		@import "blocks/main/slider";
		@import "blocks/main/about";
		@import "blocks/main/students";
		@import "blocks/main/partners";
		@import "blocks/main/join";

		&-inline-logo_blue, &-inline-logo_red, &-news__photo, &-carousel, &-team-person__image, &-slider-about {
			display: none !important; }

		&-news-info__date {
			font-weight: bold; }

		&-album__desc {
			box-shadow: 0 0 50px $font_dark_gray; }

		&-events__photo {
			background-color: rgba(0, 0, 0, .1);
			background-image: none !important; }

		&-calendar-day {
			&.in-range, &.in-range:hover, &.start-date:after, &.end-date:after {
				background: rgba(0,0,0,.2) !important; }
			&.active {
				background: $white !important;
				&.in-range {
					background: transparent !important; } } }

		&-escort__item-title {
			&.#{$prefix}-tab-title_open {
				background: transparent !important; } }

		&-table_big__header {
			& > div {
				&.#{$prefix}-table_big__header-item_light {
					background: transparent !important; } } }

		&-students-registry__list-count li:before {
			color: rgba(0, 0, 0, .1); }

		&-team-person__post {
			color: $font_dark_gray !important; }

		@include media($mobile) {
			&-join__desc {
				color: $font_white; }
			&-header__menu-item_active {
				& > a {
					box-shadow: none !important; } } } }

	##{$prefix}-slider, img {
		display: none !important; } }
