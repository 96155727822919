@mixin icon-background ($url, $i: '') {
	background: {
		image: url(#{$icon_path}icon-#{$url}.svg) #{$i};
		repeat: no-repeat #{$i};
		position: center #{$i};
		size: contain #{$i}; } }

@mixin str-dropdown-tab ($size, $border) {
	&:after {
		content: '';
		height: #{$size}px;
		width: #{$size}px;
		border: #{$border}px solid;
		border-left: none;
		border-top: none;
		position: absolute;
		right: 25px;
		top: 23px;
		transition: color .2s ease;
		transform: rotate(45deg); }
	&.#{$prefix}-tab-title_open {
		color: $font_dark_gray;
		&:after {
			transform: rotate(-135deg); } } }

@mixin white-grid ($width) {
	&__grid {
		flex-wrap: wrap;
		margin: 0 -20px;
		a {
			display: block;
			flex: 0 1 #{$width};
			padding: 25px 20px;
			box-sizing: border-box;
			&:hover {
				& > div {
					&:after {
						background: $light_red; } }
				use {
					fill: $light_red; } } } }
	&__item {
		background: $white;
		border-radius: 4px;
		box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
		text-align: center;
		padding: 30px 0 25px;
		position: relative;
		overflow: hidden;
		span {
			font: {
				family: Panton;
				weight: bold;
				size: 1.25em; }
			line-height: 1.5em;
			display: block;
			height: 55px; }
		&:after {
			content: '';
			height: 15px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background: $light_blue;
			transition: all .3s ease; } }
	&__icon {
		height: 55px;
		margin: 10px 0 25px;
		use {
			fill: $icon_gray;
			transition: all .2s ease; } }

	@include media($mobile) {
		&__grid {
			margin: 0;
			a {
				flex: 0 1 100%;
				padding: 15px 20px; } }
		&__item {
			padding: 15px 0 25px;
			span {
				font: {
					family: Panton;
					weight: normal;
					size: 1.15em; }
				line-height: 1.4em;
				display: inline-flex;
				align-items: flex-end;
				height: 40px; } }
		&__icon {
			margin: 5px 0 10px; } } }
