&-btn {
	display: inline-block;
	padding: 16px 15px 15px;
	width: 400px;
	text-align: center;
	border-radius: 5px;
	font: bold 1.25em Panton;
	cursor: pointer;
	box-sizing: border-box;
	transition: all .3s ease !important;

	&_s {
		display: inline-block;
		padding: 6px 35px;
		border-radius: 5px;
		font: bold 1.125em Panton;
		cursor: pointer;
		transition: all .3s ease !important; }

	&_m {
		display: inline-block;
		padding: 10px 45px;
		border-radius: 5px;
		font: bold 1.125em Panton;
		cursor: pointer;
		transition: all .3s ease !important; }

	&_red {
		background: $light_red;
		color: $font_white !important;
		&:hover {
			background: $light_red_hover; } }

	&_blue {
		background: $light_blue;
		color: $font_white !important;
		&:hover {
			background: $light_blue_hover; } }

	&_white {
		background: $white;
		color: $blue !important;
		&:hover {
			box-shadow: inset -100px 0 100px -100px rgba(0, 0, 0, .2); }
		&_s {
			background: $white;
			color: $font_blue !important;
			&:hover {
				box-shadow: inset -100px 0 100px -100px rgba(0, 0, 0, .2); } } }

	&_filter {
		border-radius: 5px;
		border: 2px solid $light_blue;
		padding: 1px 45px 0;
		display: inline-block;
		span {
			color: $light_blue;
			line-height: 2.4em; }
		.#{$prefix}-label-filter {
			display: inline-block;
			margin-left: 30px;
			vertical-align: sub; }
		&-search {
			display: block;
			text-align: center;
			padding: 15px;
			border-radius: 0 5px 5px 0;
			font: bold 1em Panton;
			background: $light_blue;
			color: $font_white !important;
			transition: all .3s ease !important;
			&:hover {
				background: $light_blue_hover; } } } }


@include media($mobile) {
	&-sbtn {
		&_blue {
			padding: 9px 15px;
			width: 100%;
			text-align: center;
			border-radius: 5px;
			font: bold 1.05em Panton;
			cursor: pointer;
			background: $light_blue;
			color: $font_white !important;
			&:hover {
				background: $light_blue; } }
		&_white {
			padding: 9px 15px;
			width: 100%;
			text-align: center;
			border-radius: 5px;
			font: bold 1.05em Panton;
			cursor: pointer;
			background: $white;
			color: $font_dark_gray !important;
			&:hover {
				background: $white; } } }
	&-btn {
		&_filter {
			&-search {
				padding: 9px 10px; }
			&-clear {
				padding: 7px 10px;
				text-align: center;
				font: bold 1em Panton;
				border: 2px solid $light_blue;
				color: $light_blue !important; } } } }
