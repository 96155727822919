&-escort {
	img {
		max-height: 800px;
		width: 100%;
		margin-bottom: 80px; }
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 40px;
		p {
			margin-bottom: 40px; } }
	&__title {
		padding-left: 30px;
		line-height: 55px;
		color: $font_dark_gray;
		margin-bottom: 45px;
		font: {
			family: Panton;
			weight: bold;
			size: 1.5em; }
		&:nth-child(odd) {
			box-shadow: inset 5px 0 0 $light_red; }
		&:nth-child(even) {
			box-shadow: inset 5px 0 0 $light_blue; } }
	&__item {
		border-bottom: 1px solid $light_gray;
		&-title {
			font: {
				size: 1.125em;
				weight: bold; }
			color: $font_dark_gray;
			padding: 25px 45px 25px 30px;
			position: relative;
			cursor: pointer;
			@include str-dropdown-tab(5, 1);
			&:after {
				right: 30px;
				top: 50%;
				margin-top: -5px; }
			&.#{$prefix}-tab-title_open {
				&:after {
					margin-top: -3px; } } }
		&-content {
			& > div {
				margin: 15px 30px 25px; }
			p {
				margin-bottom: 0; } } } }

&-gtab {
	margin-bottom: 50px;
	&__content {
		&:nth-child(odd) {
			.#{$prefix}-escort__item-title.#{$prefix}-tab-title_open {
				background: rgba(235, 126, 127, .1); } }
		&:nth-child(even) {
			.#{$prefix}-escort__item-title.#{$prefix}-tab-title_open {
				background: rgba(113, 134, 207, .1); } } } }

@include media($mobile) {
	&-escort {
		img {
			border-radius: 5px;
			overflow: hidden;
			margin: 0 20px 25px;
			width: calc(100% - 40px); }
		&__content {
			margin: 0 0 30px;
			p {
				margin-bottom: 20px;
				padding: 0 20px; } }
		&__title {
			padding: 0 20px;
			font-size: 1.15em;
			line-height: 30px;
			margin: 30px 0; }
		&__item {
			&-title {
				font-size: 1em;
				padding: 15px 30px 15px 20px;
				line-height: normal;
				@include str-dropdown-tab(3, 1);
				&:after {
					right: 20px;
					top: 50%;
					margin-top: -3px; }
				&.#{$prefix}-tab-title_open {
					&:after {
						margin-top: -1px; } } }
			&-content {
				& > div {
					margin: 10px 20px; }
				p {
					margin: 0;
					padding: 0; } } } }
	&-gtab {
		margin-bottom: 30px; } }
