&-official {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto; }
	&__list {
		padding: 10px 0 25px; }
	&-tab__list {
		.#{$prefix}-docs__list {
			padding: 10px 0 0 10px; } }
	&__item {
		margin-bottom: 40px;
		&:nth-child(odd) {
			.#{$prefix}-official__title {
				box-shadow: inset 5px 0 $light_red; } }
		&:nth-child(even) {
			.#{$prefix}-official__title {
				box-shadow: inset 5px 0 $light_blue; } } }
	&__title {
		font-weight: bold;
		font-size: 1.25em;
		line-height: 55px;
		padding-left: 35px;
		&.#{$prefix}-tab-title {
			cursor: pointer;
			&:after {
				content: '';
				height: 7px;
				width: 7px;
				border: 2px solid;
				border-left: none;
				border-top: none;
				display: inline-block;
				position: relative;
				margin-left: 12px;
				top: -3px;
				transform: rotate(45deg); } }
		&.#{$prefix}-tab-title_open {
			&:after {
				transform: rotate(-135deg);
				top: -1px; } } }
	&-data {
		&__item {
			padding: 35px;
			border-bottom: 1px solid $border_gray;
			line-height: 3em;
			flex-wrap: wrap;
			& > div {
				flex: 0 0 50%; } }
		&__title {
			font: {
				weight: bold;
				size: 1.125em; } }
		&__desc {
			padding-top: 3px;
			&_middle {
				margin: auto; }
			a {
				color: $font_blue; } } } }

@include media($mobile) {
	&-official {
		&__title {
			line-height: normal;
			height: 35px;
			padding: 0 20px;
			color: $font_blue;
			vertical-align: middle;
			display: table-cell;
			font: {
				size: 1.15em;
				family: Panton; }
			&.#{$prefix}-tab-title_open {
				color: $font_dark_gray;
				&:after {
					top: 16px !important; } }
			&.#{$prefix}-tab-title {
				&:after {
					height: 4px;
					width: 4px;
					top: 14px;
					position: absolute;
					right: 22px; } } }
		&__item {
			margin-bottom: 25px;
			padding-bottom: 25px;
			position: relative;
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 20px;
				right: 20px;
				border-bottom: 1px solid $border_gray; }
			&:nth-child(odd) {
				.#{$prefix}-official__title {
					box-shadow: inset 5px 0 $light_blue; } }
			&:first-child {
				.#{$prefix}-official__title {
					box-shadow: none;
					line-height: normal;
					color: $font_dark_gray;
					font: {
						family: Istok Web;
						weight: normal; } }
				padding-bottom: 40px; } }
		&-tab__list {
			.#{$prefix}-docs__list {
				padding: 15px 0 0; } }
		&-data {
			&__item {
				padding: 20px 20px 0;
				line-height: 1.9em;
				border: none;
				& > div {
					flex: 0 0 100%; } }
			&__title {
				font-size: 1em; } } } }
