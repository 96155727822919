&-vacancy {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 60px;
		&-title, &-list {
			padding-left: 35px; }
		&-title {
			font: {
				size: 1.375em;
				weight: bold; }
			margin-bottom: 12px; }
		&-list {
			padding-bottom: 30px;
			li {
				line-height: 2.4em;
				&:before {
					content: '';
					display: inline-block;
					height: 4px;
					width: 4px;
					border-radius: 50%;
					vertical-align: middle;
					margin-right: 10px;
					background: $font_dark_gray; } } }
		&-text {
			padding: 0 0 30px 35px; } }
	&__item {
		position: relative;
		border-top: 1px solid $border_gray;
		&:last-child {
			border-bottom: 1px solid $border_gray; }
		&:nth-child(odd) {
			.#{$prefix}-vacancy__title {
				box-shadow: inset 5px 0 0 $light_blue; } }
		&:nth-child(even) {
			.#{$prefix}-vacancy__title {
				box-shadow: inset 5px 0 0 $light_red; } } }
	&__list {
		padding-top: 35px;
		margin-bottom: 60px; }
	&__title {
		position: relative;
		padding-left: 35px;
		margin: 30px 0;
		font: {
			family: Panton;
			weight: bold;
			size: 1.5em; }
		line-height: 55px;
		user-select: none;
		color: $font_blue;
		cursor: pointer;
		transition: color .2s ease;
		&:hover {
			color: $light_blue; }
		@include str-dropdown-tab(7, 3); }
	&__desc {
		margin-bottom: 60px; } }

@include media($mobile) {
	&-vacancy {
		&__list {
			padding-top: 25px;
			margin-bottom: 50px; }
		&__title {
			padding-left: 20px;
			margin: 20px 0;
			line-height: 35px;
			font-size: 1.15em;
			&:after {
				height: 4px;
				width: 4px;
				border: 2px solid;
				border-left: none;
				border-top: none;
				top: 14px; }
			&.#{$prefix}-tab-title_open {
				&:after {
					top: 16px !important; } } }
		&__content {
			&-title {
				font-size: 1em;
				margin-bottom: 5px;
				padding: 0 20px; }
			&-list {
				padding: 0 20px 30px;
				li {
					line-height: 2.2em; } }
			&-text {
				padding: 0 20px 30px; } }
		&__desc {
			margin-bottom: 45px; }
		&__buttons {
			text-align: center;
			a {
				width: auto; } }
		&__item {
			border: none;
			&:last-child {
				border: none;
				&:after {
					content: '';
					display: block;
					border-top: 1px solid $border_gray;
					margin: 0 20px; } }
			&:before {
				content: '';
				display: block;
				border-top: 1px solid $border_gray;
				margin: 0 20px; } } } }
