&-events {
	&__content {
		.#{$prefix}-pagination {
			margin-bottom: 45px; } }
	&__grid {
		margin: 0 -20px 40px;
		flex-wrap: wrap;
		justify-content: flex-start; }
	&__item {
		flex: 0 1 25%;
		margin: 25px 0;
		figure {
			padding: 0 20px; } }
	&__photo {
		height: 215px;
		border-radius: 5px;
		background-position: center;
		background-size: cover; }
	&__desc {
		max-width: $mid_content_width;
		margin: 0 auto 20px;
		line-height: 1.9em;
		text-align: justify; }
	&-info {
		margin-top: 17px;
		&__date {
			color: $font_white;
			margin-left: 20px;
			padding: 3px 10px 0;
			background: $light_blue;
			display: inline-block;
			font: bold 1.375em/1.8em Istok Web; }
		&__title {
			color: $font_blue;
			word-break: break-word;
			font: bold 1.25em/1.4em Panton; }
		&__place {
			position: relative;
			.#{$prefix}-label {
				position: absolute;
				left: 0;
				top: 1px; }
			span {
				padding: 0 18px;
				line-height: 1.4em;
				margin-bottom: 12px;
				display: block;
				height: 40px; } } }
	&-more {
		display: none;
		text-align: center;
		margin: 35px 0 45px;
		a {
			display: inline-block;
			margin: 7px 0 6px 0;
			color: $font_blue;
			font-weight: 700;
			font-size: 1.125em; } } }

&-event {
	&-single {
		&__header {
			max-width: $mid_content_width;
			margin: 40px auto; }
		&__content {
			max-width: $mid_content_width;
			margin: 0 auto 90px; }
		&__grid {
			flex-wrap: wrap;
			justify-content: flex-start; }
		&__item {
			flex: 0 1 33.3%;
			margin: 0 0 40px;
			&_w100 {
				flex: 0 1 100%;
				margin: 0 0 40px; }
			.#{$prefix}-date {
				display: inline-block;
				vertical-align: middle;
				margin-top: -3px; }
			.#{$prefix}-age {
				display: inline-block;
				vertical-align: middle;
				margin: -3px 0 0; }
			.#{$prefix}-participant {
				display: inline-block;
				vertical-align: middle;
				margin: -3px 2px 0 0; }
			.#{$prefix}-label {
				display: inline-block;
				vertical-align: middle;
				margin: -4px 1px 0 0; } }
		&__image {
			margin: 0 0 40px;
			max-height: 500px;
			border-radius: 5px;
			overflow: hidden; }
		&__title {
			font-weight: bold;
			margin-bottom: 8px; }
		&__date {
			span {
				color: $font_blue;
				display: inline-block;
				margin-left: 2px; } }
		&__direction {
			border-radius: 45px;
			border: 1px solid $gray;
			color: $gray;
			display: inline-block;
			padding: 2px 40px 3px;
			margin-top: 15px; }
		&__desc, &__rewards, &__org {
			margin-bottom: 45px; }
		&__desc {
			p {
				a, a:hover {
					color: $light_blue; } } }
		&__docs {
			margin-bottom: 40px; }
		&__buttons {
			text-align: center; }
		.#{$prefix}-docs {
			&__item {
				padding: 0; }
			&__list {
				margin-top: 22px; } }
		&__text {
			padding-right: 20px;
			& > div {
				display: inline-block;
				vertical-align: top; }
			.#{$prefix}-email-gray, .#{$prefix}-handset {
				display: inline-block;
				vertical-align: middle;
				margin-right: 3px; }
			&-info {
				width: 340px;
				padding-right: 20px;
				div {
					//white-space: nowrap
					//overflow: hidden
					//text-overflow: ellipsis
					margin-top: 5px; } }
			&-data {
				div {
					margin-top: 5px; } } } } }

@include media($mobile) {
	&-events {
		&__grid {
			margin: 0 0 20px; }
		&__item {
			flex: 0 1 100%;
			margin: 25px 0 5px; }
		&__content {
			.#{$prefix}-pagination {
				display: none; } }
		&__desc {
			padding: 0 20px;
			margin: 0; }
		&-info {
			margin-top: 12px;
			&__date {
				padding: 1px 10px 0;
				font: bold 1.1em/2.3em Istok Web; }
			&__place {
				color: $gray;
				span {
					height: auto;
					margin-bottom: 8px; } }
			&__title {
				font: bold 1em/1.6em Istok Web; } }
		&-more {
			display: block; } }

	&-event {
		&-single {
			&__header {
				padding: 0 20px;
				margin: 0 auto; }
			&__content {
				display: flex;
				flex-wrap: wrap;
				margin: 0 auto 35px;
				& > div {
					order: 1; } }
			&__image {
				order: 2 !important;
				margin: 30px 20px 35px; }
			&__item {
				flex: 0 1 100%;
				margin: 0 0 25px;
				&_w100 {
					margin: 0 0 25px; } }
			&__desc, &__rewards, &__org {
				margin-bottom: 25px; }
			&__docs {
				margin: 0; }
			&__buttons {
				width: 100%;
				padding: 0 20px;
				order: 2 !important; }
			&__grid, &__desc, &__rewards, &__org, &__docs {
				padding: 0 20px; }
			&__direction {
				margin-top: 5px; }
			.#{$prefix}-docs__list {
				margin-top: 10px; }
			&__text {
				&-info {
					width: auto;
					padding: 0; } } } } }
