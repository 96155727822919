&-gallery {
	&__content {
		margin: 0 -20px 90px; }
	&__grid {
		margin-top: 40px;
		flex-wrap: wrap;
		justify-content: flex-start; }
	&__item {
		flex: 0 1 33.3%;
		margin-bottom: 90px;
		padding: 0 20px;
		box-sizing: border-box;
		a {
			display: block;
			height: 350px;
			position: relative;
			overflow: hidden;
			&:hover {
				.tau-album__desc {
					background: $light_blue_hover; } } }
		&-photo {
			flex: 0 1 33.3%;
			margin: 0 0 40px !important;
			padding: 0 20px !important;
			box-sizing: border-box;
			img {
				position: relative !important; } } }
	&__more {
		margin-top: 50px;
		text-align: center;
		a {
			color: $font_blue;
			font-weight: bold;
			font-size: 1.125em; } } }

&-album {
	&__desc {
		position: absolute;
		height: 65px;
		left: 0;
		right: 0;
		bottom: 0;
		background: $light_blue;
		color: $font_white;
		box-sizing: border-box;
		padding: 10px 15px;
		box-shadow: 0 0 50px rgba(47, 67, 139, 1);
		transition: background .2s ease; }
	&__photo-count {
		position: absolute;
		font: {
			family: Panton;
			size: 1.125em; }
		right: 15px;
		top: 10px; }
	&__title {
		font: {
			family: Panton;
			weight: bold;
			size: 1.125em; }
		margin-bottom: 4px;
		max-width: 320px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; }
	&__date {
		font-size: .875em;
		text-transform: lowercase; } }

@include media($mobile) {
	&-gallery {
		&__content {
			margin: 0 0 30px; }
		&__grid {
			margin-top: 25px;
			flex-wrap: wrap;
			justify-content: flex-start; }
		&__item {
			flex: 0 1 100%;
			margin-bottom: 30px;
			a {
				height: auto; }
			&-photo {
				flex: 0 1 100%;
				margin-bottom: 30px; } }
		&__more {
			margin-top: 0; } }
	&-album {
		&__title {
			font-size: 1em;
			margin-bottom: 2px; }
		&__date {
			font-size: .875em; }
		&__desc {
			padding: 6px 12px;
			height: 50px; }
		&__photo {
			&-count {
				font-size: .875em;
				right: 12px;
				top: 6px; } } } }
