&-table {
	width: 100%;
	min-width: 550px;
	display: table;
	margin: 40px 0 50px;
	border: 1px solid $light_gray;
	&__header {
		display: flex;
		width: 100%;
		background: $bg_gray;
		text-align: center;
		& > div {
			display: table-cell;
			padding: 25px 20px;
			font-weight: bold;
			line-height: 1.5em;
			border-bottom: 1px solid $light_gray;
			border-right: 1px solid $light_gray;
			&:nth-child(1) {
				width: 6%; }
			&:nth-child(2) {
				width: 35%; }
			&:nth-child(3) {
				width: 29%; }
			&:nth-child(4) {
				width: 30%; }
			&:last-child {
				border-right: none; } } }
	&__body {
		width: 100%;
		display: table; }
	&__item {
		display: table-row;
		&:last-child {
			& > div {
				border-bottom: none; } }
		& > div {
			display: table-cell;
			padding: 25px 20px;
			width: 20%;
			font-size: .875em;
			text-align: center;
			border-bottom: 1px solid $light_gray;
			border-right: 1px solid $light_gray;
			&:nth-child(1) {
				width: 6%; }
			&:nth-child(2) {
				width: 35%;
				text-align: left; }
			&:nth-child(3) {
				width: 29%; }
			&:nth-child(4) {
				width: 30%; }
			&:last-child {
				font: {
					size: 1.125em;
					weight: bold; }
				border-right: none; } } }

	&_big {
		width: 100%;
		min-width: 550px;
		display: table;
		margin: 0 0 90px;
		position: relative;
		&__wrap {
			margin-top: 50px; }
		&__header {
			display: flex;
			width: 100%;
			& > div {
				display: block;
				width: 20%;
				padding: 20px 0;
				font-weight: bold;
				line-height: 1.5em;
				color: $font_blue;
				border: 1px solid $light_gray;
				border-left: none;
				&:first-child {
					border-left: 1px solid $light_gray; }
				span {
					display: block;
					text-align: center; }
				&.#{$prefix}-table_big__header-item {
					&_light {
						background: rgba(113, 134, 207, .1); }
					&_blue {
						background: $light_blue_hover;
						color: $font_white;
						border: 1px solid $light_blue;
						border-left: none; } }
				&.#{$prefix}-table_big__col_active {
					&:before {
						content: '';
						position: absolute;
						top: 0;
						width: 20%;
						height: 100%;
						box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
						transition: all .2s ease; } } }
			&_additional {
				font: {
					size: 1.25em;
					weight: bold; }
				color: $font_white;
				background: $light_blue;
				text-align: center;
				padding: 15px;
				display: block;
				width: 60%;
				float: right;
				border-radius: 5px 5px 0 0;
				margin-top: 30px; }
			&_simple {
				display: flex;
				& > div {
					background: $light_blue;
					border-bottom: none;
					display: table-cell;
					padding: 20px 0;
					width: 20%;
					font-weight: bold;
					line-height: 1.5em;
					color: $font_white;
					transition: all .2s ease;
					span {
						display: block;
						text-align: center; }
					&:first-child {
						border-radius: 5px 0 0 0; }
					&:last-child {
						border-radius: 0 5px 0 0; }
					&.#{$prefix}-table_big__col_active {
						background: $light_blue_hover;
						&:before {
							content: '';
							position: absolute;
							top: 0;
							width: 20%;
							height: 100%;
							box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
							transition: all .2s ease; } } } } }
		&__body {
			width: 100%;
			display: table; }
		&__item {
			display: table-row;
			&.#{$prefix}-table_big__item_blue {
				color: $font_blue; }
			.#{$prefix}-icon {
				margin: auto; }
			&.#{$prefix}-table_big__item_finally {
				& > div {
					height: 360px; } }
			& > div {
				display: table-cell;
				padding: 20px 25px;
				width: 20%;
				font-size: .875em;
				position: relative;
				vertical-align: top;
				border-bottom: 1px solid $light_gray;
				border-right: 1px solid $light_gray;
				&:nth-child(2) {
					text-align: left; }
				&:first-child {
					border-left: 1px solid $light_gray; }
				p {
					margin-bottom: 20px; } } }
		&__price {
			font: {
				family: Panton;
				weight: bold;
				size: 1.125em; }
			color: $font_dark_gray;
			text-transform: uppercase;
			margin-bottom: 25px;
			&-additional {
				font-size: .875em; } }
		&__btn {
			position: absolute;
			bottom: 50px;
			left: 50%;
			margin-left: -87px; } } }

@include media($mobile) {
	&-table {
		margin: 0;
		&__wrap {
			margin: 20px 20px 40px;
			overflow-y: scroll; }
		&__header {
			& > div {
				padding: 10px;
				font-weight: normal; } }
		&__item {
			& > div {
				padding: 10px;
				font-size: 1em;
				&:nth-child(2) {
					text-align: left; }
				&:last-child {
					font: {
						size: 1em;
						weight: normal; } } } }
		&_big {
			margin: 0;
			&__wrap {
				margin: 20px 20px 40px;
				overflow-y: scroll; }
			&__btn {
				width: auto;
				margin-left: -49px; }
			&__header {
				&_additional {
					display: none; } } } } }
