&-tab {
	&-title {
		&_open {
			& + .#{$prefix}-tab-content {
				height: auto; } } }
	&-content {
		height: 0;
		overflow: hidden; } }

&-gtab {
	&__titles {
		display: flex; }
	&__title {
		flex: 0 0 50%;
		font: {
			family: Panton;
			weight: bold;
			size: 1.25em; }
		color: $font_dark_gray;
		text-transform: uppercase;
		text-align: center;
		padding: 15px;
		box-sizing: border-box;
		&_open {
			color: $font_white !important;
			border-radius: 5px 5px 0 0;
			box-shadow: 0 4px 6px rgba(0, 0, 0, .2); }
		&:nth-child(odd) {
			&.#{$prefix}-gtab__title_open {
				background: $light_red; } }
		&:nth-child(even) {
			&.#{$prefix}-gtab__title_open {
				background: $light_blue; } } }
	&__content {
		display: none;
		&_open {
			display: block !important; }
		&:nth-child(odd) {
			&.#{$prefix}-gtab__content_open {
				box-shadow: 0 -1px 0 $light_red; } }
		&:nth-child(even) {
			&.#{$prefix}-gtab__content_open {
				box-shadow: 0 -1px 0 $light_blue; } } } }
