&-contacts {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto;
		.#{$prefix}-inline-logo {
			display: none; } }
	&__info {
		margin-bottom: 55px;
		flex-wrap: wrap;
		&-item {
			margin-bottom: 35px;
			&:nth-child(odd) {
				.tau-contacts__title {
					box-shadow: inset 5px 0 0 $light_blue; } }
			&:nth-child(even) {
				.tau-contacts__title {
					box-shadow: inset 5px 0 0 $light_red; } } } }
	&__item {
		flex: 0 0 50%; }
	&__title {
		font: {
			family: Panton;
			weight: bold;
			size: 1.625em; }
		color: $font_blue;
		line-height: 55px;
		padding-left: 30px; }
	&__value {
		font-size: 1.125em;
		padding-left: 30px; } }

@include media($mobile) {
	&-contacts {
		&__content {
			margin-bottom: 35px;
			.#{$prefix}-inline-logo {
				display: block; } }
		&__info {
			margin-bottom: 30px;
			&-item {
				margin-bottom: 25px; } }
		&__title {
			font-size: 1.15em;
			padding: 0 20px;
			line-height: 35px;
			margin-bottom: 5px; }
		&__value {
			font-size: 1em;
			padding-left: 20px; }
		&__item {
			flex: 0 0 100%;
			&:last-child {
				padding: 0 20px; } } } }
