&-students-registry {
	img {
		width: 100%;
		margin-bottom: 60px; }
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 90px;
		p {
			margin-bottom: 40px; }
		ul:not(.#{$prefix}-students-registry__list-count) {
			margin-bottom: 40px;
			li {
				padding-left: 15px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 11px;
					left: 0;
					height: 4px;
					width: 4px;
					border-radius: 50%;
					background: $font_dark_gray; } } }
		&-title {
			font: {
				family: Panton;
				weight: bold;
				size: 1.5em; }
			color: $font_dark_gray;
			margin: 10px 0 20px; } }
	&__title {
		padding-left: 30px;
		line-height: 55px;
		color: $font_dark_gray;
		box-shadow: inset 5px 0 0 $light_red;
		margin-bottom: 80px;
		font: {
			family: Panton;
			weight: bold;
			size: 1.5em; } }
	&__list {
		&-count {
			display: flex;
			justify-content: stretch;
			flex-wrap: wrap;
			counter-reset: item;
			margin-bottom: 80px;
			li {
				flex: 0 0 25%;
				height: 110px;
				position: relative;
				&:before {
					content: counter(item);
					counter-increment: item;
					position: absolute;
					top: 0;
					height: 100%;
					left: 0;
					line-height: 110px;
					font: {
						family: Panton;
						size: 144px;
						weight: bold; }
					color: rgba(103, 124, 197, .15); }
				span {
					font-size: 1.125em;
					line-height: 1.9em;
					color: $font_dark_gray;
					padding-left: 20px;
					position: absolute;
					bottom: 0;
					left: 0;
					max-width: 150px;
					text-align: center; } } } }
	&__buttons {
		text-align: center; } }

@include media($mobile) {
	&-students-registry {
		img {
			border-radius: 5px;
			overflow: hidden;
			margin: 0 20px 30px;
			width: calc(100% - 40px); }
		&__content {
			margin: 0 0 40px;
			p {
				margin-bottom: 20px;
				padding: 0 20px; }
			b {
				display: inline-block;
				margin-top: 20px;
				&:first-child {
					margin: 0; } }
			ul:not(.#{$prefix}-students-registry__list-count) {
				margin-bottom: 20px;
				padding: 0 20px; }
			&-title {
				padding: 0 20px;
				font-size: 1.15em; } }
		&__list {
			&-count {
				margin-bottom: 0;
				padding: 0 20px;
				justify-content: space-between;
				li {
					flex: 0 0 45%;
					margin: 30px 0;
					height: 75px;
					&:before {
						line-height: 75px;
						font-size: 96px; }
					span {
						font-size: .875em;
						line-height: 1.4em;
						text-align: left; } } } }
		&__title {
			padding: 0 20px;
			font-size: 1.15em;
			line-height: 30px;
			margin: 30px 0 0; }
		&__buttons {
			a {
				width: auto; } } } }
