@import url('https://fonts.googleapis.com/css?family=Istok+Web:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');

body {
	&.#{$prefix}-impaired {
		@import "color-im"; }
	margin: 0;
	padding: 0;
	background: $white;
	font: normal 16px Istok Web, sans-serif;
	color: $font_dark_gray;

	h1, h2, h3, h4, h5, h6, p, ul, li, i, b, strong, figure, figcaption {
		margin: 0;
		padding: 0;
		line-height: 1.8em;
		box-sizing: border-box; }

	a, a:visited, a:link {
		text-decoration: none;
		color: $font_gray;
		transition: color, box-shadow .2s ease; }

	ul, li {
		list-style: none; }

	img {
		display: block;
		width: 100%; }

	div {
		box-sizing: border-box; }

	p {
		a {
			color: $light_blue !important; } }

	svg {
		path {
			fill: inherit;
			stroke: inherit; } }

	input, textarea, select, button {
		font: normal 16px Istok Web, sans-serif;
		outline: none;
		border: none;
		&:focus {
			outline: none;
			border: none; } }

	input, textarea {
		&::placeholder {
			color: $gray;
			font: normal .875em/1.47em Istok Web; } }

	textarea {
		resize: none; }

	div, textarea {
		// scrollbar for ff (experimental technology)
		scrollbar-color: $font_grayer transparent;
		scrollbar-width: thin;

		// scrollbar for ie
		scrollbar-track-color: transparent;
		scrollbar-face-color: $gray;
		scrollbar-array-color: transparent;

		// scrollbar for webkit
		&::-webkit-scrollbar {
			width: 9px; }
		&::-webkit-scrollbar-track {
			background: transparent;
			background-clip: padding-box; }
		&::-webkit-scrollbar-thumb {
			background: $gray;
			border-radius: 4px;
			border: 2px solid transparent;
			border-top: none;
			border-bottom: none;
			background-clip: padding-box;
			&:hover {
				background: $font_grayer;
				border-radius: 4px;
				background-clip: padding-box;
				border: 2px solid transparent;
				border-top: none;
				border-bottom: none; }
			&:active {
				background: $font_grayer;
				border-radius: 4px;
				background-clip: padding-box;
				border: 2px solid transparent;
				border-top: none;
				border-bottom: none; } } } }

@include media($mobile) {
	body {
		font: normal 14px Istok Web, sans-serif; } }
