&-teachers {
	&__content {
		max-width: $mid_content_width;
		margin: 0 auto 45px;
		p {
			margin-bottom: 40px; }
		li {
			padding-left: 15px;
			position: relative;
			&:not(.#{$prefix}-docs__item):before {
				content: '';
				position: absolute;
				top: 11px;
				left: 0;
				height: 4px;
				width: 4px;
				border-radius: 50%;
				background: #333; } } }
	&__grid {
		max-width: $mid_content_width;
		margin: 25px auto 75px;
		flex-wrap: wrap; }
	&__title {
		color: $font_dark_gray;
		font: {
			family: Panton;
			weight: bold;
			size: 1.375em; }
		padding: 0 25px;
		line-height: 55px;
		box-shadow: inset 5px 0 0 $light_red; }
	&__item {
		flex: 0 0 50%;
		li {
			padding: 12px 0 12px 25px;
			a {
				color: $font_blue;
				font: {
					family: Panton;
					weight: bold;
					size: 1.125em; } } } }
	&__buttons {
		text-align: center;
		margin-bottom: 70px; }
	.#{$prefix}-partners__grid {
		padding: 60px 0;
		a {
			flex: 0 1 33.3%; } } }

@include media($mobile) {
	&-teachers {
		&__grid {
			margin: 0 0 30px; }
		&__title {
			font-size: 1.15em;
			line-height: 35px;
			padding: 0 20px;
			margin-bottom: 4px; }
		&__item {
			flex: 0 0 100%;
			margin: 20px 0;
			li {
				padding: 8px 20px;
				a {
					font: {
						family: Istok Web;
						size: 1em;
						weight: normal; } } } }
		&__content {
			margin: 0 0 30px;
			p {
				padding: 0 20px;
				margin-bottom: 20px; }
			ul {
				padding: 0 20px;
				p {
					padding: 0; } } }
		&__buttons {
			margin: 35px 0 45px;
			a {
				width: auto; } }
		.#{$prefix}-partners__grid {
			padding: 45px 0;
			a {
				flex: 0 1 100%; } } } }
