&-students {
	&__content {
		padding: 35px 0 55px; }
	@include white-grid(25%);

	&-inner {
		&__content {
			max-width: $mid_content_width;
			margin: 0 auto 45px; }
		.#{$prefix}-students__grid {
			padding: 60px 0;
			justify-content: flex-start;
			a {
				flex: 0 1 33.3%; } } } }

@include media($mobile) {
	&-students {
		&-inner {
			&__content {
				padding: 0 20px;
				margin-bottom: 40px; }
			.#{$prefix}-students__grid {
				padding: 45px 0;
				a {
					flex: 0 1 100%; } } } } }
