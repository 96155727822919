&-header {
	&__logo {
		height: 75px;
		width: 75px;
		display: inline-block;
		vertical-align: top;
		background: {
			image: url(#{$img_path}logo#{$suffix}.svg);
			repeat: no-repeat;
			position: center;
			size: contain; } }
	&__logo-text {
		display: inline-block;
		position: relative;
		margin-left: 6px;
		&-#{$prefix} {
			height: 75px;
			background: {
				image: url(#{$img_path}logo-text#{$suffix}.svg);
				repeat: no-repeat;
				position: 0 22px;
				size: auto 40px; } }
		&-other {
			color: $font_light_gray;
			display: block;
			margin-top: -12px;
			font: {
				family: Panton;
				weight: bold;
				size: .875em; } } }
	&__menu {
		padding-top: 5px;
		.#{$prefix}-header__lk, .#{$prefix}-social {
			display: none; }
		&-item {
			display: inline-block;
			vertical-align: top;
			position: relative;
			& > a {
				font: {
					family: Panton;
					weight: bold;
					size: 1.125em; }
				padding: 20px;
				position: relative; }
			&:hover {
				& > a {
					background: $white;
					box-shadow: inset 0 5px 0 $light_red, 0px -22px 28px rgba(0, 0, 0, .07);
					z-index: 6; }
				.#{$prefix}-header__submenu {
					display: block; } }
			&_active {
				& > a {
					box-shadow: inset 0 5px 0 $light_red; } }
			&_with_submenu {
				& > a {
					&:after {
						content: '';
						display: inline-block;
						vertical-align: middle;
						height: 5px;
						width: 5px;
						border: 2px solid $font_blue;
						border-left: none;
						border-top: none;
						transform: rotate(45deg);
						margin: -3px 0 0 5px; } } } } }
	&__submenu {
		display: none;
		position: absolute;
		background: $white;
		width: 410px;
		padding: 20px 0;
		box-shadow: 0 12px 42px rgba(0, 0, 0, .15);
		z-index: 5;
		li {
			display: block;
			a {
				font: {
					family: Istok Web;
					weight: normal;
					size: 1em; }
				padding: 10px 20px;
				transition: background .2s ease;
				&:hover {
					background: $bg_gray;
					box-shadow: none; } } } }
	&__right-wrapper {
		height: 75px; }
	&__search {
		margin: auto 15px auto 0;
		position: relative; }
	&__lk {
		margin: auto;
		position: relative;
		font: {
			family: Panton;
			weight: bold; }
		line-height: 2.375em;
		padding-left: 40px;
		border-left: 1px solid #cecece; }
	&__hamburger {
		display: none;
		margin: auto;
		padding-left: 30px;
		a {
			display: block;
			height: 2px;
			width: 20px;
			background: $blue;
			position: relative;
			&:before {
				content: '';
				display: block;
				height: 2px;
				width: 100%;
				background: $blue;
				position: absolute;
				top: -7px;
				left: 0;
				transition: all .2s ease; }
			&:after {
				content: '';
				display: block;
				height: 2px;
				width: 100%;
				background: $blue;
				position: absolute;
				bottom: -7px;
				left: 0;
				transition: all .2s ease; } } }
	&__vision {
		position: absolute;
		top: -25px;
		right: 40px;
		width: 45px;
		height: 45px;
		background: $light_red; } }

@include media($mobile) {
	&-header {
		&__logo {
			height: 37px;
			width: 37px;
			&-text {
				margin-left: 0;
				&-tau {
					height: 40px;
					width: 45px;
					background-position: 0;
					background-size: contain; }
				&-other {
					display: none; } } }
		&__right {
			&-wrapper {
				height: auto; } }
		&__left {
			&-wrapper {
				a {
					height: 37px; } } }
		&__search {
			margin: auto 0; }
		&__lk {
			border: none;
			padding-left: 25px;
			&-ex {
				a {
					span {
						display: none; } } } }
		&__hamburger {
			display: block; }
		&__vision {
			display: none; }
		&__menu {
			display: none;
			position: fixed;
			top: 59px;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: 35px;
			z-index: 100;
			background: $white;
			ul {
				padding: 0 20px;
				&:before, &:after {
					content: '';
					display: block;
					height: 1px;
					width: 115px;
					background: $icon_gray; } }
			&-item {
				display: block;
				&:hover {
					.#{$prefix}-header__submenu {
						display: none; } }
				& > a {
					font-size: 1.25em;
					padding: 12px 0;
					&:hover {
						box-shadow: none; } }
				&_active {
					a {
						box-shadow: none; } }
				&:first-child {
					a {
						padding: 24px 0 12px; } }
				&:last-child {
					a {
						padding: 12px 0 30px; } }
				&_with_submenu {
					& > a {
						&:after {
							display: none; } } } }
			.#{$prefix}-header__lk {
				display: block;
				margin-bottom: 25px;
				i {
					display: inline-block;
					vertical-align: middle; }
				span {
					vertical-align: middle;
					font-weight: normal;
					margin-left: 6px; } }
			.#{$prefix}-social {
				position: absolute;
				bottom: 40px;
				left: 0;
				right: 0;
				&__item {
					display: inline-block !important; } } } }
	&-open {
		&-menu {
			.#{$prefix}-header__search, .#{$prefix}-header__lk {
				display: none; }
			.#{$prefix}-header__menu {
				display: block;
				.#{$prefix}-header__lk, .#{$prefix}-social {
					display: block; } }
			.#{$prefix}-header__hamburger {
				a {
					height: 0;
					&:before {
						top: -1px;
						background: $font_gray;
						transform: rotate(45deg); }
					&:after {
						bottom: -1px;
						background: $font_gray;
						transform: rotate(-45deg); } } } } } }
